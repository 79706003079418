<template>
    <div>
        <Breadcrumbs :items="breadcrumbs"/>
        <div class="my-4"><h3 class="ml-5"><v-icon>mdi-chevron-left</v-icon>Agregar canal</h3></div>
        <v-card>
            <ChannelForm :channel="channel"/>
        </v-card>
    </div>
</template>

<script>
    // @ is an alias to /src
    import Breadcrumbs from "@/components/layout/Breadcrumbs";
    import ChannelForm from "@/components/channel/ChannelForm";

    export default {
        name: 'Example',
        components: {
            Breadcrumbs,
            ChannelForm
        },
        data: () => ({
            breadcrumbs: [
                {text: 'Inicio', disabled: false, href: '/',},
                {text: 'Canales', disabled: false, href: '/channels',},
                {text: 'Crear Canal', disabled: true, href: '/channels/create',}
            ],
            channel: {
                name: '',
            }
        }),
    }
</script>
