<template>
    <div>
        <Breadcrumbs :items="breadcrumbs"/>
        <v-card>
            <SubchannelForm :subchannel="subchannel"/>
        </v-card>
    </div>
</template>

<script>
    // @ is an alias to /src
    import Breadcrumbs from "@/components/layout/Breadcrumbs";
    import SubchannelForm from "@/components/subchannel/SubchannelForm";
    import subchannelService from "@/services/subchannelService";

    export default {
        name: 'ChannelEdit',
        components: {
            Breadcrumbs,
            SubchannelForm
        },
        data: () => ({
            breadcrumbs: [
                {text: 'Inicio', disabled: false, href: '/',},
                {text: 'Subcanales', disabled: false, href: '/subchannels',},
                {text: 'Editar Subcanal', disabled: true, href: '/subchannels/edit',}
            ],
            subchannel: {
                channel_id: null,
                name: '',
            }
        }),
        mounted() {
            this.getData(this.$route.params.id);
        },
        methods: {
            getData(id) {
                subchannelService.show(id).then(response => {
                    this.subchannel = response.data.data;
                })
            }
        }
    }
</script>
