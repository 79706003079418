<template>
    <div>
        <Breadcrumbs :items="breadcrumbs"/>
        <div class="my-4"><h3 class="ml-5"><v-icon>mdi-chevron-left</v-icon>Agregar Cupón</h3></div>
        <v-card>
            <CouponForm :coupon="coupon"/>
        </v-card>
    </div>
</template>

<script>
    // @ is an alias to /src
    import Breadcrumbs from "@/components/layout/Breadcrumbs";
    import CouponForm from "@/components/coupon/CouponForm";

    export default {
        name: 'CrearCupon',
        components: {
            Breadcrumbs,
            CouponForm
        },
        data: () => ({
            breadcrumbs: [
                {text: 'Inicio', disabled: false, href: '/',},
                {text: 'Cupones', disabled: false, href: '/coupons',},
                {text: 'Crear Cupón', disabled: true, href: '/coupons/create',}
            ],
            coupon: {
                code: null,
                description: null,
                amount: null,
                expire_in: null,
                status: 1,
                max_number_exchanges: null,
            }
        }),
    }
</script>
