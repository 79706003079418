<template>
    <div>
        <Breadcrumbs :items="breadcrumbs"/>
        <div class="my-4"><h3 class="ml-5"><v-icon>mdi-chevron-left</v-icon>Agregar categoría</h3></div>
        <v-card>
            <CategoriaForm :categoria="categoria"/>
        </v-card>
    </div>
</template>

<script>
    import Breadcrumbs from "@/components/layout/Breadcrumbs";
    import CategoriaForm from "@/components/categoria/CategoriaForm";

    export default {
        name: 'CategoriaCreate',
        components: {
            Breadcrumbs,
            CategoriaForm
        },
        data: () => ({
            breadcrumbs: [
                {text: 'Inicio', disabled: false, href: '/',},
                {text: 'Categorías', disabled: false, href: '/categorias',},
                {text: 'Crear Categoría', disabled: true, href: '/categorias/create',}
            ],
            categoria: {
                nombre: '',
                descripcion: '',
            }
        }),
    }
</script>
