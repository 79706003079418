<template>
  <v-stepper v-model="e1">
    <v-stepper-header>
      <v-stepper-step :complete="e1 > 1" step="1"> Pagado </v-stepper-step>

      <v-divider></v-divider>

      <v-stepper-step :complete="e1 > 2" step="2"> Despachado </v-stepper-step>

      <v-divider></v-divider>

      <v-stepper-step step="3"> Entregado </v-stepper-step>

      <v-btn color="success" dark class="mt-4 mr-4" @click="descargarImeis(pedido.id)">
        Descarga Excel
        <v-icon small class="ml-2"> mdi-file-document </v-icon>
      </v-btn>
    </v-stepper-header>

    <v-stepper-items>
      <v-stepper-content step="1">
        <v-row>
          <v-col cols="12" md="4">
            <v-text-field
              v-model="pedido.nfactura"
              label="Voucher"
              required
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="6" lg="4" md="4" class="mb-6">
            <div class="d-flex flex-row justify-space-between">
              <p class="py-0">Pedido</p>
              <p class="py-0">{{ pedido.id }}</p>
            </div>
            <div class="d-flex flex-row justify-space-between">
              <p class="py-0">Solicitante</p>
              <p class="py-0">{{ pedido.create_user }}</p>
            </div>
            <div class="d-flex flex-row justify-space-between">
              <p class="py-0">Fecha de pago:</p>
              <p class="py-0">{{ pedido.fecha_pagado }}</p>
            </div>
          </v-col>
          <v-col cols="6" lg="4" md="4" class="mb-6">
            <div class="d-flex flex-row justify-space-between">
              <p class="py-0">RUC:</p>
              <p class="py-0">{{ pedido.business.clientid }}</p>
            </div>
            <div class="d-flex flex-row justify-space-between">
              <p class="py-0">Razón social:</p>
              <p class="py-0 pl-3 text-end">{{ pedido.business.name }}</p>
            </div>
            <div class="d-flex flex-row justify-space-between">
              <p class="py-0">Código bancario:</p>
              <p class="py-0">{{ pedido.codigo_bancario }}</p>
            </div>
          </v-col>
          <v-col cols="6" lg="4" md="4" class="mb-6">
            <div class="d-flex flex-row justify-space-between">
              <p class="py-0">Monto de compra:</p>
              <p class="py-0">{{ pedido.total }}</p>
            </div>
            <div class="d-flex flex-row justify-space-between">
              <p class="py-0">Flete:</p>
              <p class="py-0">S/ {{ pedido.flete }}</p>
            </div>
            <div class="d-flex flex-row justify-space-between">
              <p class="py-0">NOTA:</p>
              <p class="py-0">{{ pedido.nota }}</p>
            </div>
          </v-col>
        </v-row>
        <hr />
        <h3 class="my-3">Lista de pedidos</h3>
        <v-simple-table>
          <template v-slot:default>
            <thead>
              <tr>
                <th>N°</th>
                <th>Foto</th>
                <th>Producto</th>
                <th>Unidad</th>
                <th>Cantidad</th>
                <th>Precio Unitario</th>
                <th>Total parcial</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, key) in pedido.pedido_details" :key="item.id">
                <td>{{ key + 1 }}</td>
                <td>
                  <v-img
                    :src="item.product_detail.image_url"
                    alt=""
                    width="100"
                    class="mt-1 mb-1"
                  ></v-img>
                </td>
                <td>{{ item.product_detail.description }}</td>
                <td>Un</td>
                <td>{{ item.cantidad }}</td>
                <td>{{ item.price }}</td>
                <td>{{ item.parcial }}</td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
        <v-btn color="primary" @click="continuar(2)"> Continuar </v-btn>
      </v-stepper-content>

      <v-stepper-content step="2">
        <v-row>
          <v-col cols="6" lg="4" md="4" class="mb-6">
            <div class="d-flex flex-row justify-space-between">
              <p class="py-0">Pedido</p>
              <p class="py-0">{{ pedido.id }}</p>
            </div>
            <div class="d-flex flex-row justify-space-between">
              <p class="py-0">Solicitante</p>
              <p class="py-0">{{ pedido.create_user }}</p>
            </div>
            <div class="d-flex flex-row justify-space-between">
              <p class="py-0">Fecha de pago:</p>
              <p class="py-0">{{ pedido.fecha_pagado }}</p>
            </div>
            <div class="d-flex flex-row justify-space-between">
              <p class="py-0">Courier:</p>
              <p class="py-0">{{ pedido.courier }}</p>
            </div>
          </v-col>
          <v-col cols="6" lg="4" md="4" class="mb-6">
            <div class="d-flex flex-row justify-space-between">
              <p class="py-0">RUC:</p>
              <p class="py-0">{{ pedido.business.clientid }}</p>
            </div>
            <div class="d-flex flex-row justify-space-between">
              <p class="py-0">Razón social:</p>
              <p class="py-0 pl-3 text-end">{{ pedido.business.name }}</p>
            </div>
            <div class="d-flex flex-row justify-space-between">
              <p class="py-0">Código bancario:</p>
              <p class="py-0">{{ pedido.codigo_bancario }}</p>
            </div>
            <div class="d-flex flex-row justify-space-between">
              <p class="py-0">Tracking number:</p>
              <p class="py-0">{{ pedido.tracking_number }}</p>
            </div>
          </v-col>
          <v-col cols="6" lg="4" md="4" class="mb-6">
            <div class="d-flex flex-row justify-space-between">
              <p class="py-0">Monto de compra:</p>
              <p class="py-0">{{ pedido.total }}</p>
            </div>
            <div class="d-flex flex-row justify-space-between">
              <p class="py-0">Flete:</p>
              <p class="py-0">S/ {{ pedido.flete }}</p>
            </div>
            <div class="d-flex flex-row justify-space-between">
              <p class="py-0">Fecha Despacho:</p>
              <p class="py-0">{{ pedido.dispatched_date }}</p>
            </div>
            <div class="d-flex flex-row justify-space-between">
              <p class="py-0">Fecha Estimada Entrega:</p>
              <p class="py-0">{{ pedido.fecha_est_entrega }}</p>
            </div>
          </v-col>
        </v-row>
        <hr />
        <h3 class="my-3">Lista de pedidos</h3>
        <v-row class="mb-4">
          <v-col cols="6" lg="5" md="5">
            <v-card class="mx-auto">
              <v-list-item three-line>
                <v-list-item-content>
                  <div class="overline mb-4">
                    <h3 style="color: #1371cb">Envío Directo</h3>
                  </div>
                  <v-list-item-subtitle
                    >Destinatario:
                    {{ pedido.consignatario }}</v-list-item-subtitle
                  >
                  <v-list-item-subtitle
                    >Celular: {{ pedido.business_address.contact_phone }}</v-list-item-subtitle
                  >
                  <v-list-item-subtitle
                    >Región:
                    {{ pedido.business_address.department ? pedido.business_address.department.name : "" }}-{{
                      pedido.business_address.province ? pedido.business_address.province.name : ""
                    }}-{{
                      pedido.business_address.district ? pedido.business_address.district.name : ""
                    }}</v-list-item-subtitle
                  >
                  <v-list-item-subtitle
                    >Dirección: {{ pedido.business_address.address }}</v-list-item-subtitle
                  >
                  <v-list-item-subtitle
                    >DNI: {{ pedido.business_address.document_number }}</v-list-item-subtitle
                  >
                </v-list-item-content>
              </v-list-item>
            </v-card>
          </v-col>
          <v-col cols="6" lg="5" md="5">
            <v-card class="mx-auto">
              <v-list-item three-line>
                <v-list-item-content>
                  <div class="overline mb-4">
                    <h3 style="color: #1371cb">Envío por agencia</h3>
                  </div>
                  <v-list-item-subtitle
                    >Agencia:
                    {{
                      pedido.business_address.agencia_ubigeo
                        ? pedido.business_address.agencia_ubigeo.agencia.name
                        : ""
                    }}</v-list-item-subtitle
                  >
                  <v-list-item-subtitle
                    >Región:
                    {{
                      pedido.business_address.ag_department ? pedido.business_address.ag_department.name : ""
                    }}-{{
                      pedido.business_address.ag_province ? pedido.business_address.ag_province.name : ""
                    }}-{{
                      pedido.business_address.ag_district ? pedido.business_address.ag_district.name : ""
                    }}</v-list-item-subtitle
                  >
                  <v-list-item-subtitle
                    >UBIGEO:
                    {{
                      pedido.business_address.ag_district ? pedido.business_address.ag_district.id : ""
                    }}</v-list-item-subtitle
                  >
                  <v-list-item-subtitle
                    >Origen:
                    {{
                      pedido.business_address.agencia_ubigeo
                        ? pedido.business_address.agencia_ubigeo.agencia.origen
                        : ""
                    }}</v-list-item-subtitle
                  >
                  <v-list-item-subtitle
                    >Destino:
                    {{
                      pedido.business_address.agencia_ubigeo ? pedido.business_address.agencia_ubigeo.destino : ""
                    }}</v-list-item-subtitle
                  >
                </v-list-item-content>
              </v-list-item>
            </v-card>
          </v-col>
        </v-row>
        <pedido-tracking-olva v-if="pedido.envio_emision && pedido.envio_codigo" :pedido="pedido"></pedido-tracking-olva>
        <v-btn color="primary" @click="e1 = 3"> Continuar </v-btn>

        <v-btn text @click="e1 = 1"> Cancelar </v-btn>
      </v-stepper-content>

      <v-stepper-content step="3">
        <v-row>
          <v-col cols="6" lg="4" md="4" class="mb-6">
            <div class="d-flex flex-row justify-space-between">
              <p class="py-0">Pedido</p>
              <p class="py-0">{{ pedido.id }}</p>
            </div>
            <div class="d-flex flex-row justify-space-between">
              <p class="py-0">Solicitante</p>
              <p class="py-0">{{ pedido.create_user }}</p>
            </div>
            <div class="d-flex flex-row justify-space-between">
              <p class="py-0">Fecha de pago:</p>
              <p class="py-0">{{ pedido.fecha_pagado }}</p>
            </div>
          </v-col>
          <v-col cols="6" lg="4" md="4" class="mb-6">
            <div class="d-flex flex-row justify-space-between">
              <p class="py-0">RUC:</p>
              <p class="py-0">{{ pedido.business.clientid }}</p>
            </div>
            <div class="d-flex flex-row justify-space-between">
              <p class="py-0">Razón social:</p>
              <p class="py-0 pl-3 text-end">{{ pedido.business.name }}</p>
            </div>
            <div class="d-flex flex-row justify-space-between">
              <p class="py-0">Código bancario:</p>
              <p class="py-0">{{ pedido.codigo_bancario }}</p>
            </div>
          </v-col>
          <v-col cols="6" lg="4" md="4" class="mb-6">
            <div class="d-flex flex-row justify-space-between">
              <p class="py-0">Monto de compra:</p>
              <p class="py-0">{{ pedido.total }}</p>
            </div>
            <div class="d-flex flex-row justify-space-between">
              <p class="py-0">Flete:</p>
              <p class="py-0">S/ {{ pedido.flete }}</p>
            </div>
          </v-col>
        </v-row>
        <hr />
        <h3 class="my-3">Sustentos de envío</h3>
        <v-row>
          <v-col cols="6" sm="4">
            <v-img :src="pedido.sustento_1_url"></v-img>
            <v-img :src="pedido.sustento_2_url"></v-img>
            <v-img :src="pedido.sustento_3_url"></v-img>
          </v-col>
          <v-col class="text-center">
            <h4>Fecha de entrega</h4>
            <p>{{ pedido.fecha_entrega }}</p>
            <v-btn color="primary" dark>Entregado</v-btn>
          </v-col>
        </v-row>
        <v-btn color="primary" @click="e1 = 1"> Continuar </v-btn>

        <v-btn text @click="e1 = 2"> Cancelar </v-btn>
      </v-stepper-content>
    </v-stepper-items>
  </v-stepper>
</template>

<script>
import pedidoService from "@/services/pedidoService";
import partnerOrderService from "@/services/partner-1/partnerOrderService";
import PedidoTrackingOlva from "@/components/pedido/PedidoTrackingOlva";
export default {
  name: "DetallePedidoPartner",
  props: ["pedido"],
  components: {
    PedidoTrackingOlva
  },
  data() {
    return {
      e1: 1,
    };
  },
  mounted() {
    console.log(this.pedido);
    this.type_status = this.$route.query.type ? this.$route.query.type : "";
  },
  methods: {
    continuar(d) {
        console.log(d);
        this.e1 = d;
      if (d == 2) {
        partnerOrderService.updatePedido(this.pedido).then(
          (response) => {
          },
          (error) => {
            console.log("error", error);
          }
        );
      }
    },
    descargarImeis(id) {
      partnerOrderService.descargarImeis(id).then(
        (response) => {
          const blob = new Blob([response.data], {type: 'application/vnd.ms-excel'});
          const link = document.createElement('a');
          link.href = URL.createObjectURL(blob);
          link.download = 'imeis_pedido_' + id;
          link.click()
          URL.revokeObjectURL(link.href)
        },
        (error) => {
          console.log("error", error);
        }
      )
    }
  },
};
</script>

<style scoped>
.theme--light.v-data-table > .v-data-table__wrapper > table > thead > tr > th {
  color: #1371cb;
  font-size: 16px;
}
.theme--light.v-stepper .v-stepper__step--active .v-stepper__label {
  color: #1371cb !important;
}
.v-application .primary {
  background-color: #1371cb !important;
}
</style>
