<template>
    <div>
        <Breadcrumbs :items="breadcrumbs"/>
        <v-card>
            <BannerForm :banner="banner"/>
        </v-card>
    </div>
</template>

<script>
    // @ is an alias to /src
    import Breadcrumbs from "@/components/layout/Breadcrumbs";
    import BannerForm from "@/components/banner/BannerForm";
    import bannerService from "@/services/bannerService";

    export default {
        name: 'BodegaEdit',
        components: {
            Breadcrumbs,
            BannerForm
        },
        data: () => ({
            breadcrumbs: [
                {text: 'Inicio', disabled: false, href: '/',},
                {text: 'Banners', disabled: false, href: '/banners',},
                {text: 'Editar Banner', disabled: true, href: '/banners/edit',}
            ],
            banner: {
                position: '',
                photo: '',
                photo_url: '',
            }
        }),
        mounted() {
            this.show(this.$route.params.id);
        },
        methods: {
            show(bodega_id) {
                bannerService.show(bodega_id).then(response => {
                    this.banner = response.data.banner;
                })
            }
        }
    }
</script>
