<template>
    <div>
        <Breadcrumbs :items="breadcrumbs"/>
        <v-card>
            <ProductForm :product="product" v-if="product.id"/>
        </v-card>
    </div>
</template>

<script>
    // @ is an alias to /src
    import Breadcrumbs from "@/components/layout/Breadcrumbs";
    import ProductForm from "@/components/product/ProductForm";
    import productService from "@/services/productService";

    export default {
        name: 'ProductEdit',
        components: {
            Breadcrumbs,
            ProductForm
        },
        data: () => ({
            breadcrumbs: [
                {text: 'Inicio', disabled: false, href: '/',},
                {text: 'Productos', disabled: false, href: '/products',},
                {text: 'Editar Producto', disabled: true, href: '/products/edit/',}
            ],
            product: {
                code: '',
                name: '',
                description: '',
                unit: 'Un',
                stock: null,
                price: null,
                image: '',
                detail: false,
                marca_id: null,
                categoria_id: null,
                web_description: null,
                details: [{
                    code: '',
                    color: '',
                    capacity: '',
                    image: '',
                    image_url: 'https://via.placeholder.com/100x100',
                    stock: null,
                    price: null
                }]
            }
        }),
        mounted() {
            this.getDataProduct(this.$route.params.id);
        },
        methods: {
            getDataProduct(product_id) {
                productService.getProduct(product_id).then(response => {
                    this.product = response.data;
                })
            }
        }
    }
</script>
