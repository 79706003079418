<template>
  <div>
    <Breadcrumbs :items="breadcrumbs"/>
        <div class="my-4"><h3 class="ml-5"><v-icon>mdi-chevron-left</v-icon> Agregar cliente</h3></div>
    <v-card>
      <ClientForm/>
    </v-card>
  </div>
</template>

<script>
// @ is an alias to /src
import Breadcrumbs from "@/components/layout/Breadcrumbs";
import ClientForm from "@/components/client/ClientForm";

export default {
  name: 'Example',
  components: {
    Breadcrumbs,
    ClientForm
  },
  data: () => ({
    breadcrumbs: [
      {text: 'Inicio', disabled: false, href: '/',},
      {text: 'Clientes', disabled: false, href: '/clients',},
      {text: 'Crear Cliente', disabled: true, href: '/clients/create',}
    ]
  }),
}
</script>
