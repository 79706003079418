<template>
    <div>
        <v-overlay :value="overlay">
            <v-progress-circular
                indeterminate
                color="primary"
                size="64"
            ></v-progress-circular>
        </v-overlay>
        <v-snackbar v-model="snackbar" :timeout="2000">
            {{ textError }}
            <template v-slot:action="{ attrs }">
                <v-btn
                    :color="snackbarColor"
                    text
                    v-bind="attrs"
                    @click="snackbar = false"
                >
                    Cerrar
                </v-btn>
            </template>
        </v-snackbar>
        <v-form v-model="valid" v-on:submit="submit">
            <PedidoTabs :pedido="pedido"></PedidoTabs>
            <v-container>
                <v-row v-if="pedido.response_code_bitel || pedido.flexline_imei_sync">
                    <v-col cols="12" md="12">
                        <h5>Sincronización de IMEI's</h5>
                    </v-col>
                    <v-col v-if="pedido.response_code_bitel" cols="12" md="12">
                        <v-row>
                            <v-col cols="12" md="3">
                                <label><b>Código</b></label>
                                <p>{{ pedido.response_code_bitel }}</p>
                            </v-col>
                            <v-col cols="12" md="3">
                                <label><b>Descripción</b></label>
                                <p v-if="pedido.response_code_bitel == '000'">COMPLETO</p>
                                <p v-if="pedido.response_code_bitel == '001'">ESTADO DIFERENTE</p>
                                <p v-if="pedido.response_code_bitel == '006'">PEDIDO INEXISTENTE</p>
                                <p v-if="pedido.response_code_bitel == '011'">ERROR DE ENVÍO DE IMEI</p>
                                <p v-else>{{ pedido.response_message_bitel }}</p>
                            </v-col>
                            <v-col cols="12" md="3">
                                <label><b>Fecha y Hora de sincronización</b></label>
                                <p>{{ pedido.response_bitel_datetime | date('DD/MM/YYYY hh:mm') }}</p>
                            </v-col>
                            <v-col cols="12" md="3" v-if="pedido.response_code_bitel != '000'">
                                <v-btn large class="btn-blue px-5" @click="syncImeis">Sincronizar</v-btn>
                            </v-col>
                        </v-row>
                    </v-col>
                    <v-col v-if="pedido.flexline_imei_sync" cols="12" md="12">
                        <v-row>
                            <v-col cols="12" md="12" v-if="pedido.response_code_bitel != '000'">
                                <div style="text-align: right">
                                    <v-btn large class="btn-blue px-5" @click="syncImeisFlexLine">Capturar IMEI</v-btn>
                                </div>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
                <hr>
            </v-container>
            <v-container>
                <v-row>
                    <v-col cols="12" md="4">
                        <v-autocomplete
                            v-model="pedido.business"
                            :loading="loading"
                            :items="business"
                            :search-input.sync="searchBusiness"
                            item-text="name"
                            return-object
                            flat
                            hide-no-data
                            hide-pedido_details
                            label="Empresas"
                            :rules="rules.client"
                            required
                            :readonly="userSession.role === 'partner'"
                        >
                        </v-autocomplete>
                    </v-col>
                    <v-col cols="12" md="6">
                        <v-card outlined>
                            <v-list-item three-line>
                                <v-list-item-content>
                                    <div class="overline">Empresa</div>
                                    <v-list-item-subtitle>{{
                                            pedido.business ? pedido.business.name : ""
                                        }}
                                    </v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item>
                        </v-card>
                    </v-col>
                    <v-col cols="12" md="2">
                        <v-radio-group v-model="pedido.minorista_mayorista">
                            <v-radio
                                label="Minorista"
                                :value="1"
                            ></v-radio>
                            <v-radio
                                label="Mayorista"
                                :value="2"
                            ></v-radio>
                        </v-radio-group>
                    </v-col>

                </v-row>
                <v-row></v-row>
                <v-row>
                    <v-col cols="12" md="4">
                        <PedidoAddress
                            :pedido_address="pedido.business_address"
                            :departments="departments"
                            :provinces="provinces"
                            :districts="districts"
                        />
                    </v-col>
                    <v-col cols="12" md="4" v-if="pedido.type_transaction!=2">
                        <PedidoAddressAgencia
                            :disabled="validPedidoAddressIncompleto"
                            :pedido_address="pedido.business_address"
                            :departments="departments_agencia"
                            :provinces="provinces_agencia"
                            :districts="districts_agencia"
                            :ubigeo_agencias="ubigeo_agencias"
                        />
                    </v-col>
                    <v-col cols="12" md="4">
                        <p style="color:#707070;">¿No encontraste empresa? Agrélala</p>
                        <EmpresaModalCreate></EmpresaModalCreate>
                        
                    </v-col>
                    
                </v-row>
                <v-row>
                    <v-col cols="12" md="8">
                        <v-autocomplete
                            v-model="productSelect"
                            :loading="loading"
                            :items="products"
                            :search-input.sync="searchProduct"
                            item-text="name"
                            return-object
                            flat
                            clearable
                            hide-pedido_details
                            hide-selected
                            label="Productos"
                            @change="selectProduct"
                        >
                            <template v-slot:no-data>
                                <v-list-item>
                                    <v-list-item-title>
                                        Buscar por
                                        <strong>nombre</strong> o
                                        <strong>código</strong>
                                    </v-list-item-title>
                                </v-list-item>
                            </template>
                            <template v-slot:selection="{ attr, on, item, selected }">
                                <v-chip
                                    v-bind="attr"
                                    :input-value="selected"
                                    color="blue-grey"
                                    class="white--text"
                                    v-on="on"
                                >
                                    <span v-text="item.name"></span>
                                </v-chip>
                            </template>
                            <template v-slot:item="{ item }">
                                <v-list-item-avatar
                                    color="indigo"
                                    :tile="true"
                                    class="headline font-weight-light white--text"
                                >
                                    <img :src="item.image_url" alt=""/>
                                </v-list-item-avatar>
                                <v-list-item-content>
                                    <v-list-item-title v-text="item.name"></v-list-item-title>
                                    <v-list-item-subtitle
                                        v-text="item.color"
                                    ></v-list-item-subtitle>
                                </v-list-item-content>
                            </template>
                        </v-autocomplete>
                    </v-col>
                </v-row>

                <v-row>
                    <v-col>
                        <hr/>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        <v-card>
                            <v-simple-table>
                                <template v-slot:top>
                                    <v-toolbar flat>
                                        <v-dialog v-model="dialogDelete" max-width="500px">
                                            <v-card>
                                                <v-card-title class="headline"
                                                >Estás seguro de eliminar este ítem?
                                                </v-card-title>
                                                <v-card-actions>
                                                    <v-spacer></v-spacer>
                                                    <v-btn color="blue darken-1" text @click="closeDelete"
                                                    >Cancelar
                                                    </v-btn
                                                    >
                                                    <v-btn
                                                        color="blue darken-1"
                                                        text
                                                        @click="deleteItemConfirm"
                                                    >Sí, estoy seguro
                                                    </v-btn>
                                                    <v-spacer></v-spacer>
                                                </v-card-actions>
                                            </v-card>
                                        </v-dialog>
                                    </v-toolbar>
                                </template>
                                <template v-slot:default>
                                    <thead>
                                    <tr>
                                        <th>N°</th>
                                        <th>Foto</th>
                                        <th>Producto</th>
                                        <th>Especificaciones</th>
                                        <th>Unidad</th>
                                        <th>Cantidad</th>
                                        <th>Stock Disponible</th>
                                        <th>Precio Unitario</th>
                                        <th>Total parcial</th>
                                        <th>Acciones</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr
                                        v-for="(item, key) in pedido.pedido_details"
                                        :key="item.id"
                                    >
                                        <td>{{ key + 1 }}</td>
                                        <td>
                                            <v-img
                                                :src="item.product_detail.image_url"
                                                alt=""
                                                width="100"
                                                class="mt-1 mb-1"
                                            ></v-img>
                                        </td>
                                        <td>{{ item.product_detail.description }}</td>
                                        <td>
                                            <p>Color: {{ item.product_detail.color }}</p>
                                            <p>Capacidad: {{ item.product_detail.capacity }}</p>
                                        </td>
                                        <td>Un</td>
                                        <td>
                                            <v-text-field
                                                type="number"
                                                label="Cantidad"
                                                v-model="pedido.pedido_details[key].cantidad"
                                                @change="updateParcial(key)"
                                                :rules="[
                          rules.min(1, pedido.pedido_details[key]),
                          rules.max(
                            item.product_detail.stock,
                            pedido.pedido_details[key]
                          ),
                        ]"
                                            ></v-text-field>
                                        </td>
                                        <td>{{ item.product_detail.stock }}</td>
                                        <!--                                        <td>{{ item.product_detail.stockreserve }}</td>-->
                                        <td>{{ item.price }}</td>
                                        <td>{{ item.parcial }}</td>
                                        <td>
                                            <v-icon small @click="deleteItem(item)">
                                                mdi-delete
                                            </v-icon>
                                        </td>
                                    </tr>
                                    </tbody>
                                </template>
                            </v-simple-table>
                        </v-card>
                        <div>
                            <div class="d-flex flex-row-reverse">
                                <div class="pa-2">
                                    <v-simple-table>
                                        <template v-slot:default>
                                            <tbody>
                                            <tr>
                                                <td>Sub total:</td>
                                                <td>{{ sub_total }}</td>
                                            </tr>
                                            <tr>
                                                <td>Monto total:</td>
                                                <td>{{ monto_total }}</td>
                                            </tr>
                                            <tr>
                                                <td>Flete:</td>
                                                <td>{{ flete }}</td>
                                            </tr>
                                            <!--<tr>
                                            <td>Taxes:</td>
                                            <td>{{ taxes }}</td>
                                        </tr>-->
                                            <tr>
                                                <td>Pedido total:</td>
                                                <td>{{ pedido_total }}</td>
                                            </tr>
                                            </tbody>
                                        </template>
                                    </v-simple-table>
                                </div>
                            </div>
                        </div>
                    </v-col>
                </v-row>

                <v-row>
                    <v-col cols="12" md="4" v-if="pedido.business">
                        <v-text-field
                            v-model="pedido.business.name"
                            label="Razón social"
                            readonly
                        ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="4" v-if="pedido.business">
                        <v-text-field
                            v-model="pedido.business.clientid"
                            label="Razón social"
                            readonly
                        ></v-text-field>
                    </v-col>
                    <!--<v-col
                            cols="12"
                            md="4">
                        <v-combobox
                                v-model="pedido.contenido_factura"
                                :items="factura_contenidos"
                                label="Contenido Factura"
                        ></v-combobox>
                    </v-col>-->
                </v-row>
                

                <v-row>
                    <v-col cols="12" md="4">
                        <v-btn
                            :disabled="!valid || isEmptyPedidoDetail || sending"
                            color="success"
                            class="mr-4"
                            @click="submit"
                            v-if="!pedido.id"
                            :loading="sending"
                        >
                            Guardar
                        </v-btn>
                        <v-btn
                            :disabled="!valid || !isPorConfirmar || sending"
                            color="success"
                            class="mr-4"
                            @click="submitUpdate"
                            v-if="pedido.id"
                            :loading="sending"
                        >
                            Actualizar
                        </v-btn>
                    </v-col>
                </v-row>
            </v-container>
        </v-form>
    </div>
</template>
<style scoped>
.v-dialog {
    max-height: 82% !important;
    margin-top: 74px;
}
</style>
<style>
.img_detail {
    width: 100px;
}
</style>
<script>
// @ is an alias to /src
import pedidoService from "@/services/pedidoManualService";
import productService from "@/services/productService";
import clientService from "@/services/clientService";
import PedidoAddress from "@/components/pedido/PedidoAddress";
import PedidoAddressAgencia from "@/components/pedido/PedidoAddressAgencia";
import PedidoTabs from "@/components/pedido/PedidoTabs";
import ubigeoService from "@/services/ubigeoService";
import EmpresaModalCreate from "@/components/pedido-manual/EmpresaModalCreate";

export default {
    props: ["pedido", "edit_codigo_bancario"],
    name: "PedidoForm",
    components: {
        PedidoTabs,
        PedidoAddress,
        PedidoAddressAgencia,
        EmpresaModalCreate,
    },
    data: () => ({
        type_status: "pedidos-manual",
        valid: false,
        sub_total: 0,
        monto_total: 0,
        factura_contenidos: ["Detalles producto", "General"],
        loading: false,
        business: [],
        transaction_types: [
            {id: 1, text: "BTB"},
            {id: 2, text: "BTC"},
        ],
        products: [],
        departments: [],
        departments_agencia: [],
        provinces_agencia: [],
        districts_agencia: [],
        ubigeo_agencias: [],
        provinces: [],
        districts: [],
        searchBusiness: null,
        searchProduct: null,
        productSelect: null,
        select: null,
        dialogDelete: false,
        rules: {
            client: [(v) => !!v || "Cliente es requerido"],
            name: [(v) => !!v || "Nombre es requerido"],
            // address: [(v) => !!v || "Dirección es requerido"],
            min(min, v) {
                this.valid = parseInt(v.cantidad) >= min;
                return (
                    parseInt(v.cantidad) >= min || `Valor debe ser por lo menos ${min}`
                );
            },
            max(max, v) {
                if (v.old_cantidad) {
                    this.valid = parseInt(v.cantidad) - parseInt(v.old_cantidad) <= max;
                    return (
                        parseInt(v.cantidad) - parseInt(v.old_cantidad) <= max ||
                        `Valor no debe ser mayor a ${max}.`
                    );
                } else {
                    this.valid = parseInt(v.cantidad) <= max;
                    return (
                        parseInt(v.cantidad) <= max || `Valor no debe ser mayor a ${max}.`
                    );
                }
            },
        },
        validPedidoAddressIncompleto: true,
        sending: false,
        overlay: false,
        snackbar: false,
        snackbarColor: 'red',
        textError: '',
    }),
    watch: {
        pedido: {
            handler: function (newValue) {
                if (!this.pedido.business_address) {
                    this.validPedidoAddressIncompleto = true;
                    return;
                }
                if (!this.pedido.business_address.address) {
                    this.validPedidoAddressIncompleto = true;
                    return;
                }
                if (!this.pedido.business_address.department) {
                    this.validPedidoAddressIncompleto = true;
                    return;
                }
                if (!this.pedido.business_address.province) {
                    this.validPedidoAddressIncompleto = true;
                    return;
                }
                if (!this.pedido.business_address.district) {
                    this.validPedidoAddressIncompleto = true;
                    return;
                }
                if (!this.pedido.business_address.consignatario) {
                    this.validPedidoAddressIncompleto = true;
                    return;
                }
                if (!this.pedido.business_address.contact_phone) {
                    this.validPedidoAddressIncompleto = true;
                    return;
                }
                if (!this.pedido.business_address.document_number) {
                    this.validPedidoAddressIncompleto = true;
                    return;
                }
                this.validPedidoAddressIncompleto = false;
                // console.log(newValue.status);
            },
            deep: true,
        },
        searchBusiness(val) {
            val && val !== this.select && this.getBusiness(val);
        },
        searchProduct(val) {
            val && val !== this.select && this.getProducts(val);
        },
        dialogDelete(val) {
            val || this.closeDelete();
        },
        "pedido.pedido_details"(val) {
            val && val !== this.select && this.recalculate();
        },
        "pedido.client"(val) {
            this.setUbigeo();
        },
    },
    computed: {
        userSession: function () {
            return this.$store.getters.userSession
        },
        taxes() {
            return parseFloat(this.monto_total * 0.18).toFixed(2);
        },
        pedido_total() {
            // return parseFloat(this.monto_total * 1.18).toFixed(2);
            return parseFloat(Number(this.monto_total) + Number(this.flete)).toFixed(
                2
            );
        },
        pedido_sub_total() {
            return parseFloat(this.monto_total / 1.18).toFixed(2);
        },
        flete() {
            this.recalculate();
            if (this.monto_total >= 2000) {
                return 0;
            } else if (this.pedido.business_address.district) {
                return this.pedido.business_address.district.price;
            } else if (this.pedido.client) {
                return this.pedido.client.price;
            } else {
                return 0;
            }
        },
        isEmptyPedidoDetail() {
            return this?.pedido?.pedido_details?.length === 0;
        },
        isPorConfirmar() {
            if (this.pedido.status == 2 || this.pedido.status == 3) {
                return this.pedido.nfactura;
            } else {
                return true;
            }
        },
    },
    mounted() {
        console.log(this.pedido);
        this.type_status = this.$route.query.type
            ? this.$route.query.type
            : "pedidos-manual";
        // console.log('ACA');
        // console.log(this.pedido);
        // console.log(this.pedido.flexline_imei_sync);

        this.getDepartments();
        this.getDepartmentsAgencia();
        console.log('role', this.userSession.role);
        if (this.userSession.role == 'partner') {
            clientService.getClientById(this.userSession.client_id).then(response => {
                this.$set(this.pedido, 'client', response.data)
                this.$set(this.pedido, 'client_id', this.userSession.client_id)
            });
            this.$set(this.pedido, 'is_partner', 1)
        }
    },
    methods: {
        submit() {
            console.log("antes de salir", this.type_status);
            this.pedido.flete = this.flete;
            this.pedido.subtotal = this.monto_total;
            this.pedido.subtotal_igv = this.taxes;
            this.pedido.total = this.pedido_total;
            this.sending = true;
            pedidoService.storePedidoBusiness(this.pedido).then(
                (response) => {
                    if (response.data.success) {
                        if (response.data.download) {
                            var pedido_id = response.data.pedido.id;
                            pedidoService.downloadPedidoBusinessCoti(pedido_id).then((response) => {
                                const blob = new Blob([response.data], {
                                    type: "application/pdf",
                                });
                                const link = document.createElement("a");
                                link.href = URL.createObjectURL(blob);
                                link.download = "cotizacion_" + pedido_id;
                                link.click();
                                URL.revokeObjectURL(link.href);
                                this.$router.push({path: "/" + this.type_status});
                                this.sending = false;
                            });
                        } else {
                            this.$router.push({path: "/" + this.type_status});
                        }
                    } else {
                        console.log("error", error);
                    }
                },
                (error) => {
                    console.log("error", error);
                    this.sending = false;
                    this.textError = error.response.data.message;
                    this.snackbar = true;
                }
            );
        },
        submitUpdate() {
            this.pedido.flete = this.flete;
            this.pedido.subtotal = this.monto_total;
            this.pedido.subtotal_igv = this.taxes;
            this.pedido.total = this.pedido_total;
            this.sending = true;
            pedidoService.updatePedido(this.pedido).then(
                (response) => {
                    this.sending = false;
                    if (response.data.success) {
                        if (response.data.download) {
                            var pedido_id = response.data.pedido.id;
                            pedidoService.downloadPagado(pedido_id).then((response) => {
                                const blob = new Blob([response.data], {
                                    type: "application/pdf",
                                });
                                const link = document.createElement("a");
                                link.href = URL.createObjectURL(blob);
                                link.download = "pedido_pagado_" + pedido_id;
                                link.click();
                                URL.revokeObjectURL(link.href);
                                this.$router.push({path: "/" + this.type_status});
                            });
                        } else {
                            this.$router.push({path: "/" + this.type_status});
                        }
                    } else {
                        this.textError = error.response.data.message;
                        this.snackbar = true;
                    }
                },
                (error) => {
                    console.log("error", error);
                    this.sending = false;
                    if (error.response) {
                        this.textError = error.response.data.message;
                    } else {
                        this.textError = 'Ocurrió un error, por favor inténtelo nuevamente.';
                    }
                    this.snackbar = true;
                }
            );
        },
        getDepartments() {
            ubigeoService.getDepartments().then((response) => {
                this.departments = response.data;
            });
        },
        getDepartmentsAgencia() {
            ubigeoService.getDepartmentsAgencia().then((response) => {
                this.departments_agencia = response.data;
            });
        },
        setUbigeo() {
            if (this.pedido.business_address?.district?.id) {
                console.log("datosdddd");

                pedidoService
                    .getUbigeo(this.pedido.business_address.district.id)
                    .then((result) => {
                        this.departments = result.data.departments;
                        this.provinces = result.data.provinces;
                        this.districts = result.data.districts;
                        this.pedido.business_address.department = this.departments.find(
                            (items) =>
                                items.id === this.pedido.business_address.district.department_id
                        );
                        this.pedido.business_address.province = this.provinces.find(
                            (items) =>
                                items.id === this.pedido.business_address.district.province_id
                        );
                        this.pedido.business_address.district = this.districts.find(
                            (items) => items.id === this.pedido.business_address.district.id
                        );
                    });
                if (this.pedido.delivery_type === 2) {
                    console.log("datos");
                    pedidoService
                        .getUbigeoAgencia(this.pedido.business_address.ag_district.id)
                        .then((result) => {
                            this.departments_agencia = result.data.departments;
                            this.provinces_agencia = result.data.provinces;
                            this.districts_agencia = result.data.districts;
                            this.ubigeo_agencias = result.data.ubigeo_agencias;
                            this.pedido.business_address.ag_department =
                                this.departments_agencia.find(
                                    (items) =>
                                        items.id ===
                                        this.pedido.business_address.ag_district.department_id
                                );
                            this.pedido.business_address.ag_province =
                                this.provinces_agencia.find(
                                    (items) =>
                                        items.id ===
                                        this.pedido.business_address.ag_district.province_id
                                );
                            this.pedido.business_address.ag_district =
                                this.districts_agencia.find(
                                    (items) =>
                                        items.id === this.pedido.business_address.ag_district.id
                                );
                            this.pedido.business_address.agencia_ubigeo =
                                this.ubigeo_agencias.find(
                                    (items) =>
                                        items.id === this.pedido.business_address.agencia_ubigeo.id
                                );
                        });
                }
            }
        },
        recalculate() {
            this.monto_total = this.pedido.pedido_details.reduce((prev, cur) => {
                return prev + cur.parcial;
            }, 0);
            this.sub_total = (this.monto_total / 1.18).toFixed(2);
        },
        selectProduct(e) {
            this.pedido.pedido_details.push({
                cantidad: 1,
                price: e.price,
                parcial: e.price,
                product_detail: e,
            });
            this.$nextTick(() => {
                this.productSelect = null;
            });
        },
        updateParcial(index) {
            //this.monto_total += e.price;
            this.pedido.pedido_details[index].parcial =
                this.pedido.pedido_details[index].cantidad *
                this.pedido.pedido_details[index].price;
            this.$set(
                this.pedido.pedido_details,
                index,
                this.pedido.pedido_details[index]
            );
        },
        getBusiness(v) {
            this.loading = true;
            pedidoService.searchBusiness(v).then((response) => {
                this.business = response.data;
                this.loading = false;
            });
        },
        getProducts(v) {
            this.loading = true;
            let business_id = this.pedido.business ? this.pedido.business.id : null;
            let type_transaction = this.pedido.type_transaction ? this.pedido.type_transaction : 1;
            productService.searchProductsBusiness(v, business_id, type_transaction).then((response) => {
                this.loading = false;
                let products = [];
                response.data.forEach(product => {
                    if (product.stock > 0 && product.price > 0) {
                        product.price = Number(product.price);
                        products.push(product);
                    }
                })
                this.products = products;
            });
        },
        deleteItem(item) {
            this.editedIndex = this.pedido.pedido_details.indexOf(item);
            this.dialogDelete = true;
        },

        deleteItemConfirm() {
            this.pedido.pedido_details.splice(this.editedIndex, 1);
            this.closeDelete();
        },
        closeDelete() {
            this.dialogDelete = false;
        },
        handleImage(e) {
            this.createBase64Image(e);
        },

        createBase64Image(fileObject) {
            const reader = new FileReader();
            reader.onload = (e) => {
                pedidoService.uploadPhoto(e.target.result).then(
                    (response) => {
                        this.pedido.adjunto = response.data.fileName;
                        this.pedido.adjunto_url = response.data.url;
                    },
                    (error) => {
                        console.log("error", error);
                    }
                );
            };
            reader.readAsDataURL(fileObject);
        },
        syncImeis() {
            this.overlay = true;
            pedidoService.syncImeisBitel(this.pedido.id).then(response => {
                this.overlay = false;
                if (response.data.data.sync_bitel) {
                    this.pedido.response_code_bitel = response.data.data.sync_bitel.response_code_bitel;
                    this.pedido.response_message_bitel = response.data.data.sync_bitel.response_message_bitel;
                    this.pedido.response_bitel_datetime = response.data.data.sync_bitel.response_bitel_datetime;
                }
            }).catch(error => {
                this.overlay = false;
                this.textError = error.response.data.message;
                this.snackbar = true;
            })
        },
        syncImeisFlexLine() {
            this.overlay = true;
            pedidoService.syncImeisFlexline(this.pedido.id).then(response => {
                this.overlay = false;
                this.textError = response.data.data.message;
                this.snackbarColor = response.data.data.status ? 'success' : 'error';
                this.snackbar = true;
                setTimeout(() => {
                    this.snackbarColor = 'error';
                }, 2000);
            }).catch(error => {
                this.overlay = false;
                this.textError = error.response.data.message;
                this.snackbar = true;
            })
        }
    },
};
</script>
