<template>
    <v-form v-model="valid" v-on:submit="submit" class="elevation-2 py-4">
        <v-container>
            <v-row>
                <v-col cols="12" md="6">
                    <h4 class="mb-3">Canal</h4>
                    <v-select
                        v-model="subchannel.channel_id"
                        :rules="rules.channel_id"
                        :items="channels"
                        required
                        outlined
                        solo
                        item-text="name"
                        item-value="id"
                        label="Canal"
                    ></v-select>
                </v-col>
                <v-col cols="12" md="6">
                    <h4 class="mb-3">Nombre</h4>
                    <v-text-field
                            v-model="subchannel.name"
                            :rules="rules.name"
                            label="Nombre"
                            required
                            outlined
                            solo
                    ></v-text-field>
                </v-col>
            </v-row>

            <v-row>
                <v-col
                        cols="12"
                        md="12"
                        class="d-flex">
                    <v-btn
                            :disabled="!valid"
                            class="btn-blue mx-auto"
                            @click="submit"
                            v-if="!subchannel.id"
                    >
                        Agregar subcanal
                    </v-btn>
                    <v-btn
                            :disabled="!valid"
                            color="success"
                            class="mr-4"
                            @click="submitUpdate"
                            v-if="subchannel.id"
                    >
                        Actualizar
                    </v-btn>
                </v-col>
            </v-row>
        </v-container>
    </v-form>
</template>

<script>
    import channelService from "@/services/channelService";
    import subchannelService from "@/services/subchannelService";

    export default {
        name: "ChannelForm",
        props: ["subchannel"],
        data: () => ({
            valid: false,
            alert: false,
            rules: {
                name: [
                    v => !!v || 'Nombre es requerido',
                ],
                channel_id: [
                    v => !!v || 'Canal es requerido',
                ],
            },
            channels: [],
        }),
        mounted() {
            channelService.all().then(response => {
                this.channels = response.data.data;
            }).catch(error => {
                console.log('error', error);
            })
        },
        methods: {
            submit() {
                subchannelService.store(this.subchannel).then(response => {
                    this.$router.push({path: '/subchannels'})
                }, (error, data) => {
                    this.alert = true;
                    setTimeout(() => {
                        this.alert = false
                    }, 3000);
                });
            },
            submitUpdate() {
                subchannelService.update(this.subchannel).then(response => {
                    this.$router.push({path: '/subchannels'})
                }, error => {
                    console.log('error', error);
                });
            },
        }
    }
</script>

<style scoped>

</style>
