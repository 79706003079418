<template>
    <div>
        <Breadcrumbs :items="breadcrumbs"/>
        <v-card>
            <v-data-table
                    :headers="headers"
                    :items="subchannels"
                    :options.sync="options"
                    :server-items-length="totalSubchannels"
                    :loading="loading"
                    :items-per-page="25"
                    class="elevation-1">
                <template v-slot:top>
                    <v-toolbar flat
                        class="py-4">
                        <v-dialog v-model="dialogDelete" max-width="500px">
                            <v-card>
                                <v-card-title class="headline">Estás seguro de eliminar este subcanal?
                                </v-card-title>
                                <v-card-actions>
                                    <v-spacer></v-spacer>
                                    <v-btn color="blue darken-1" text @click="closeDelete">Cancelar</v-btn>
                                    <v-btn color="blue darken-1" text @click="deleteItemConfirm">
                                        Sí, estoy seguro
                                    </v-btn>
                                    <v-spacer></v-spacer>
                                </v-card-actions>
                            </v-card>
                        </v-dialog>
                        <v-toolbar-title>Subcanales</v-toolbar-title>
                        <v-spacer></v-spacer>
                        <v-form>
                           <v-text-field
                                outlined
                                v-model="search_subchannel"
                                label="Buscar Subcanal"
                                prepend-inner-icon="mdi-magnify"
                                dense
                                class="mr-3 input-saco"
                            ></v-text-field>
                        </v-form>
                        <v-btn  dark 
                            class="btn-green" to="/subchannels/create">
                            <v-icon left>
                                mdi-plus
                            </v-icon>
                            Agregar Subcanal
                        </v-btn>
                    </v-toolbar>
                </template>
                <template v-slot:item.actions="{ item }">
                    <v-btn icon :to="'/subchannels/edit/' + item.id">
                        <v-icon small class="mr-2">
                            mdi-square-edit-outline
                        </v-icon>
                    </v-btn>
                    <v-icon
                            small
                            @click="deleteItem(item.id)"
                    >
                        mdi-trash-can-outline
                    </v-icon>
                </template>
            </v-data-table>
        </v-card>
    </div>
</template>

<script>
    // @ is an alias to /src
    import Breadcrumbs from "@/components/layout/Breadcrumbs";
    import subchannelService from "@/services/subchannelService";

    export default {
        name: 'Subcanales',
        components: {
            Breadcrumbs,
        },
        data: () => ({
            breadcrumbs: [
                {text: 'Inicio', disabled: false, href: '/',},
                {text: 'Subcanales', disabled: true, href: '/subchannels',}
            ],
            search_subchannel: '',
            totalSubchannels: 0,
            subchannels: [],
            loading: true,
            dialogDelete: false,
            options: {query: {subchannel: ''}},
            editedIndex: null,
            headers: [
                {
                    text: 'ID',
                    align: 'start',
                    sortable: false,
                    value: 'id',
                },
                {
                    text: 'Canal',
                    sortable: false,
                    value: 'channel.name',
                },
                {
                    text: 'Subcanal',
                    sortable: false,
                    value: 'name',
                },
                {text: 'Acciones', value: 'actions', sortable: false},
            ],
        }),
        watch: {
            params: {
                handler() {
                    this.getDataFromApi()
                },
                deep: true,
            },
        },
        computed: {
            params(nv) {
                this.options.query.subchannel = this.search_subchannel;
            }
        },
        mounted() {
            this.getDataFromApi()
        },
        methods: {
            editItem(item) {
            },
            deleteItem(item) {
                this.dialogDelete = true;
                this.editedIndex = item;
            },
            deleteItemConfirm() {
                subchannelService.destroy(this.editedIndex).then(result => {
                    this.getDataFromApi()
                    this.closeDelete()
                })
            },
            closeDelete() {
                this.dialogDelete = false
            },
            getDataFromApi() {
                this.loading = true
                subchannelService.datatable(this.options).then(response => {
                    // console.log('data_api',response);
                    this.subchannels = response.data.items;
                    this.totalSubchannels = response.data.total;
                    this.loading = false
                })
            },
        },
    }
</script>
