<template>
  <div>
    <Breadcrumbs :items="breadcrumbs"/>
    <v-card>
      <v-data-table
          :headers="headers"
          :items="bodegas"
          :options.sync="options"
          :server-items-length="totalBodegas"
          :loading="loading"
          :items-per-page="25"
          class="elevation-1"
      >
        <template v-slot:top>
          <v-toolbar
              flat
              class="py-4"
          >
            <v-dialog v-model="dialogDelete" max-width="500px">
              <v-card>
                <v-card-title class="headline">Estás seguro de eliminar esta bodega?
                </v-card-title>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="blue darken-1" text @click="closeDelete">Cancelar</v-btn>
                  <v-btn color="blue darken-1" text @click="deleteItemConfirm">Sí, estoy
                    seguro
                  </v-btn>
                  <v-spacer></v-spacer>
                </v-card-actions>
              </v-card>
            </v-dialog>
            <v-toolbar-title>Bodegas</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-form>
                <v-text-field
                  v-model="search_bodega"
                  outlined
                  label="Buscar bodega"
                  prepend-inner-icon="mdi-magnify"
                  dense
                  class="mr-3 input-saco"
                ></v-text-field>
            </v-form>
            <v-btn
                dark
                class="btn-green"
                to="/bodegas/create"
              >      
              <v-icon left>
                mdi-plus
              </v-icon>
                <v-icon left>fas fa-search</v-icon>
                Agregar bodega
            </v-btn>
          </v-toolbar>
        </template>
        <template v-slot:item.actions="{ item }">
          <v-btn icon :to="'/bodegas/edit/' + item.id">
            <v-icon small class="mr-2">
              mdi-square-edit-outline
            </v-icon>
          </v-btn>
          <v-icon
              small
              @click="deleteItem(item.id)"
          >
            mdi-trash-can-outline
          </v-icon>
        </template>
        <template v-slot:item.image_url="{ item }">
          <div class="p-2 mt-1 mb-1">
            <v-img :src="item.image_url" :alt="item.bodega.name" width="70px"></v-img>
          </div>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
// @ is an alias to /src
import Breadcrumbs from "@/components/layout/Breadcrumbs";
import bodegaService from "@/services/bodegaService";

export default {
  name: 'Listas',
  components: {
    Breadcrumbs,
  },
  data: () => ({
    breadcrumbs: [
      {text: 'Inicio', disabled: false, href: '/',},
      {text: 'Listas', disabled: true, href: '/bodegas',}
    ],
    search_bodega:'',
    totalBodegas: 0,
    bodegas: [],
    loading: true,
    dialogDelete: false,
    options: {query:{bodega:''}},
    editedIndex: null,
    headers: [
      {
        text: 'ID',
        align: 'start',
        sortable: false,
        value: 'id',
      },
      {
        text: 'Bodega',
        sortable: false,
        value: 'name',
      },
      {
        text: 'Descripción',
        sortable: false,
        value: 'description',
      },
      { text: 'Actions', value: 'actions', sortable: false },
    ],
  }),
  watch: {
    params: {
      handler () {
        this.getDataFromApi()
      },
      deep: true,
    },
  },
  computed: {
    params(nv) {
      this.options.query.bodega = this.search_bodega;
    }
  },
  mounted () {
    this.getDataFromApi()
  },
  methods: {
    editItem (item) {
      console.log(item);
    },
    deleteItem(item) {
      console.log(item)
      this.dialogDelete = true
      this.editedIndex = item
    },
    deleteItemConfirm() {
      bodegaService.deleteBodega(this.editedIndex).then(result => {
        this.getDataFromApi()
        this.closeDelete()
      })
    },
    closeDelete() {
      this.dialogDelete = false
    },
    getDataFromApi () {
      this.loading = true
      bodegaService.bodegasDatatable(this.options).then(response => {
        // console.log('data_api',response);
        this.bodegas = response.data.items;
        this.totalBodegas = response.data.total;
        this.loading = false
      })
    },

  },
}
</script>
