<template>
  <v-form v-model="valid" v-on:submit="submit"
          class="elevation-2 py-4">
    <v-container>
      <v-row >
        <v-col
            cols="12"
            md="4"
        >
         <h4 class="mb-3">Nombre del producto</h4>
          <v-text-field
              v-model="user.name"
              :rules="rules.name"
              label="Nombre"
              required
              outlined
               solo
          ></v-text-field>
        </v-col>

        <v-col
            cols="12"
            md="4"
        >
         <h4 class="mb-3">Email</h4>
          <v-text-field
              v-model="user.email"
              :rules="rules.email"
              label="Email"
              required
              outlined
              solo
          ></v-text-field>
        </v-col>
        <v-col
            cols="12"
            md="4"
            v-if="!user.id"
        >

         <h4 class="mb-3">Password</h4>
          <v-text-field
              v-model="user.password"
              :rules="rules.password"
              label="Password"
              type="password"
              required
              outlined
              solo
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>

        <v-col
            cols="12"
            md="4"
        >
         <h4 class="mb-3">Name code</h4>
          <v-text-field
              v-model="user.name_code"
              label="Name code"
              outlined
              solo
          ></v-text-field>
        </v-col>
        <v-col
            cols="12"
            md="4"
        >
         <h4 class="mb-3">Staff code</h4>
          <v-text-field
              required
              v-model="user.staff_code"
              label="Staff code"
              outlined
              solo
          ></v-text-field>
        </v-col>
        <v-col
            cols="12"
            md="4"
        >
         <h4 class="mb-3">Dirección</h4>
          <v-text-field
              required
              v-model="user.address"
              label="Dirección"
              outlined
              solo
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>

        <v-col
            cols="12"
            md="4"
        >
         <h4 class="mb-3">Teléfono</h4>
          <v-text-field
              v-model="user.phone"
              :rules="rules.phone"
              label="Teléfono"
              required
              outlined
              solo
          ></v-text-field>
        </v-col>
        <v-col
            cols="12"
            md="4"
        >
         <h4 class="mb-3">Rol</h4>
          <v-combobox
              v-model="user.role"
              :rules="rules.role"
              :items="roles"
              @change="changeRole()"
              label="Rol"
              outlined
              solo
          ></v-combobox>
        </v-col>
         <v-col v-if="status_lista_precios == true"
            cols="12"
            md="4"
        >
         <h4 class="mb-3">Lista de precio</h4>
          <v-combobox
              v-model="user.lista_precio"
              :items="listas"
              item-text="name"
              item-value="name"
              label="Lista de precio"
              outlined
              solo
          ></v-combobox>
        </v-col>
      </v-row>

      <v-row>
        <v-col v-if="status_cliente == true"
            cols="12"
            md="4"
        >
          <h4 class="mb-3">Cliente</h4>
          <v-combobox
              v-model="user.client"
              :items="clientes"
              item-text="name"
              item-value="name"
              label="Clientes"
              outlined
              solo
          ></v-combobox>
        </v-col>
      </v-row>
      <v-row>
        <v-col
            cols="12"
            md="12"
            class="d-flex">
          <v-btn
              :disabled="!valid"
              class="mx-auto btn-blue"
              @click="submit"
              v-if="!user.id"
          >
            Agregar usuario
          </v-btn>
          <v-btn
              :disabled="!valid"
              class="mr-4 btn-blue"
              @click="submitUpdate"
              v-if="user.id"
          >
            Actualizar
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
  </v-form>
</template>
<style>
.img_detail {
  width: 100px;
}
</style>
<script>
// @ is an alias to /src
import userService from "@/services/userService";
import listaService from "@/services/listaService";
import clientService from "@/services/clientService";

export default {
  props: ["user",],
  name: 'UserForm',
  components: {},
  data: () => ({
    valid: false,
    status_lista_precios: true,
    status_cliente: true,
    departments: [],
    provinces: [],
    districts: [],
    roles:['saco','bitel','partner','P1-EjecutivoVentas','P1-Supervisor','P2-EjecutivoVentas','P2-Supervisor'],
    listas:[],
    clientes:[],
    rules: {
      email: [
        v => !!v || 'Email es requerido',
      ],
      name: [
        v => !!v || 'Nombre es requerido',
      ],
      password: [
        v => !!v || 'Password es requerido',
      ],
      role: [
        v => !!v || 'Rol es requerido',
      ],
    },
  }),
  mounted () {
    this.getListas();
    this.getClientes();
  },
  methods: {
    changeRole(){
      if(this.user.role == 'P1-EjecutivoVentas' || this.user.role == 'P1-Supervisor' || this.user.role == 'P2-EjecutivoVentas' || this.user.role == 'P2-Supervisor') {
        this.status_lista_precios = false;
        this.status_cliente = false;
        this.user.client = null;
        this.user.lista_precio = null;
      }else{
        this.status_lista_precios = true;
        this.status_cliente = true;
      }
    },
    getListas() {
      listaService.getListas().then(response => {
        if(response.status === 200){
          this.listas = response.data
        }
      })
    },
    getClientes() {
      clientService.getClientes().then(response => {
        if(response.status === 200){
          this.clientes = response.data
        }
      })
    },
    submit() {
      userService.storeUser(this.user).then(response=>{
        if(response.data.success){
          this.$router.push({ path: '/users' })
        }
      },error=>{
        console.log('error', error);
      });
    },
    submitUpdate() {
      userService.updateUser(this.user).then(response=>{
        if(response.data.success){
          this.$router.push({ path: '/users' })
        }
      },error=>{
        console.log('error', error);
      });
    },
  }
}
</script>
