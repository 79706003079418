<template>
  <v-card>
    <v-tabs
      v-model="tab"
      background-color="transparent"
      color="secondary accent-4"
    >
      <v-tab
        v-for="item in items"
        v-if="type_status == item.type_status"
        :key="item.tab"
        :disabled="
          !item.roles.includes(userSession.role) ||
          !(pedido.status >= item.status)
        "
      >
        {{ item.tab }}
      </v-tab>
    </v-tabs>
    <v-divider></v-divider>
    <PedidoSteps :status="pedido.status" />
    <v-divider></v-divider>
    <v-tabs-items v-model="tab">
      <v-tab-item
        v-for="item in items"
        v-if="type_status == item.type_status"
        :key="item.tab"
      >
        <v-card flat>
          <v-card-text>
            <component v-bind:is="item.content" :pedido="pedido"></component>
          </v-card-text>
        </v-card>
      </v-tab-item>
    </v-tabs-items>
  </v-card>
</template>

<script>
// @ is an alias to /src
import PedidoSteps from "@/components/pedido/PedidoSteps";
import DetallePedido from "@/components/pedido/DetallePedido";
import PedidoPago from "@/components/pedido/PedidoPago";
import PedidoTracking from "@/components/pedido/PedidoTracking";
import PedidoEntregado from "@/components/pedido/PedidoEntregado";
import PedidoLog from "@/components/pedido/PedidoLog";
import PedidoFactura from "@/components/pedido/PedidoFactura";

export default {
  props: ["pedido"],
  computed: {
    userSession: function () {
      return this.$store.getters.userSession;
    },
  },
  name: "PedidoTabs",
  components: {
    PedidoSteps,
    DetallePedido,
    PedidoPago,
    PedidoTracking,
    PedidoEntregado,
    PedidoLog,
    PedidoFactura,
  },
  watch: {
    pedido: {
      handler: function (newValue) {
        // console.log(newValue.status);
      },
      deep: true,
    },
  },
  mounted() {
    this.type_status = this.$route.query.type ? this.$route.query.type : "";

    //this.items[5].show = this.type_status === 'tracking';
  },
  data: () => ({
    tab: null,
    type_status: null,
    items: [
      {
        tab: "Detalles",
        status: 0,
        roles: ["admin", "bitel", "saco"],
        type_status: "",
      },
      {
        tab: "Pago",
        content: "PedidoPago",
        status: 1,
        roles: ["admin", "bitel"],
        type_status: "",
      },
      {
        tab: "Despacho",
        content: "PedidoTracking",
        status: 3,
        roles: ["admin", "saco"],
        type_status: "tracking",
      },
      {
        tab: "Entregado",
        content: "PedidoEntregado",
        status: 4,
        roles: ["admin", "saco"],
        type_status: "tracking",
      },
      {
        tab: "Facturacion",
        content: "PedidoFactura",
        status: 0,
        roles: ["admin", "saco"],
        type_status: "facturacion",
      },
      {
        tab: "Despacho",
        content: "PedidoTracking",
        status: 3,
        roles: ["admin", "saco"],
        type_status: "facturacion",
      },
      {
        tab: "Entregado",
        content: "PedidoEntregado",
        status: 4,
        roles: ["admin", "saco"],
        type_status: "facturacion",
      },
      {
        tab: "Log",
        content: "PedidoLog",
        status: 0,
        show:false,
        roles: ["admin", "saco"],
        type_status: "tracking",
      },{
        tab: "Log",
        content: "PedidoLog",
        status: 0,
        show:false,
        roles: ["admin", "saco"],
        type_status: "facturacion",
      },
    ],
  }),
};
</script>
