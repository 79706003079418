<template>
    <v-form v-model="valid" v-on:submit="submit" class="elevation-2 py-4">
        <v-container>
            <v-row v-if="product.id">
                <v-col cols="12">
                    <h3></h3>
                </v-col>
            </v-row>
            <v-row>
                <v-col v-if="product.details.length > 0" cols="12" md="4">
                    <h4 class="mb-3">Código SACO</h4>
                    <v-text-field
                            v-model="product.details[0].codigo_saco"
                            readonly
                            label="Código SACO"
                            type="text"
                            outlined
                            solo
                    ></v-text-field>
                </v-col>
                <v-col v-if="product.details.length > 0" cols="12" md="4">
                    <h4 class="mb-3">Capacidad</h4>
                    <v-text-field
                            v-model="product.details[0].capacity"
                            label="Capacidad"
                            type="text"
                            required
                            outlined
                            solo
                    ></v-text-field>
                </v-col>
                <v-col v-if="product.details.length > 0" cols="12" md="4">
                    <h4 class="mb-3">Color</h4>
                    <v-text-field
                            v-model="product.details[0].color"
                            label="Color"
                            type="text"
                            required
                            outlined
                            solo
                    ></v-text-field>
                </v-col>
                <v-col cols="12" md="4">
                    <h4 class="mb-3">Nombre del producto</h4>
                    <v-text-field
                            v-model="product.name"
                            :rules="rules.name"
                            label="Nombre"
                            required
                            outlined
                            solo
                    ></v-text-field>
                </v-col>

                <v-col cols="12" md="4">
                    <h4 class="mb-3">Descripción</h4>
                    <v-text-field
                            v-model="product.description"
                            label="Descripción"
                            outlined
                            solo
                    ></v-text-field>
                </v-col>
                <v-col cols="12" md="4">
                    <h4 class="mb-3">Unidad de medida</h4>
                    <v-text-field v-model="product.unit" label="Unidad de medida" readonly
                            outlined
                            solo></v-text-field>
                </v-col>
                <v-col cols="12" md="4">
                    <h4 class="mb-3">Marcas</h4>
                    <v-select
                            v-model="product.marca_id"
                            :items="marcas"
                            item-text="nombre"
                            item-value="id"
                            label="Marcas"
                            outlined
                            solo
                    ></v-select>
                </v-col>
                <v-col cols="12" md="4">
                    <h4 class="mb-3">Categorías</h4>
                    <v-select
                            v-model="product.categoria_id"
                            :items="categorias"
                            item-text="nombre"
                            item-value="id"
                            label="Categorías"
                            outlined
                            solo
                    ></v-select>
                </v-col>
                <v-col cols="12" md="12">
                    <h4 class="mb-3">Descripción de la Web</h4>
                    <editor-content :editor="editor" />
                </v-col>
            </v-row>

            <v-row v-if="product.details.length > 0">
                <v-col cols="12" md="3">
                    <div>
                        <h4 class="mb-3">Foto Principal</h4>
                    </div>
                    <v-file-input
                            accept="image/*"
                            @change="handleImage(0,$event, 'image', 'image_url')"
                            label="Foto"
                            outlined
                    ></v-file-input>
                    <div>
                        <img class="img_detail" :src="product.details[0].image_url" alt="">
                    </div>
                </v-col>
                <v-col cols="12" md="3">
                    <div>
                        <h4 class="mb-3">Foto 2</h4>
                    </div>
                    <v-file-input
                            accept="image/*"
                            @change="handleImage(0,$event, 'image_2', 'image2_url')"
                            label="Foto"
                            outlined
                    ></v-file-input>
                    <div>
                        <img class="img_detail" :src="product.details[0].image2_url" alt="">
                    </div>
                </v-col>
                <v-col cols="12" md="3">
                    <div>
                        <h4 class="mb-3">Foto 3</h4>
                    </div>
                    <v-file-input
                            accept="image/*"
                            @change="handleImage(0,$event, 'image_3', 'image3_url')"
                            label="Foto"
                            outlined
                    ></v-file-input>
                    <div>
                        <img class="img_detail" :src="product.details[0].image3_url" alt="">
                    </div>
                </v-col>
                <v-col cols="12" md="3">
                    <div>
                        <h4 class="mb-3">Foto 4</h4>
                    </div>
                    <v-file-input
                            accept="image/*"
                            @change="handleImage(0,$event, 'image_4', 'image4_url')"
                            label="Foto"
                            outlined
                    ></v-file-input>
                    <div>
                        <img class="img_detail" :src="product.details[0].image4_url" alt="">
                    </div>
                </v-col>
                <v-col cols="12" md="3">
                    <div>
                        <h4 class="mb-4">Imagen</h4>
                    </div>
                    <v-file-input
                            accept="image/*"
                            @change="handleImage(0,$event, 'image_5', 'image5_url')"
                            label="Foto"
                            outlined
                    ></v-file-input>
                    <div>
                        <img class="img_detail" :src="product.details[0].image5_url" alt="">
                    </div>
                </v-col>
            </v-row>

            <!-- <div class="subtitle-2">Variaciones</div> -->
            <v-list-item v-for="(detail,key) in product.details" v-bind:key="key" class="px-0">
                <v-list-item-content>
                    <v-list-item-title class="font-bold mb-4" style="color:#1371CB;">Variante {{ key + 1 }}</v-list-item-title>
                        <v-container class="px-0">
                            <v-row>
                                <!--<v-col cols="12" md="4">
                                    <h4 class="mb-3">Capacidad</h4>
                                    <v-text-field
                                            v-model="detail.capacity"
                                            label="Capacidad"
                                            type="text"
                                            required
                                            outlined
                                            solo
                                    ></v-text-field>
                                </v-col>-->
                                <v-col cols="12" md="4">
                                    <h4 class="mb-3">Código</h4>
                                    <v-text-field
                                            v-model="detail.code"
                                            label="Código"
                                            type="text"
                                            required
                                            outlined
                                            solo
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="12" md="4">
                                    <h4 class="mb-3">Comisión</h4>
                                    <v-text-field
                                            v-model="detail.comision"
                                            label="Comisión"
                                            type="number"
                                            required
                                            outlined
                                            solo
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="12" md="4">
                                    <h4 class="mb-3">Precio</h4>
                                    <v-text-field
                                            v-model="detail.price"
                                            label="Precio"
                                            type="number"
                                            required
                                            outlined
                                            solo
                                    ></v-text-field>
                                </v-col>
                                <!--<v-col cols="12" md="4">
                                    <h4 class="mb-3">Color</h4>
                                    <v-text-field
                                            v-model="detail.color"
                                            label="Color"
                                            type="text"
                                            required
                                            outlined
                                            solo
                                    ></v-text-field>
                                </v-col>-->
                            </v-row>
                            <v-row>
                                <!--<v-col cols="12" md="4">
                                    <h4 class="mb-3">Código SACO</h4>
                                    <v-text-field
                                            v-model="detail.codigo_saco"
                                            label="Código SACO"
                                            type="text"
                                            outlined
                                            solo
                                    ></v-text-field>
                                </v-col>-->
                                <!--<v-col cols="12" md="4">
                                    <h4 class="mb-3">Comisión</h4>
                                    <v-text-field
                                            v-model="detail.comision"
                                            label="Comisión"
                                            type="number"
                                            required
                                            outlined
                                            solo
                                    ></v-text-field>
                                </v-col>-->
<!--                                 <v-col cols="12" md="4">
                                    <h4 class="mb-3">Precio B2B</h4>
                                    <v-text-field
                                            label="Precio B2B"
                                            type="number"
                                            required
                                            outlined
                                            solo
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="12" md="4">
                                    <h4 class="mb-3">Precio B2C</h4>
                                    <v-text-field
                                            label="Precio B2C"
                                            type="number"
                                            required
                                            outlined
                                            solo
                                    ></v-text-field>
                                </v-col> -->
                            </v-row>
                            <v-row>
                                <v-col cols="12" md="4">
                                    <h4 class="mb-3">Stock</h4>
                                    <v-text-field
                                            v-model="detail.stock"
                                            label="Stock"
                                            type="number"
                                            required
                                            outlined
                                            solo
                                    ></v-text-field>
                                </v-col>
                                <!--<v-col cols="12" md="4">
                                    <h4 class="mb-3">Precio</h4>
                                    <v-text-field
                                            v-model="detail.price"
                                            label="Precio"
                                            type="number"
                                            required
                                            outlined
                                            solo
                                    ></v-text-field>
                                </v-col>-->

                                <!--<v-col cols="12" md="12">
                                    <v-row>
                                        <v-col cols="12" md="3">
                                            <div>
                                                <h4 class="mb-3">Foto Principal</h4>
                                            </div>
                                            <v-file-input
                                                    accept="image/*"
                                                    @change="handleImage(key,$event, 'image', 'image_url')"
                                                    label="Foto"
                                                    outlined
                                            ></v-file-input>
                                            <div>
                                                <img class="img_detail" :src="detail.image_url" alt="">
                                            </div>
                                        </v-col>
                                        <v-col cols="12" md="3">
                                            <div>
                                                <h4 class="mb-3">Foto 2</h4>
                                            </div>
                                            <v-file-input
                                                    accept="image/*"
                                                    @change="handleImage(key,$event, 'image_2', 'image2_url')"
                                                    label="Foto"
                                                    outlined
                                            ></v-file-input>
                                            <div>
                                                <img class="img_detail" :src="detail.image2_url" alt="">
                                            </div>
                                        </v-col>
                                        <v-col cols="12" md="3">
                                            <div>
                                                <h4 class="mb-3">Foto 3</h4>
                                            </div>
                                            <v-file-input
                                                    accept="image/*"
                                                    @change="handleImage(key,$event, 'image_3', 'image3_url')"
                                                    label="Foto"
                                                    outlined
                                            ></v-file-input>
                                            <div>
                                                <img class="img_detail" :src="detail.image3_url" alt="">
                                            </div>
                                        </v-col>
                                        <v-col cols="12" md="3">
                                            <div>
                                                <h4 class="mb-3">Foto 4</h4>
                                            </div>
                                            <v-file-input
                                                    accept="image/*"
                                                    @change="handleImage(key,$event, 'image_4', 'image4_url')"
                                                    label="Foto"
                                                    outlined
                                            ></v-file-input>
                                            <div>
                                                <img class="img_detail" :src="detail.image4_url" alt="">
                                            </div>
                                        </v-col>
                                        <v-col cols="12" md="3">
                                            <div>
                                                <h4 class="mb-4">Imagen</h4>
                                            </div>
                                            <v-file-input
                                                    accept="image/*"
                                                    @change="handleImage(key,$event, 'image_5', 'image5_url')"
                                                    label="Foto"
                                                    outlined
                                            ></v-file-input>
                                            <div>
                                                <img class="img_detail" :src="detail.image5_url" alt="">
                                            </div>
                                        </v-col>
                                    </v-row>
                                </v-col>-->
                            </v-row>
                            <v-row>
                                <v-col cols="12" md="12">
                                    <v-list-item v-for="(bodegaItem,keyBodega) in detail.product_detail_bodegas"
                                                 v-bind:key="keyBodega">
                                        <v-list-item-content>
                                                <v-col cols="12" md="2">
                                                    <h4 class="mb-3">Canal</h4>
                                                    <v-text-field
                                                            v-bind:value=" bodegaItem.bodega.name "
                                                            label="Canal"
                                                            type="text"
                                                            readonly
                                                            outlined
                                                            solo
                                                    ></v-text-field>
                                                </v-col>
                                              <v-col cols="12" md="2">
                                                    <h4 class="mb-3">Stock Total</h4>
                                                    <v-text-field
                                                            v-bind:value="bodegaItem.stock"
                                                            label="Stock Total"
                                                            type="text"
                                                            readonly
                                                            outlined
                                                            solo
                                                    ></v-text-field>
                                                </v-col>
                                                <v-col cols="12" md="2">
                                                    <h4 class="mb-3">Stock Reservado</h4>
                                                    <v-text-field
                                                            v-model="bodegaItem.stock_reservado"
                                                            label="Stock Reservado"
                                                            type="text"
                                                            outlined
                                                            solo
                                                    ></v-text-field>
                                                </v-col>
                                                <v-col cols="12" md="2">
                                                    <h4 class="mb-3">Stock Pagado</h4>
                                                    <v-text-field
                                                            v-model="bodegaItem.stock_pagado"
                                                            label="Stock Pagado"
                                                            type="text"
                                                            outlined
                                                            solo
                                                    ></v-text-field>
                                                </v-col>
                                                <v-col cols="12" md="2">
                                                    <h4 class="mb-3">Stock Disponible</h4>
                                                    <v-text-field
                                                            v-bind:value="(bodegaItem.stock - bodegaItem.stock_pagado - bodegaItem.stock_reservado)"
                                                            label="Stock Disponible"
                                                            type="text"
                                                            readonly
                                                            outlined
                                                            solo
                                                    ></v-text-field>
                                                </v-col>
                                              <v-col cols="12" md="2" v-for="(listaprecioItem,keyListaPrecio) in detail.product_detail_lista_precio" :key="listaprecioItem.id" v-if="(listaprecioItem.lista_precio ? listaprecioItem.lista_precio.bodega_id : (listaprecioItem.partner_list_price ? listaprecioItem.partner_list_price.bodega_id : 0))==bodegaItem.bodega_id">
                                                    <h4 class="mb-3">{{(listaprecioItem.lista_precio ? listaprecioItem.lista_precio.name : listaprecioItem.partner_list_price.name)}}</h4>
                                                    <v-text-field
                                                            v-bind:value="listaprecioItem.price_list"
                                                            :label="(listaprecioItem.lista_precio ? listaprecioItem.lista_precio.name : listaprecioItem.partner_list_price.name) "
                                                            type="text"
                                                            readonly
                                                            outlined
                                                            solo
                                                    ></v-text-field>
                                                </v-col>
                                        </v-list-item-content>
                                    </v-list-item>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="12" md="3">
                                    <h4 class="mb-3">Compra por Rango</h4>
                                    <v-text-field
                                        label="Compra por Rango"
                                        type="number"
                                        outlined
                                        solo
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="12" md="3">
                                    <h4 class="mb-3">Precio por Rango</h4>
                                    <v-text-field
                                        label="Precio por Rango"
                                        type="number"
                                        outlined
                                        solo
                                    ></v-text-field>
                                </v-col>
                            </v-row>
                        </v-container>
                </v-list-item-content>
            </v-list-item>

            <v-row>
                <v-col cols="12" md="4">
                    <v-btn  class="mr-4 border-0" @click="addVariacion"
                    style="background:transparent;
                    box-shadow:none;
                    color:#1371CB;">
                    <v-icon left>
                        mdi-plus
                    </v-icon>
                        Agregar Variación
                    </v-btn>
                </v-col>
            </v-row>

            <v-row>
                <v-col cols="12" md="12" class="d-flex">
                    <v-btn :disabled="!valid" color="btn-blue" class="mx-auto" @click="submit" v-if="!product.id">
                        Agregar producto
                    </v-btn>
                    <v-btn :disabled="!valid" color="success" class="mx-auto" @click="submitUpdate" v-if="product.id">
                        Actualizar
                    </v-btn>
                </v-col>
            </v-row>
        </v-container>
    </v-form>
</template>

<script>
    // @ is an alias to /src
    import productService from "@/services/productService";
    import marcaService from "@/services/marcaService";
    import categoriaService from "@/services/categoriaService";
    import { Editor, EditorContent } from '@tiptap/vue-2'
    import StarterKit from '@tiptap/starter-kit'

    export default {
        props: ["product"],
        name: 'ProductForm',
        components: {
            EditorContent,
        },
        data: () => ({
            valid: false,
            imageData: null,
            rules: {
                code: [
                    v => !!v || 'Código es requerido',
                ],
                name: [
                    v => !!v || 'Nombre es requerido',
                ],
                stock: [
                    v => !!v || 'Stock es requerido',
                ],
                price: [
                    v => !!v || 'Precio es requerido',
                ],
            },
            marcas: [],
            categorias: [],
            editor: null,
        }),
        mounted() {
            marcaService.all().then(response => {
                this.marcas = response.data.data;
            }).catch(error => {
                console.error('error', error);
            });

            categoriaService.all().then(response => {
                this.categorias = response.data.data;
            }).catch(error => {
                console.error('error', error);
            });

            this.editor = new Editor({
                content: '',
                extensions: [
                    StarterKit,
                ],
            });
            if (this.product.id) {
                this.editor.commands.setContent(this.product.web_description);
            }
        },
        beforeDestroy() {
            this.editor.destroy()
        },
        methods: {
            submit() {
                this.product.web_description = this.editor.getHTML();
                productService.storeProduct(this.product).then(response => {
                    this.$router.push({path: '/products'})
                }, error => {
                    console.log('error', error);
                });
            },
            submitUpdate() {
                this.product.web_description = this.editor.getHTML();
                productService.updateProduct(this.product).then(response => {
                    this.$router.push({path: '/products'})
                }, error => {
                    console.log('error', error);
                });
            },
            addVariacion() {
                this.product.details.push({
                    code: '',
                    color: '',
                    capacity: '',
                    image: '',
                    image_url: 'https://via.placeholder.com/100x100',
                    codigo_saco: null,
                    comision: null,
                    stock: null,
                    price: null
                })
            },
            handleImage(index, e, attr, attrUrl) {
                this.createBase64Image(index, e, attr, attrUrl);
            },

            createBase64Image(index, fileObject, attr, attrUrl) {
                const reader = new FileReader;
                reader.onload = e => {
                    productService.uploadPhoto(e.target.result).then(response => {
                        this.product.details[index][attr] = response.data.fileName;
                        this.product.details[index][attrUrl] = response.data.url;
                        // this.product.details[index].image = response.data.fileName;
                        // this.product.details[index].image_url = response.data.url;
                    }, error => {
                        console.log('error', error)
                    })
                }
                reader.readAsDataURL(fileObject);
            }
        }
    }
</script>

<style >
    .img_detail {
        width: 100px;
    }
    /* Basic editor styles */
    .ProseMirror {
       border: 2px solid #000;
        margin-bottom: 2rem;
    }
</style>
