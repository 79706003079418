<template>
  <div>
    <Breadcrumbs :items="breadcrumbs"/>
    <v-card>
      <ListaForm :lista="lista" v-if="lista.id"/>
    </v-card>
  </div>
</template>

<script>
// @ is an alias to /src
import Breadcrumbs from "@/components/layout/Breadcrumbs";
import ListaForm from "@/components/lista/ListaForm";
import listaService from "@/services/listaService";

export default {
  name: 'ListaEdit',
  components: {
    Breadcrumbs,
    ListaForm
  },
  data: () => ({
    breadcrumbs: [
      {text: 'Inicio', disabled: false, href: '/',},
      {text: 'Listas', disabled: false, href: '/listas',},
      {text: 'Crear Lista', disabled: true, href: '/listas/create',}
    ],
    lista: {
      id: null,
      name: '',
      description: '',
      id_flexline: '',
      name_flexline: '',
      fecha_vigencia: '',
      bodega_id: null,
        channel_id: null,
        subchannel_id: null,
      rangos: 0,
      lista_precio_range:[]
    }
  }),
  mounted () {
    this.getDataLista(this.$route.params.id);
  },
  methods: {
    getDataLista(lista_id) {
      listaService.getLista(lista_id).then(response => {        
        this.lista = response.data.lista;
      })
    }
  }
}
</script>
