<template>
    <v-form v-model="valid" v-on:submit="submit" class="elevation-2 py-4">
        <v-container>
            <v-row>
                <v-col cols="12" md="4">
                    <h4 class="mb-3">Tipo de documento</h4>
                    <v-combobox v-model="business.client_type" :items="client_types" @change="setDocumentLength"
                        label="Tipo de documento" outlined solo></v-combobox>
                </v-col>
                <v-col cols="12" md="4">
                    <h4 class="mb-3">Nº de documento</h4>
                    <v-text-field v-model="business.clientid" label="Nº de documento" v-on:minlength="documentLength"
                        v-on:maxlength="documentLength" v-on:keypress="isLetterOrNumber($event)" outlined solo>
                    </v-text-field>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12" md="12">
                    <h4 class="mb-3">Nombre/Razón social</h4>
                    <v-text-field v-model="business.name" :rules="rules.name" label="Nombre/Razón social" required
                        outlined solo></v-text-field>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12" md="4">
                    <h4 class="mb-3">Departamento</h4>
                    <v-combobox v-model="business.department_id" :items="departments" item-text="name"
                        label="Departamento" @change="getProvinces" outlined solo>
                    </v-combobox>
                </v-col>
                <v-col cols="12" md="4">
                    <h4 class="mb-3">Provincia</h4>
                    <v-combobox v-model="business.province_id" :items="provinces" item-text="name" label="Provincia"
                        @change="getDistricts" outlined solo>
                        <template v-slot:no-data>
                            <v-list-item>
                                <v-list-item-content>
                                    <v-list-item-title>
                                        Por favor seleccione un departamento
                                    </v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                        </template>
                    </v-combobox>
                </v-col>
                <v-col cols="12" md="4">
                    <h4 class="mb-3">Distrito</h4>
                    <v-combobox v-model="business.district" :items="districts" item-text="name" label="Distrito"
                        outlined solo>
                        <template v-slot:no-data>
                            <v-list-item>
                                <v-list-item-content>
                                    <v-list-item-title>Por favor seleccione una provincia
                                    </v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                        </template>
                    </v-combobox>
                </v-col>
            </v-row>

            <v-row>
                <v-col cols="12" md="4">
                    <h4 class="mb-3">Dirección</h4>
                    <v-text-field v-model="business.address" :rules="rules.address" label="Dirección" required outlined
                        solo></v-text-field>
                </v-col>
                <v-col cols="12" md="4">
                    <h4 class="mb-3">Zona</h4>
                    <v-text-field
                        :value="business.district ? business.district.zona + ' -  S/' + business.district.price : ''"
                        label="Zona " readonly outlined solo></v-text-field>
                </v-col>
                <v-col cols="12" md="4">
                    <h4 class="mb-3">Lista precio</h4>
                    <v-combobox v-model="business.lista_precio" :items="listas" item-text="name" item-value="name"
                        label="Lista precio" outlined solo></v-combobox>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12" md="4">
                    <h4 class="mb-3">Correo</h4>
                    <v-text-field v-model="business.email" label="Correo"></v-text-field>
                </v-col>
                <v-col cols="12" md="4">
                    <h4 class="mb-3">Contacto</h4>
                    <v-text-field v-model="business.contacto" label="Contacto"></v-text-field>
                </v-col>
                <v-col cols="12" md="4">
                    <h4 class="mb-3">Número celular</h4>
                    <v-text-field v-model="business.phone" label="Número celular"></v-text-field>
                </v-col>
            </v-row>
            <v-row>
                <v-col>
                    <v-alert :value="alert" color="red" dark border="top" icon="mdi-office-building"
                        transition="scale-transition">
                        El número de RUC ya fue registrado
                    </v-alert>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12" md="12" class="d-flex">
                    <v-btn :disabled="!valid" class="mx-auto" @click="submit">
                        Agregar empresa
                    </v-btn>
                </v-col>
            </v-row>
        </v-container>
    </v-form>
</template>
<style>
.img_detail {
    width: 100px;
}
</style>
<script>
// @ is an alias to /src
import businessService from "@/services/businessService";
import ubigeoService from "@/services/ubigeoService";
import listaService from "@/services/listaService";

export default {
    name: "BusinessForm",
    components: {},
    data: () => ({
        valid: false,
        alert: false,
        departments: [],
        provinces: [],
        districts: [],
        listas: [],
        documentLength: 11,
        client_types: ["RUC", "DNI"],
        business: {
            name: "",
            client_type: "RUC",
            clientid: "",
            department_id: "",
            province_id: "",
            district_id: "",
            district: null,
            address: "",
            postal_code: "",
            lista_precio: null,
        },
        rules: {
            code: [(v) => !!v || "Código es requerido"],
            name: [(v) => !!v || "Nombre es requerido"],
            address: [(v) => !!v || "Dirección es requerido"],
        },
        /*numberRule: v  => {
            if (!v.trim()) return true;
            if (!isNaN(parseFloat(v))) return true;
            return false;
        },*/
    }),
    mounted() {
        this.getDepartments();
        this.getListas();
    },
    methods: {
        getListas() {
            listaService.getListas().then((response) => {
                if (response.status === 200) {
                    this.listas = response.data;
                }
            });
        },
        submit() {
            businessService.store(this.business).then(
                (response) => {
                    this.$router.push({ path: "/businesses" });
                },
                (error, data) => {
                    console.log("error", error);
                    console.log("error data", data);
                    this.alert = true;
                    setTimeout(() => {
                        this.alert = false;
                    }, 3000);
                }
            );
        },
        getDepartments() {
            ubigeoService.getDepartments().then((response) => {
                this.departments = response.data;
            });
        },
        getProvinces() {
            ubigeoService.getProvinces(this.business.department_id.id).then((response) => {
                this.provinces = response.data;
            });
        },
        getDistricts(key) {
            ubigeoService.getDistricts(this.business.province_id.id).then((response) => {
                this.districts = response.data;
            });
        },
        isLetterOrNumber(e) {
            let char = String.fromCharCode(e.keyCode);
            if (/^[0-9]+$/.test(char)) {
                if (this.business.clientid.length >= this.documentLength) {
                    e.preventDefault();
                } else {
                    return true;
                }
            } else {
                e.preventDefault();
            }
        },
        setDocumentLength() {
            console.log(this.client.client_type);
            if (this.client.client_type === "RUC") {
                this.documentLength = 11;
            } else {
                this.documentLength = 8;
            }
        },
    },
};
</script>
