<template>
    <div>
      <v-row>
            <v-col cols="12" md="4">
                <v-text-field
                        v-model="pedido.despacho_factura"
                        label="Factura"
                ></v-text-field>
            </v-col>
        </v-row>
    </div>
</template>

<script>
    // @ is an alias to /src


    import pedidoService from "@/services/pedidoService";

    export default {
        props: ["pedido"],
        name: 'PedidoFactura',
        components: {},
        watch: {
            pedido: {
                handler: function (newValue) {
                    // console.log(newValue.status);
                },
                deep: true
            }
        },
        data: () => ({
            // dialogCerrar: false,
            // evidencia_url: ''
        }),
        methods: {
        }
    }
</script>
