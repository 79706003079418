import axios from "axios";

const ENDPOINT_PATH = process.env.VUE_APP_BASE_URL;

export default {
    listasDatatable(data) {
        return axios.post(`${ENDPOINT_PATH}/partner-list-price/datatable`, data);
    },
    storeLista(data) {
        return axios.post(`${ENDPOINT_PATH}/partner-list-price`, data);
    },
    getPartners() {
        return axios.get(`${ENDPOINT_PATH}/partners`);
    },
    getLista(id) {
        return axios.get(`${ENDPOINT_PATH}/partner-list-price/${id}`);
    },
    getListas() {
        return axios.get(`${ENDPOINT_PATH}/list-price/list`);
    },
    updateLista(data) {
        return axios.put(`${ENDPOINT_PATH}/partner-list-price/${data.id}`, data);
    },
    deleteLista(lista_id) {
        return axios.post(`${ENDPOINT_PATH}/partner-list-price/${lista_id}/delete`, null);
    },
}