<template>
  <v-row justify="center">
    <v-col>
      <v-dialog
          v-model="dialog"
          persistent
          max-width="600px"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-card
              class="mx-auto"
              outlined
          >
            <v-list-item three-line>
              <v-list-item-content>
                <div class="overline mb-4">
                  Dirección de envío
                </div>
                <v-list-item-subtitle>Destinatario: {{pedido_address.consignatario}}</v-list-item-subtitle>
                <v-list-item-subtitle>Celular: {{pedido_address.contact_phone}}</v-list-item-subtitle>
                <v-list-item-subtitle>Región: {{pedido_address.department?pedido_address.department.name:''}}-{{pedido_address.province?pedido_address.province.name:''}}-{{pedido_address.district?pedido_address.district.name:''}}</v-list-item-subtitle>
                <v-list-item-subtitle>Dirección: {{pedido_address.address}}</v-list-item-subtitle>
                <v-list-item-subtitle>DNI: {{pedido_address.document_number}}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>

            <v-card-actions>
              <v-btn
                  color="primary"
                  dark
                  v-bind="attrs"
                  v-on="on"
              >
                Editar
              </v-btn>
            </v-card-actions>
          </v-card>
        </template>
        <v-card>
          <v-card-title>
            <span class="headline">Dirección de envío</span>
          </v-card-title>
          <v-card-text>
            <v-form v-model="valid">
              <v-container>
                <v-row>
                  <v-col cols="12">
                    <v-text-field
                        v-model="pedido_address.address"
                        label="Dirección detallada *"
                    ></v-text-field>
                  </v-col>
                  <v-col
                      cols="12"
                      sm="6"
                      md="4"
                  >
                    <v-combobox
                        v-model="pedido_address.department"
                        :items="departments"
                        item-text="name"
                        label="Departamento"
                        required
                        :rules="rules.department"
                        @change="getProvinces"
                    >
                    </v-combobox>
                  </v-col>
                  <v-col
                      cols="12"
                      sm="6"
                      md="4"
                  >
                    <v-combobox
                        v-model="pedido_address.province"
                        :items="provinces"
                        item-text="name"
                        label="Provincia"
                        required
                        :rules="rules.province"
                        @change="getDistricts"
                    >
                      <template v-slot:no-data>
                        <v-list-item>
                          <v-list-item-content>
                            <v-list-item-title>
                              Por favor seleccione un departamento
                            </v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                      </template>
                    </v-combobox>
                  </v-col>
                  <v-col
                      cols="12"
                      sm="6"
                      md="4"
                  >
                    <v-combobox
                        v-model="pedido_address.district"
                        :items="districts"
                        item-text="name"
                        required
                        :rules="rules.district"
                        label="Distrito"
                    >
                      <template v-slot:no-data>
                        <v-list-item>
                          <v-list-item-content>
                            <v-list-item-title>
                              Por favor seleccione una provincia
                            </v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                      </template>
                    </v-combobox>
                  </v-col>
                  <v-col cols="12">
                    <v-text-field
                        v-model="pedido_address.consignatario"
                        label="Consignatario *"
                        required
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <v-text-field
                        v-model="pedido_address.contact_phone"
                        label="Celular *"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <v-text-field
                        v-model="pedido_address.phone"
                        label="Teléfono fijo"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <v-text-field
                        v-model="pedido_address.document_number"
                        label="DNI *"
                        :rules="rules.document_number"
                        required
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12">
                      <v-textarea
                        v-model="pedido_address.description"
                        label="Descripción"
                        counter
                        maxlength="255"
                        full-width
                        single-line
                      ></v-textarea>
                  </v-col>
                </v-row>
              </v-container>
            </v-form>
            <small>*requerido</small>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn

                color="blue darken-1"
                text
                @click="dialog = false"
            >
              Cerrar
            </v-btn>
            <v-btn
                :disabled="!valid"
                color="blue darken-1"
                text
                @click="dialog = false"
            >
              Guardar
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-col>
  </v-row>
</template>

<script>
// @ is an alias to /src


import ubigeoService from "@/services/ubigeoService";

export default {
  props: ["pedido_address","departments","provinces","districts"],
  name: 'PedidoAddress',
  components: {

  },
  watch: {
    // pedido_address: {
    //   handler: function(newValue, oldValue) {
    //     console.log(newValue,oldValue)
    //     console.log("department_id" + newValue.department_id + " modified")
    //     console.log("New province_id: " + newValue.province_id)
    //   },
    //   deep: true
    // }
  },
  data: () => ({
    valid: false,
    dialog: false,
    rules: {
      document_number: [
        v => !!v || 'DNI es requerido',
      ],
      province: [
        v => !!v || 'Provincia es requerido',
      ],
      department: [
        v => !!v || 'Departamento es requerido',
      ],
      district: [
        v => !!v || 'Distrito es requerido',
      ],
    },
  }),
  mounted() {
    // if(this.pedido_address.department_id){
    //   if(this.provinces && this.provinces.length>0){

    //   }else{
    //     this.getProvinces();
    //   }
    // }
    // if(this.pedido_address.province_id){
    //   if(this.districts && this.districts.length>0){

    //   }else{
    //     this.getDistricts();
    //   }
    // }
  },
  methods: {
    getProvinces() {
      let department_id = this.pedido_address.department;
      if (typeof this.pedido_address.department === 'object')
        department_id = this.pedido_address.department.id
      ubigeoService.getProvinces(department_id).then(response => {
        //this.$set(this.provinces,null, response.data);
        this.provinces = response.data;
        this.pedido_address.province=''
        this.pedido_address.district=''
      });
    },
    getDistricts() {
      let province_id = this.pedido_address.province;
      if (typeof this.pedido_address.province === 'object')
        province_id = this.pedido_address.province.id
      ubigeoService.getDistricts(province_id).then(response => {
        this.districts = response.data
        this.pedido_address.district='';

      });
    }
    // setDistrict(){
    //   console.log(this.pedido_address.district)
    // }
  }
}
</script>
