import axios from "axios";

const ENDPOINT_PATH = process.env.VUE_APP_BASE_URL;

export default {
    trackingBBDatatable(data) {
        return axios.post(`${ENDPOINT_PATH}/partner/trackingb2b/datatable`, data);
    },
    trackingBCDatatable(data) {
        return axios.post(`${ENDPOINT_PATH}/partner/trackingb2c/datatable`, data);
    },
}