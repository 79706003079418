<template>
  <div>
    <Breadcrumbs :items="breadcrumbs" />
    <v-card>
      <v-form v-model="valid" v-on:submit="submit">
        <v-container>
          <v-row>
            <v-col cols="12" md="4">
              <v-text-field
                v-model="client.name"
                :rules="rules.name"
                label="Nombre"
                required
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="4">
              <v-combobox
                v-model="client.client_type"
                :items="client_types"
                @change="setDocumentLength"
                label="Tipo de documento"
              ></v-combobox>
            </v-col>
            <v-col cols="12" md="4">
              <v-text-field
                v-model="client.clientid"
                :rules="rules.clientid"
                label="RUC"
                v-on:minlength="documentLength"
                v-on:maxlength="documentLength"
                v-on:keypress="isLetterOrNumber($event)"
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12" md="4">
              <v-combobox
                v-model="client.department_id"
                :items="departments"
                item-text="name"
                label="Departamento"
                @change="getProvinces"
              >
              </v-combobox>
            </v-col>
            <v-col cols="12" md="4">
              <v-combobox
                v-model="client.province_id"
                :items="provinces"
                item-text="name"
                label="Provincia"
                @change="getDistricts"
              >
                <template v-slot:no-data>
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title>
                        Por favor seleccione un departamento
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </template>
              </v-combobox>
            </v-col>
            <v-col cols="12" md="4">
              <v-combobox
                v-model="client.district"
                :items="districts"
                item-text="name"
                label="Distrito"
                v-on:change="setPrice"
              >
                <template v-slot:no-data>
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title
                        >Por favor seleccione una provincia</v-list-item-title
                      >
                    </v-list-item-content>
                  </v-list-item>
                </template>
              </v-combobox>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12" md="4">
              <v-text-field
                v-model="client.address"
                :rules="rules.address"
                label="Dirección"
                required
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="4">
              <v-text-field
                v-model="price"
                label="Zona - Precio"
                readonly
              ></v-text-field>
            </v-col>
            <!-- <v-col cols="12" md="4">
              <v-combobox
                v-model="client.lista_precio"
                :items="listas"
                item-text="name"
                item-value="name"
                label="Lista de precio"
              ></v-combobox>
            </v-col> -->
          </v-row>
          <v-row>
            <v-col cols="12" md="4">
              <v-text-field
                v-model="client.email"
                :rules="rules.email"
                label="Correo"
                required
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="4">
              <v-text-field
                v-model="client.contact"
                :rules="rules.contact"
                label="Contacto"
                required
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="4">
              <v-text-field
                v-model="client.cell_phone"
                :rules="rules.cell_phone"
                label="Número celular"
                required
              ></v-text-field>
            </v-col>
          </v-row>
          <!--
          <v-row>
            <v-col>
              <v-card>
                <v-simple-table>
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <th>Shop Code</th>
                        <th>Shop Name</th>
                        <th>Acciones</th>
                        <th>
                          <v-btn small color="info" @click="addStore">
                            Agregar Tienda
                          </v-btn>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <template v-for="(item, key) in client.client_stores">
                        <tr>
                          <td>
                            <v-text-field
                              label="Shop Code"
                              v-model="client.client_stores[key].code"
                            ></v-text-field>
                          </td>
                          <td>
                            <v-text-field
                              label="Shop Name"
                              v-model="client.client_stores[key].name"
                            ></v-text-field>
                          </td>
                          <td>
                            <v-icon @click="deleteStore(key)">
                              mdi-delete
                            </v-icon>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <v-combobox
                              v-model="client.client_stores[key].department_id"
                              :items="departments"
                              item-text="name"
                              label="Departamento"
                              @change="getProvinces(key)"
                            >
                            </v-combobox>
                          </td>
                          <td>
                            <v-combobox
                              v-model="client.client_stores[key].province_id"
                              :items="stores_ubigeo[key].provinces"
                              item-text="name"
                              label="Provincia"
                              @change="getDistricts(key)"
                            >
                              <template v-slot:no-data>
                                <v-list-item>
                                  <v-list-item-content>
                                    <v-list-item-title>
                                      Por favor seleccione un departamento
                                    </v-list-item-title>
                                  </v-list-item-content>
                                </v-list-item>
                              </template>
                            </v-combobox>
                          </td>
                          <td>
                            <v-combobox
                              v-model="client.client_stores[key].district"
                              :items="stores_ubigeo[key].districts"
                              item-text="name"
                              label="Distrito"
                            >
                              <template v-slot:no-data>
                                <v-list-item>
                                  <v-list-item-content>
                                    <v-list-item-title
                                      >Por favor seleccione una
                                      provincia</v-list-item-title
                                    >
                                  </v-list-item-content>
                                </v-list-item>
                              </template>
                            </v-combobox>
                          </td>
                        </tr>
                        <hr />
                      </template>
                    </tbody>
                  </template>
                </v-simple-table>
              </v-card>
            </v-col>
          </v-row>
            -->
          <v-row>
            <v-col cols="12" md="4">
              <v-btn
                :disabled="!valid"
                color="success"
                class="mr-4"
                @click="submit"
              >
                Actualizar
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-form>
    </v-card>
    <v-overlay :value="overlay">
      <v-progress-circular
        indeterminate
        color="primary"
        size="64"
      ></v-progress-circular>
    </v-overlay>
  </div>
</template>

<script>
import Breadcrumbs from "@/components/layout/Breadcrumbs";
// import ClientForm from "@/components/client/ClientForm";
import PartnerClientForm from "@/components/partner-1/partner-client/PartnerClientForm";
import partnerClientService from "@/services/partner-1/partnerClientService";
import ubigeoService from "@/services/ubigeoService";
import partnerListPriceService from "@/services/partnerListPriceService";

export default {
  name: "edit.vue",
  components: {
    Breadcrumbs,
    PartnerClientForm,
  },
  data: () => ({
    overlay: true,
    breadcrumbs: [
      { text: "Inicio", disabled: false, href: "/" },
      { text: "Clientes", disabled: false, href: "/partner-client" },
    ],
    loader: null,
    price: null,
    valid: false,
    departments: [],
    provinces: [],
    districts: [],
    listas: [],
    documentLength: 8,
    client_types: ["RUC", "DNI"],
    stores_ubigeo: [
      { departments: [], provinces: [], districts: [] },
      { departments: [], provinces: [], districts: [] },
      { departments: [], provinces: [], districts: [] },
      { departments: [], provinces: [], districts: [] },
      { departments: [], provinces: [], districts: [] },
      { departments: [], provinces: [], districts: [] },
      { departments: [], provinces: [], districts: [] },
      { departments: [], provinces: [], districts: [] },
      { departments: [], provinces: [], districts: [] },
      { departments: [], provinces: [], districts: [] },
      { departments: [], provinces: [], districts: [] },
      { departments: [], provinces: [], districts: [] },
      { departments: [], provinces: [], districts: [] },
      { departments: [], provinces: [], districts: [] },
      { departments: [], provinces: [], districts: [] },
      { departments: [], provinces: [], districts: [] },
      { departments: [], provinces: [], districts: [] },
      { departments: [], provinces: [], districts: [] },
      { departments: [], provinces: [], districts: [] },
      { departments: [], provinces: [], districts: [] },
    ],
    client: {
      name: "",
      client_type: "RUC",
      clientid: "",
      department_id: "",
      province_id: "",
      district_id: "",
      district: null,
      address: "",
      email: "",
      contact: "",
      cell_phone: "",
      client_stores: [],
      lista_precio: null,
    },
    rules: {
      clientid: [(v) => !!v || "Este campo es requerido"],
      code: [(v) => !!v || "Código es requerido"],
      name: [(v) => !!v || "Nombre es requerido"],
      address: [(v) => !!v || "Dirección es requerido"],
      email: [(v) => !!v || "Correo es requerido"],
      contact: [(v) => !!v || "Contacto es requerido"],
      cell_phone: [(v) => !!v || "Número celular es requerido"],
    },
  }),
  mounted() {
    this.breadcrumbs.push({
      text: "Editar Cliente",
      disabled: true,
      href: "/partner-client/edit/" + this.$route.params.id,
    });
    this.getListas();

    partnerClientService
      .getClient(this.$route.params.id)
      .then((response) => {
        this.departments = response.data.data.departments;
        this.provinces = response.data.data.provinces;
        this.districts = response.data.data.districts;
        this.client = response.data.data.client;

        this.client.department_id = this.departments.find(
          (items) => items.id === this.client.department_id
        );
        this.client.province_id = this.provinces.find(
          (items) => items.id === this.client.province_id
        );
        this.client.district = this.districts.find(
          (items) => items.id === this.client.district_id
        );
        this.setPrice();
        this.setDocumentLength();
        let index = 0;
        /*
        response.data.data.client.client_stores.forEach((store) => {
          if (this.stores_ubigeo.length < index + 1) {
            this.stores_ubigeo.push({
              departments: [],
              provinces: [],
              districts: [],
            });
          }
          this.stores_ubigeo[index].departments =
            response.data.data.departments;
          this.stores_ubigeo[index].provinces =
            response.data.data.stores_ubigeo[index].provinces;
          this.stores_ubigeo[index].districts =
            response.data.data.stores_ubigeo[index].districts;

          this.client.client_stores[index].department_id =
            this.departments.find(
              (items) =>
                items.id === this.client.client_stores[index].department_id
            );
          this.client.client_stores[index].province_id = this.stores_ubigeo[
            index
          ].provinces.find(
            (items) => items.id === this.client.client_stores[index].province_id
          );
          this.client.client_stores[index].district = this.stores_ubigeo[
            index
          ].districts.find(
            (items) => items.id === this.client.client_stores[index].district_id
          );
          index = index + 1;
        });*/
        this.overlay = false;
        // this.client.stores = this.client.client_stores;
      })
      .catch((error) => {
        console.error("error", error);
        this.$router.push({ path: "/partner-client" });
      });
    // console.log(this.$route.params.id);
  },
  methods: {
    getListas() {
      partnerListPriceService.getListas().then((response) => {
        if (response.status === 200) {
          this.listas = response.data;
        }
      });
    },
    submit() {
      partnerClientService
        .updateClient(this.client)
        .then((response) => {
          this.$router.push({ path: "/partner-client" });
        })
        .catch((error) => {
          console.error("error", error);
        });
    },
    getDepartments() {
      ubigeoService.getDepartments().then((response) => {
        this.departments = response.data;
      });
    },
    getProvinces(key) {
      let department_id = isNaN(key)
        ? this.client.department_id
        : this.client.client_stores[key].department_id;
      if (!isNaN(key)) {
        if (typeof this.client.client_stores[key].department_id === "object") {
          department_id = this.client.client_stores[key].department_id.id;
        }
      } else if (typeof this.client.department_id === "object") {
        department_id = this.client.department_id.id;
      }
      console.log(department_id);
      ubigeoService.getProvinces(department_id).then((response) => {
        if (!isNaN(key)) {
          this.stores_ubigeo[key].provinces = response.data;
        } else {
          this.provinces = response.data;
        }
      });
    },
    getDistricts(key) {
      let province_id = !isNaN(key)
        ? this.client.client_stores[key].province_id
        : this.client.province_id;
      if (!isNaN(key)) {
        if (typeof this.client.client_stores[key].province_id === "object") {
          province_id = this.client.client_stores[key].province_id.id;
        }
      } else if (typeof this.client.province_id === "object") {
        province_id = this.client.province_id.id;
      }
      ubigeoService.getDistricts(province_id).then((response) => {
        if (!isNaN(key)) {
          this.stores_ubigeo[key].districts = response.data;
        } else {
          this.districts = response.data;
        }
      });
    },
    setPrice() {
      this.price = this.client.district
        ? this.client.district.zona + " -  S/" + this.client.district.price
        : "";
    },
    isLetterOrNumber(e) {
      let char = String.fromCharCode(e.keyCode);
      if (/^[0-9]+$/.test(char)) {
        if (this.client.clientid.length >= this.documentLength) {
          e.preventDefault();
        } else {
          return true;
        }
      } else {
        e.preventDefault();
      }
    },
    setDocumentLength() {
      if (this.client.client_type === "RUC") {
        this.documentLength = 11;
      } else {
        this.documentLength = 8;
      }
    },
    addStore() {
      this.$set(this.client.client_stores, this.client.client_stores.length, {
        code: "",
        name: "",
        department_id: null,
        province_id: null,
        district_id: null,
      });
      if (this.stores_ubigeo.length < this.client.client_stores.length) {
        this.stores_ubigeo.push({
          departments: [],
          provinces: [],
          districts: [],
        });
      }
    },
    deleteStore(key) {
      this.$delete(this.client.client_stores, key);
    },
  },
};
</script>

<style scoped>
</style>
