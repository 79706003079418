<template>
    <div>
        <v-row>
            <v-col cols="12" md="3">
                <v-combobox
                        v-model="pedido.courier"
                        :items="couriers"
                        label="Courier"
                ></v-combobox>
            </v-col>
            <v-col cols="12" md="3">
                <v-text-field
                        v-model="pedido.tracking_number"
                        label="Tracking number"
                ></v-text-field>
            </v-col>
            <v-col cols="12" md="3">
                <v-menu
                        v-model="fecha_entrega"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        min-width="auto"
                >
                    <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                                v-model="pedido.fecha_entrega"
                                label="Fecha de entrega"
                                prepend-icon="mdi-calendar"
                                readonly
                                v-bind="attrs"
                                v-on="on"
                        ></v-text-field>
                    </template>
                    <v-date-picker
                            v-model="pedido.fecha_entrega"
                            @input="fecha_entrega = false"
                    ></v-date-picker>
                </v-menu>
            </v-col>
            <v-col cols="12" md="3">
                <v-text-field
                        v-model="pedido.fecha_est_entrega"
                        readonly
                        label="Fecha Aproximada"
                ></v-text-field>
            </v-col>
        </v-row>
        <v-row>

            <v-col cols="12" md="4">
                <v-text-field
                        v-model="pedido.despacho_guia"
                        label="Guía de remision"
                ></v-text-field>
            </v-col>
            <v-col cols="12" md="4">
                <!--<v-text-field
                        v-model="pedido.despacho_guia"
                        label="Guía de remision"
                ></v-text-field>-->
                <v-file-input
                        id="file" ref="file"
                        accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                        @change="handleFile($event)"
                        label="Datos de Equipos"
                ></v-file-input>
            </v-col>
            <v-col cols="12" md="4">
                <v-datetime-picker label="Fecha de Despacho" v-model="dispatched_date"> </v-datetime-picker>
            </v-col>
        </v-row>
        <v-row>
        <v-col cols="12" md="2">
          <v-text-field
              v-model="pedido.envio_alto"
              label="Alto (paquete)"
              type="number"
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="2">
          <v-text-field
              v-model="pedido.envio_ancho"
              label="Ancho (paquete)"
              type="number"
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="2">
          <v-text-field
              v-model="pedido.envio_largo"
              label="Largo (paquete)"
              type="number"
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="2">
          <v-text-field
              v-model="pedido.envio_peso"
              label="Peso (paquete)"
              type="number"
          ></v-text-field>
        </v-col>
         <v-col cols="12" md="6">
          <v-text-field
              v-model="pedido.envio_observacion"
              label="Observación"
              type="number"
          ></v-text-field>
        </v-col>

      </v-row>

        <pedido-tracking-olva v-if="pedido.envio_emision && pedido.envio_codigo" :pedido="pedido"></pedido-tracking-olva>
    </div>
</template>

<script>
    // @ is an alias to /src
    import pedidoService from "@/services/pedidoService";
    import moment from 'moment';
    import PedidoTrackingOlva from "@/components/pedido/PedidoTrackingOlva";


    export default {
        props: ["pedido"],
        name: 'PedidoTracking',
        components: {
          PedidoTrackingOlva
        },
        data: () => ({
            fecha_entrega: false,
            dispatched_date: undefined,
            couriers: ['OLVA', 'SAVAR', 'URBANO','SACO DIRECTO','SACO + AGENCIA'],
        }),
        watch: {
            pedido: {
                handler: function (newValue) {
                    console.log(newValue.status);
                },
                deep: true
            },
            dispatched_date:{
                 handler: function (newValue) {
                     if(newValue){
                         let test= moment(newValue).format("YYYY-MM-DD HH:mm");
                         this.pedido.dispatched_date=test;
                     }
                }
            }
        },
        methods: {
            handleFile($event) {
                 let formData = new FormData();
                formData.append('file', $event);
                pedidoService.uploadFile(formData).then(response => {
                    this.pedido.datos_equipos = response.data.fileName;
                    this.pedido.datos_equipos_url = response.data.url;
                }).catch(error => {
                    console.error('error',error)
                });
            }
        },
        mounted() {
            if(this.pedido.dispatched_date){
                var date = moment(this.pedido.dispatched_date).format("YYYY-MM-DD HH:mm");
                this.dispatched_date=date;
            }
        },
    }
</script>
