<template>
  <v-form v-model="valid" v-on:submit="submit" class="elevation-2 py-4">
    <v-container>
      <v-row>
        <v-col cols="12" md="4">
          <h4 class="mb-3">Tipo de documento</h4>
          <v-combobox
            v-model="client.client_type"
            :items="client_types"
            @change="setDocumentLength"
            label="Tipo de documento"
            outlined
            solo
          ></v-combobox>
        </v-col>
        <v-col cols="12" md="4">
          <h4 class="mb-3">Nº de documento</h4>
          <v-text-field
            v-model="client.clientid"
            label="Nº de documento"
            v-on:minlength="documentLength"
            v-on:maxlength="documentLength"
            v-on:keypress="isLetterOrNumber($event)"
            outlined
            solo
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="12">
          <h4 class="mb-3">Nombre/Razón social</h4>
          <v-text-field
            v-model="client.name"
            :rules="rules.name"
            label="Nombre/Razón social"
            required
            outlined
            solo
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="4">
          <h4 class="mb-3">Departamento</h4>
          <v-combobox
            v-model="client.department_id"
            :items="departments"
            item-text="name"
            label="Departamento"
            @change="getProvinces"
            outlined
            solo
          >
          </v-combobox>
        </v-col>
        <v-col cols="12" md="4">
          <h4 class="mb-3">Provincia</h4>
          <v-combobox
            v-model="client.province_id"
            :items="provinces"
            item-text="name"
            label="Provincia"
            @change="getDistricts"
            outlined
            solo
          >
            <template v-slot:no-data>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title>
                    Por favor seleccione un departamento
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </template>
          </v-combobox>
        </v-col>
        <v-col cols="12" md="4">
          <h4 class="mb-3">Distrito</h4>
          <v-combobox
            v-model="client.district"
            :items="districts"
            item-text="name"
            label="Distrito"
            outlined
            solo
          >
            <template v-slot:no-data>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title
                    >Por favor seleccione una provincia</v-list-item-title
                  >
                </v-list-item-content>
              </v-list-item>
            </template>
          </v-combobox>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12" md="4">
          <h4 class="mb-3">Dirección</h4>
          <v-text-field
            v-model="client.address"
            :rules="rules.address"
            label="Dirección"
            required
            outlined
            solo
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="4">
          <h4 class="mb-3">Zona</h4>
          <v-text-field
            :value="
              client.district
                ? client.district.zona + ' -  S/' + client.district.price
                : ''
            "
            label="Zona "
            readonly
            outlined
            solo
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="4">
          <h4 class="mb-3">Lista precio</h4>
          <v-combobox
            v-model="client.lista_precio"
            :items="listas"
            item-text="name"
            item-value="name"
            label="Lista precio"
            outlined
            solo
          ></v-combobox>
        </v-col>
      </v-row>

            <v-row>
                <v-col cols="12" md="4">
                    <v-btn  class="mr-4 border-0" @click="addVariacion"
                    style="background:transparent;
                    box-shadow:none;
                    color:#1371CB;">
                    <v-icon left>
                        mdi-plus
                    </v-icon>
                       Asignar tienda
                    </v-btn>
                </v-col>
            </v-row>
<!-- 
      <v-row>
        <v-col>
          <v-card>
            <v-simple-table>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th>Shop Code</th>
                    <th>Shop Name</th>
                    <th>Acciones</th>
                    <th>
                      <v-btn small color="info" @click="addStore">
                        Agregar Tienda
                      </v-btn>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <template v-for="(item, key) in client.stores">
                    <tr>
                      <td>
                        <v-text-field
                          label="Shop Code"
                          v-model="client.stores[key].code"
                        ></v-text-field>
                      </td>
                      <td>
                        <v-text-field
                          label="Shop Name"
                          v-model="client.stores[key].name"
                        ></v-text-field>
                      </td>
                      <td>
                        <v-icon @click="deleteStore(key)"> mdi-delete </v-icon>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <v-combobox
                          v-model="client.stores[key].department_id"
                          :items="departments"
                          item-text="name"
                          label="Departamento"
                          @change="getProvinces(key)"
                        >
                        </v-combobox>
                      </td>
                      <td>
                        <v-combobox
                          v-model="client.stores[key].province_id"
                          :items="stores_ubigeo[key].provinces"
                          item-text="name"
                          label="Provincia"
                          @change="getDistricts(key)"
                        >
                          <template v-slot:no-data>
                            <v-list-item>
                              <v-list-item-content>
                                <v-list-item-title>
                                  Por favor seleccione un departamento
                                </v-list-item-title>
                              </v-list-item-content>
                            </v-list-item>
                          </template>
                        </v-combobox>
                      </td>
                      <td>
                        <v-combobox
                          v-model="client.stores[key].district"
                          :items="stores_ubigeo[key].districts"
                          item-text="name"
                          label="Distrito"
                        >
                          <template v-slot:no-data>
                            <v-list-item>
                              <v-list-item-content>
                                <v-list-item-title
                                  >Por favor seleccione una
                                  provincia</v-list-item-title
                                >
                              </v-list-item-content>
                            </v-list-item>
                          </template>
                        </v-combobox>
                      </td>
                    </tr>
                    <hr />
                  </template>
                </tbody>
              </template>
            </v-simple-table>
          </v-card>
        </v-col>
      </v-row> -->
      <v-row>
        <v-col>
          <v-alert
            :value="alert"
            color="red"
            dark
            border="top"
            icon="mdi-office-building"
            transition="scale-transition"
          >
            El número de RUC ya fue registrado
          </v-alert>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="12" class="d-flex">
          <v-btn
            :disabled="!valid"
            class="mx-auto"
            @click="submit"
          >
            Agregar usuario
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
  </v-form>
</template>
<style>
.img_detail {
  width: 100px;
}
</style>
<script>
// @ is an alias to /src
import clientService from "@/services/clientService";
import ubigeoService from "@/services/ubigeoService";
import listaService from "@/services/listaService";

export default {
  name: "ProductForm",
  components: {},
  data: () => ({
    valid: false,
    alert: false,
    departments: [],
    provinces: [],
    districts: [],
    listas: [],
    stores_ubigeo: [
      {
        departments: [],
        provinces: [],
        districts: [],
      },
    ],
    documentLength: 11,
    client_types: ["RUC", "DNI"],
    client: {
      name: "",
      client_type: "RUC",
      clientid: "",
      department_id: "",
      province_id: "",
      district_id: "",
      district: null,
      address: "",
      postal_code: "",
      stores: [],
      lista_precio: null,
    },
    rules: {
      code: [(v) => !!v || "Código es requerido"],
      name: [(v) => !!v || "Nombre es requerido"],
      address: [(v) => !!v || "Dirección es requerido"],
    },
    /*numberRule: v  => {
        if (!v.trim()) return true;
        if (!isNaN(parseFloat(v))) return true;
        return false;
    },*/
  }),
  mounted() {
    this.getDepartments();
    this.getListas();
  },
  methods: {
    getListas() {
      listaService.getListas().then((response) => {
        if (response.status === 200) {
          this.listas = response.data;
        }
      });
    },
    submit() {
      clientService.storeClient(this.client).then(
        (response) => {
          this.$router.push({ path: "/clients" });
        },
        (error, data) => {
          console.log("error", error);
          console.log("error data", data);
          this.alert = true;
          setTimeout(() => {
            this.alert = false;
          }, 3000);
        }
      );
    },
    getDepartments(key) {
      ubigeoService.getDepartments().then((response) => {
        if (!isNaN(key)) {
          this.stores_ubigeo[key].departments = response.data;
        } else {
          this.departments = response.data;
        }
      });
    },
    getProvinces(key) {
      console.log("key", key);
      let department_id = isNaN(key)
        ? this.client.department_id
        : this.client.stores[key].department_id;
      if (!isNaN(key)) {
        if (typeof this.client.stores[key].department_id === "object") {
          department_id = this.client.stores[key].department_id.id;
        }
      } else if (typeof this.client.department_id === "object") {
        department_id = this.client.department_id.id;
      }
      console.log(department_id);
      ubigeoService.getProvinces(department_id).then((response) => {
        if (!isNaN(key)) {
          this.stores_ubigeo[key].provinces = response.data;
        } else {
          this.provinces = response.data;
        }
      });
    },
    getDistricts(key) {
      let province_id = !isNaN(key)
        ? this.client.stores[key].province_id
        : this.client.province_id;
      if (!isNaN(key)) {
        if (typeof this.client.stores[key].province_id === "object") {
          province_id = this.client.stores[key].province_id.id;
        }
      } else if (typeof this.client.province_id === "object") {
        province_id = this.client.province_id.id;
      }
      ubigeoService.getDistricts(province_id).then((response) => {
        if (!isNaN(key)) {
          this.stores_ubigeo[key].districts = response.data;
        } else {
          this.districts = response.data;
        }
      });
    },
    isLetterOrNumber(e) {
      let char = String.fromCharCode(e.keyCode);
      if (/^[0-9]+$/.test(char)) {
        if (this.client.clientid.length >= this.documentLength) {
          e.preventDefault();
        } else {
          return true;
        }
      } else {
        e.preventDefault();
      }
    },
    setDocumentLength() {
      console.log(this.client.client_type);
      if (this.client.client_type === "RUC") {
        this.documentLength = 11;
      } else {
        this.documentLength = 8;
      }
    },
    addStore() {
      this.client.stores.push({
        code: "",
        name: "",
      });
    },
    deleteStore(key) {
      this.client.stores.splice(key, 1);
      // this.editedIndex = this.client.stores.indexOf(item);
    },
  },
};
</script>
