<template>
  <v-form v-model="valid" v-on:submit="submit" class="elevation-2 py-4">
    <v-container>
      <v-row>
        <v-col cols="12" md="6">
          <h4 class="mb-3">Nombre</h4>
          <v-text-field
              v-model="bodega.name"
              :rules="rules.name"
              label="Nombre"
              required
              outlined
              solo
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="6">
          <h4 class="mb-3">Descripción</h4>
          <v-text-field
              v-model="bodega.description"
              label="Descripción"
              required
              outlined
              solo
          ></v-text-field>
        </v-col>
      </v-row>

    <v-row>
        <v-col
            cols="12"
            md="12"
            class="d-flex">
          <v-btn
              :disabled="!valid"
              class="mx-auto btn-blue"
              @click="submit"
              v-if="!bodega.id"
          >
            Agregar categoría
          </v-btn>
          <v-btn
              :disabled="!valid"
              color="success"
              class="mr-4"
              @click="submitUpdate"
              v-if="bodega.id"
          >
            Actualizar
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
  </v-form>
</template>
<style>
.img_detail {
  width: 100px;
}
</style>
<script>
// @ is an alias to /src
import bodegaService from "@/services/bodegaService";
import ubigeoService from "@/services/ubigeoService";

export default {
  props: ["bodega"],
  name: 'BodegaForm',
  components: {},
  data: () => ({
    valid: false,
    alert: false,
    departments: [],
    provinces: [],
    districts: [],
    stores_ubigeo:[
      {
        departments: [],
        provinces: [],
        districts: [],
      }
    ],
    rules: {
      name: [
        v => !!v || 'Nombre es requerido',
      ],
    },
    /*numberRule: v  => {
        if (!v.trim()) return true;
        if (!isNaN(parseFloat(v))) return true;
        return false;
    },*/
  }),
  mounted() {
    this.getDepartments()
  },
  methods: {
    submit() {
      bodegaService.storeBodega(this.bodega).then(response => {
        this.$router.push({path: '/bodegas'})
      }, (error, data) => {
        console.log('error', error);
        console.log('error data', data);
        this.alert = true;
        setTimeout(() => {
          this.alert = false
        }, 3000);
      });
    },
    submitUpdate() {
      bodegaService.updateBodega(this.bodega).then(response=>{
        this.$router.push({ path: '/bodegas' })
      },error=>{
        console.log('error', error);
      });
    },
    getDepartments(key) {
      ubigeoService.getDepartments().then(response => {
        if(!isNaN(key)){
          this.stores_ubigeo[key].departments = response.data
        }else{
          this.departments = response.data
        }
      });
    },
    getProvinces(key) {
      console.log('key',key)
      let department_id = isNaN(key)?this.bodega.department_id:this.bodega.stores[key].department_id;
      if(!isNaN(key)){
        if (typeof this.bodega.stores[key].department_id === 'object'){
          department_id = this.bodega.stores[key].department_id.id
        }
      }else if (typeof this.bodega.department_id === 'object'){
        department_id = this.bodega.department_id.id
      }
      console.log(department_id)
      ubigeoService.getProvinces(department_id).then(response => {
        if(!isNaN(key)){
          this.stores_ubigeo[key].provinces = response.data
        }else{
          this.provinces = response.data
        }
      });
    },
    getDistricts(key) {
      let province_id = !isNaN(key)?this.bodega.stores[key].province_id:this.bodega.province_id;
      if(!isNaN(key)){
        if (typeof this.bodega.stores[key].province_id === 'object'){
          province_id = this.bodega.stores[key].province_id.id
        }
      }else if (typeof this.bodega.province_id === 'object'){
        province_id = this.bodega.province_id.id
      }
      ubigeoService.getDistricts(province_id).then(response => {
        if(!isNaN(key)){
          this.stores_ubigeo[key].districts = response.data
        }else{
          this.districts = response.data
        }
      });
    },
    isLetterOrNumber(e) {
      let char = String.fromCharCode(e.keyCode);
      if (/^[0-9]+$/.test(char)) {
        if (this.bodega.bodegaid.length >= this.documentLength) {
          e.preventDefault();
        } else {
          return true;
        }
      } else {
        e.preventDefault();
      }
    },
    setDocumentLength() {
      console.log(this.bodega.bodega_type);
      if (this.bodega.bodega_type === 'RUC') {
        this.documentLength = 11;
      } else {
        this.documentLength = 8;
      }
    },
    addStore() {
      this.bodega.stores.push({
        code: '',
        name: '',
      });
    },
    deleteStore(key) {
      this.bodega.stores.splice(key, 1);
      // this.editedIndex = this.bodega.stores.indexOf(item);
    },
  }
}
</script>
