import axios from "axios";

const ENDPOINT_PATH = process.env.VUE_APP_BASE_URL;

export default {
    getDepartments() {
        return axios.get(`${ENDPOINT_PATH}/ubigeo/departments`);
    },
    getZonas() {
        return axios.get(`${ENDPOINT_PATH}/ubigeo/zonas`);
    },
    getProvinces(department_id) {
        return axios.get(`${ENDPOINT_PATH}/ubigeo/provinces/${department_id}`);
    },
    getDistricts(district_id) {
        return axios.get(`${ENDPOINT_PATH}/ubigeo/districts/${district_id}`);
    },
    getDepartmentsAgencia() {
        return axios.get(`${ENDPOINT_PATH}/ubigeo/agencia/departments`);
    },
    getProvincesAgencia(department_id) {
        return axios.get(`${ENDPOINT_PATH}/ubigeo/agencia/provinces/${department_id}`);
    },
    getDistrictsAgencia(district_id) {
        return axios.get(`${ENDPOINT_PATH}/ubigeo/agencia/districts/${district_id}`);
    },
    getUbigeoAgencias(district_id) {
        return axios.get(`${ENDPOINT_PATH}/ubigeo/agencias/${district_id}`);
    },
};
