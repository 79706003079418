<template>
    <div>
        <Breadcrumbs :items="breadcrumbs"/>
        <v-card>
            <CouponForm :coupon="coupon"/>
        </v-card>
    </div>
</template>

<script>
    // @ is an alias to /src
    import Breadcrumbs from "@/components/layout/Breadcrumbs";
    import CouponForm from "@/components/coupon/CouponForm";
    import couponService from "@/services/couponService";

    export default {
        name: 'ActualizarCupon',
        components: {
            Breadcrumbs,
            CouponForm
        },
        data: () => ({
            breadcrumbs: [
                {text: 'Inicio', disabled: false, href: '/',},
                {text: 'Cupones', disabled: false, href: '/coupons',},
                {text: 'Editar Cupón', disabled: true, href: '/coupons/edit',}
            ],
            coupon: {
                code: null,
                description: null,
                amount: null,
                expire_in: null,
                status: 1,
                max_number_exchanges: null,
            }
        }),
        mounted() {
            this.getData(this.$route.params.id);
        },
        methods: {
            getData(id) {
                couponService.show(id).then(response => {
                    this.coupon = response.data.data;
                })
            }
        }
    }
</script>
