<template>
  <div>
    <v-row>
      <v-col cols="12" md="4">
        <v-row>
          <v-col cols="12" md="6">
            <v-file-input
              accept="image/*"
              @change="uploadSustento(1, $event)"
              :disabled="userSession.role=='bitel'"
              label="Sustento 1"
            ></v-file-input>
          </v-col>
          <v-col cols="12" md="6">
            <img class="img_detail" :src="pedido.sustento_1_url" alt="" />
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12" md="4">
        <v-row>
          <v-col cols="12" md="6">
            <v-file-input
              accept="image/*"
              @change="uploadSustento(2, $event)"
              :disabled="userSession.role=='bitel'"
              label="Sustento 2"
            ></v-file-input>
          </v-col>
          <v-col cols="12" md="6">
            <img class="img_detail" :src="pedido.sustento_2_url" alt="" />
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12" md="4">
        <v-row>
          <v-col cols="12" md="6">
            <v-file-input
              accept="image/*"
              @change="uploadSustento(3, $event)"
              :disabled="userSession.role=='bitel'"
              label="Sustento 3"
            ></v-file-input>
          </v-col>
          <v-col cols="12" md="6">
            <img class="img_detail" :src="pedido.sustento_3_url" alt="" />
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="4">
        <v-datetime-picker label="Fecha de Entrega" 
        :disabled="userSession.role=='bitel'"
        v-model="delivered_date">
        </v-datetime-picker>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="4"> </v-col>
      <v-col cols="12" md="4" v-if="pedido.status < 5">
        <v-dialog v-model="dialogCerrar" max-width="500px">
          <v-card>
            <v-card-title class="headline"
              >Estás seguro de cerrar este pedido?</v-card-title
            >
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="closeDelete"
                >Cancelar</v-btn
              >
              <v-btn color="blue darken-1" text @click="cerrarPedidoConfirm"
                >Sí, estoy seguro</v-btn
              >
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <v-btn color="primary" dark @click="cerrarPedido">
          Cerrar Pedido
        </v-btn>
      </v-col>
      <v-col cols="12" md="4" v-if="pedido.status === 5">
        <v-alert type="success"> Pedido entregado </v-alert>
      </v-col>
    </v-row>
  </div>
</template>

<script>
// @ is an alias to /src

import partnerOrderService from "@/services/partner-2/partnerOrderService";
import moment from "moment";

export default {
  props: ["pedido"],
  computed: {
    userSession: function () {
      return this.$store.getters.userSession;
    },
  },
  name: "PedidoEntregado",
  components: {},
  watch: {
    pedido: {
      handler: function (newValue) {
        console.log(newValue.status);
      },
      deep: true,
    },
    delivered_date: {
      handler: function (newValue) {
        if (newValue) {
          this.pedido.delivered_date = moment(newValue).format(
            "YYYY-MM-DD HH:mm"
          );
        }
      },
    },
  },
  data: () => ({
    delivered_date: undefined,
    dialogCerrar: false,
    evidencia_url: "",
  }),
  methods: {
    cerrarPedido() {
      this.dialogCerrar = true;
    },

    cerrarPedidoConfirm() {
      this.pedido.status = 5;
      partnerOrderService.updatePedido(this.pedido).then((response) => {
        this.closeDelete();
      });
    },
    closeDelete() {
      this.dialogCerrar = false;
    },
    uploadSustento(index, e) {
      this.createBase64Image(index, e);
    },
    createBase64Image(index, fileObject) {
      const reader = new FileReader();
      reader.onload = (e) => {
        partnerOrderService.uploadPhoto(e.target.result).then(
          (response) => {
            this.pedido["sustento_" + index] = response.data.fileName;
            this.pedido["sustento_" + index + "_url"] = response.data.url;
          },
          (error) => {
            console.log("error", error);
          }
        );
      };
      reader.readAsDataURL(fileObject);
    },
  },
  mounted() {
    if (this.pedido.delivered_date) {
      this.delivered_date = moment(this.pedido.delivered_date).format(
        "YYYY-MM-DD HH:mm"
      );
    }
  },
};
</script>
