import axios from "axios";

const ENDPOINT_PATH = process.env.VUE_APP_BASE_URL;

export default {
    datatable(data) {
        return axios.post(`${ENDPOINT_PATH}/coupons/datatable`, data);
    },
    all() {
        return axios.get(`${ENDPOINT_PATH}/coupons`);
    },
    store(data) {
        return axios.post(`${ENDPOINT_PATH}/coupons`, data);
    },
    destroy(id) {
        return axios.delete(`${ENDPOINT_PATH}/coupons/${id}`);
    },
    update(data) {
        return axios.put(`${ENDPOINT_PATH}/coupons/${data.id}`, data);
    },
    show(id) {
        return axios.get(`${ENDPOINT_PATH}/coupons/${id}`);
    },
};
