<template>
    <div>
        <Breadcrumbs :items="breadcrumbs"/>
        <v-card>
            <v-data-table
                    :headers="headers"
                    :items="banners"
                    :options.sync="options"
                    :server-items-length="totalBanners"
                    :loading="loading"
                    :items-per-page="25"
                    class="elevation-1"
            >
                <template v-slot:top>
                    <v-toolbar flat>
                        <v-dialog v-model="dialogDelete" max-width="500px">
                            <v-card>
                                <v-card-title class="headline">Estás seguro de eliminar este banner?
                                </v-card-title>
                                <v-card-actions>
                                    <v-spacer></v-spacer>
                                    <v-btn color="blue darken-1" text @click="closeDelete">Cancelar</v-btn>
                                    <v-btn color="blue darken-1" text @click="deleteItemConfirm">Sí, estoy
                                        seguro
                                    </v-btn>
                                    <v-spacer></v-spacer>
                                </v-card-actions>
                            </v-card>
                        </v-dialog>
                        <v-toolbar-title>Banners</v-toolbar-title>
                        <v-divider class="mx-4" inset vertical></v-divider>
                        <!--<v-row class="mt-3">
                            <v-col>
                                <v-text-field
                                        v-model="search_categoria"
                                        label="Buscar categoría"
                                        class="mx-4"
                                ></v-text-field>
                            </v-col>
                        </v-row>-->
                        <v-spacer></v-spacer>
                        <v-btn
                                dark
                                class="btn-green"
                                to="/banners/create"
                        >
                        <v-icon left>
                            mdi-plus
                        </v-icon>
                            Agregar banner
                        </v-btn>
                    </v-toolbar>
                </template>
                <template v-slot:item.photo_url="{ item }">
                    <v-img :src="item.photo_url" class="img-banner" alt="Banner"></v-img>
                </template>
                <template v-slot:item.actions="{ item }">
                    <v-btn icon :to="'/banners/edit/' + item.id">
                        <v-icon small class="mr-2">mdi-pencil</v-icon>
                    </v-btn>
                    <v-icon small @click="deleteItem(item.id)">mdi-delete</v-icon>
                </template>
            </v-data-table>
        </v-card>
    </div>
</template>

<script>
    // @ is an alias to /src
    import Breadcrumbs from "@/components/layout/Breadcrumbs";
    import bannerService from "@/services/bannerService";

    export default {
        name: 'Banners',
        components: {
            Breadcrumbs,
        },
        data: () => ({
            breadcrumbs: [
                {text: 'Inicio', disabled: false, href: '/',},
                {text: 'Banners', disabled: true, href: '/banners',}
            ],
            search_banner: '',
            totalBanners: 0,
            banners: [],
            loading: true,
            dialogDelete: false,
            options: {query: {banner: ''}},
            editedIndex: null,
            headers: [
                {
                    text: 'ID',
                    align: 'start',
                    sortable: false,
                    value: 'id',
                },
                {
                    text: 'Posición',
                    sortable: false,
                    value: 'position',
                },
                {
                    text: 'Foto',
                    sortable: false,
                    value: 'photo_url',
                },
                {text: 'Acciones', value: 'actions', sortable: false},
            ],
        }),
        watch: {
            params: {
                handler() {
                    this.getDataFromApi()
                },
                deep: true,
            },
        },
        computed: {
            params(nv) {
                this.options.query.banner = this.search_banner;
            }
        },
        mounted() {
            this.getDataFromApi()
        },
        methods: {
            editItem(item) {
            },
            deleteItem(item) {
                this.dialogDelete = true;
                this.editedIndex = item;
            },
            deleteItemConfirm() {
                bannerService.destroy(this.editedIndex).then(result => {
                    this.getDataFromApi();
                    this.closeDelete()
                })
            },
            closeDelete() {
                this.dialogDelete = false;
            },
            getDataFromApi() {
                this.loading = true;
                bannerService.datatable(this.options).then(response => {
                    this.banners = response.data.items;
                    this.totalBanners = response.data.total;
                    this.loading = false;
                })
            },

        },
    }
</script>

<style>
    .img-banner {
        max-width: 50rem;
    }
</style>
