<template>
  <div>
    <Breadcrumbs :items="breadcrumbs"/>
    <v-card>
      <PedidoForm :pedido="pedido" :edit_codigo_bancario="edit_codigo_bancario"/>
    </v-card>
  </div>
</template>

<script>
// @ is an alias to /src
import Breadcrumbs from "@/components/layout/Breadcrumbs";
import PedidoForm from "@/components/pedido/PedidoForm";

export default {
  name: 'PedidosCreate',
  components: {
    Breadcrumbs,
    PedidoForm
  },
  data: () => ({
    breadcrumbs: [
      {text: 'Inicio', disabled: false, href: '/',},
      {text: 'Pedidos', disabled: false, href: '/pedidos',},
      {text: 'Crear Pedido', disabled: true, href: '/pedidos/create',}
    ],
    tab: null,
    items: [
      { tab: 'Detalles', content: 'DetallePedido' },
      { tab: 'Despachar', content: 'DetallePedido' },
      { tab: 'Pago', content: 'DetallePedido' },
      { tab: 'Registros', content: 'DetallePedido' },
    ],
    edit_codigo_bancario: true,
    pedido: {
      api_bitel:2,
      type_transaction: 1,
      delivery_type: 1,
      client_id: null,
      client_address_id: null,
      client_address: {
        description : '',
        client_id : null,
        consignatario : '',
        contact_phone : '',
        phone : '',
        department_id : null,
        province_id : null,
        district_id : null,
        ag_department_id : null,
        ag_province_id : null,
        ag_district_id : null,
        agencia_ubigeo_id : null,
        agencia_ubigeo : null,
        department: null,
        province: null,
        district: null,
        zona : '',
        price : '',
        address : '',
        default : true,
        document_number : '',
        delivery_type: 1,
        origen: '',
        destino: '',
      },
      consignatario: '',
      subtotal: null,
      subtotal_igv: null,
      total: null,
      courier: '',
      tracking_number: '',
      flete: null,
      fecha_entrega: null,
      razon_social: 'Viettel Peru S.A.C.',
      ruc: '20543254798',
      contenido_factura: null,
      nota: '',
      adjunto: '',
      adjunto_url: '',
      datos_equipos: '',
      datos_equipos_url: '',
      create_user_id: '',
      create_user: '',
      pago_user_id: '',
      pago_user: '',
      codigo_bancario: '',
      constancia: '',
      constancia_url: null,
      sustento_1: '',
      sustento_1_url: '',
      sustento_2: '',
      sustento_2_url: '',
      sustento_3: '',
      sustento_3_url: '',
      nfactura: null,
      despacho_factura: '',
      despacho_guia: '',
      envio_observacion: '',
      envio_alto: null,
      envio_ancho: null,
      envio_largo: null,
      envio_peso: null,
      status: 0,
      is_partner: 0,
      pedido_details:[]
    }
  }),
  watch: {
    'pedido.codigo_bancario'(val) {
      if(val){
        if(this.pedido.status<2){
          this.pedido.status = 2;
        }
      }else{
        this.pedido.status = 1;
      }
    },
  }
}
</script>
