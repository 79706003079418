<template>
  <div>
    <Breadcrumbs :items="breadcrumbs"/>
    <div class="my-4"><h3 class="ml-5"><v-icon>mdi-chevron-left</v-icon> Agregar bodega</h3></div>
    <v-card>
      <BodegaForm :bodega="bodega"/>
    </v-card>
  </div>
</template>

<script>
// @ is an alias to /src
import Breadcrumbs from "@/components/layout/Breadcrumbs";
import BodegaForm from "@/components/bodega/BodegaForm";

export default {
  name: 'Example',
  components: {
    Breadcrumbs,
    BodegaForm
  },
  data: () => ({
    breadcrumbs: [
      {text: 'Inicio', disabled: false, href: '/',},
      {text: 'Bodegas', disabled: false, href: '/bodegas',},
      {text: 'Crear Bodega', disabled: true, href: '/bodegas/create',}
    ],
    bodega: {
      name: '',
      description: '',
    }
  }),
}
</script>
