<template>
  <div>
    <v-row>
      <v-col
          cols="12"
          md="4">
        <v-text-field
            v-model="pedido.nfactura"
            label="Factura"
            required
            @change="changeFactura"
        ></v-text-field>
      </v-col>

      <v-col
          cols="12"
          md="4">
        <v-row>
          <v-col cols="12" md="6">
            <v-file-input
                accept="image/*"
                @change="handleImage"
                label="Constancia de pago"
            ></v-file-input>
          </v-col>
          <v-col cols="12" md="6">
            <img class="img_detail" :src="pedido.constancia_url" alt="">
          </v-col>
        </v-row>
      </v-col>

    </v-row>
    <v-row v-if="userSession.role == 'P2-Supervisor'" class="approved-voucher">
      <template>
        <v-dialog v-model="dialogDelete" max-width="500px">
            <v-card>
                <v-card-title class="headline">¿Seguro de anular pedido?
                </v-card-title>
                <v-card-subtitle>
                    <v-text-field
                            v-model="input_motivo"
                            label="Ingrese un motivo por favor"
                            counter
                            maxlength="14"
                    ></v-text-field>
                </v-card-subtitle>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="closeDelete">Cancelar</v-btn>
                    <v-btn
                            :disabled="!input_motivo"
                            color="blue darken-1" text @click="deleteItemConfirm">Sí, estoy
                        seguro
                    </v-btn>
                    <v-spacer></v-spacer>
                </v-card-actions>
            </v-card>
        </v-dialog>
      </template>
      <!-- <v-col
          cols="12"
          md="3">
          <h4>Voucher de Pago</h4>
        <v-text-field
            v-model="pedido.nfactura"
            disabled
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="12">
        <img class="img_detail" :src="pedido.constancia_url" alt="">
      </v-col> -->
      <v-col cols="12" md="12" v-if="status_approved == false">
        <v-btn
          color="success"
          @click="approvedOrder()"
        >
          Aprobar Pedido
        </v-btn>
        <v-btn class="ml-5"
          color="error"
          @click="deleteItem(pedido.id)"
        >
          Rechazar Pedido
        </v-btn>
      </v-col>
      <v-col cols="2" md="2" v-if="status_approved == true">
        <v-alert type="success">
          APROBADO
        </v-alert>
      </v-col>
    </v-row>
  </div>
</template>
<style>
.approved-voucher{
  max-width: 96%;
  margin: 0 auto;
}
.v-alert{
  margin: 0 !important;
}
</style>

<script>
// @ is an alias to /src


import partnerOrderService from "@/services/partner-2/partnerOrderService";

export default {
  props: ["pedido"],
  name: 'PedidoPago',
  components: {},
  data: () => ({
    input_motivo: '',
    type_status: "partner2-order",
    dialogDelete: false,
    status_approved: false,
    userSession: {},
  }),
  mounted() {
    this.userSession = this.$store.getters.userSession;
  },
  watch: {
    pedido: {
      handler: function(newValue) {
        // console.log(newValue.status);
      },
      deep: true
    }
  },
  methods: {
    approvedOrder() {
      this.pedido.status = 3;
      this.status_approved = true;
    },

    handleImage (e) {
      this.createBase64Image(e);
    },

    createBase64Image (fileObject) {
      const reader = new FileReader
      reader.onload = e => {
        partnerOrderService.uploadPhoto(e.target.result).then(response => {
          this.pedido.constancia = response.data.fileName;
          this.pedido.constancia_url = response.data.url;
        }, error => {
          console.log('error',error)
        })
      }
      reader.readAsDataURL(fileObject);
    },
    changeFactura(obj){
        if(obj==""){
          this.pedido.status=2;
        }
    },
    deleteItem(item) {
        console.log(item)
        this.input_motivo = '';
        this.dialogDelete = true
        this.editedIndex = item
    },
    deleteItemConfirm() {
        if (this.input_motivo) {
            let data_null = {
                pedido_id: this.editedIndex,
                comment_null: this.input_motivo
            }
            partnerOrderService.nullPedido(data_null).then(result => {
                this.input_motivo = '';
                this.closeDelete()
                this.$router.push({path: "/" + this.type_status});
            })
        }

    },
    closeDelete() {
        this.dialogDelete = false
    },
  }
}
</script>
