<template>
    <div>
        <v-snackbar v-model="snackbar" :timeout="2000">
            {{ textError }}
            <template v-slot:action="{ attrs }">
                <v-btn color="red" text v-bind="attrs" @click="snackbar = false">
                    Cerrar
                </v-btn>
            </template>
        </v-snackbar>
        <v-form v-model="valid" v-on:submit="submit" class="elevation-2 py-4">
            <v-container>
                <v-row>
                    <v-col cols="12" md="6">
                        <h4 class="mb-3">Código</h4>
                        <v-text-field
                            v-model="coupon.code"
                            :rules="rules.code"
                            label="Código"
                            required
                            outlined
                            solo
                        ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="6">
                        <h4 class="mb-3">Descripción</h4>
                        <v-text-field
                            v-model="coupon.description"
                            label="Descripción"
                            required
                            outlined
                            solo
                        ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="6">
                        <h4 class="mb-3">Monto</h4>
                        <v-text-field
                            v-model="coupon.amount"
                            :rules="rules.amount"
                            label="Monto"
                            v-number
                            required
                            outlined
                            solo
                        ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="6">
                        <h4 class="mb-3">Fecha de Expiración</h4>
                        <v-menu v-model="fromDateMenu"
                                :close-on-content-click="false"
                                :nudge-right="40"
                                transition="scale-transition"
                                offset-y
                                max-width="290px"
                                min-width="290px">
                            <template v-slot:activator="{ on }">
                                <v-text-field
                                    label="Fecha de Expiración"
                                    prepend-icon="event"
                                    outlined
                                    readonly
                                    :value="date_value"
                                    v-on="on"
                                ></v-text-field>
                            </template>
                            <v-date-picker
                                locale="es-ES"
                                v-model="coupon.expire_in"
                                no-title
                                @input="fromDateMenu = false"
                            ></v-date-picker>
                        </v-menu>
                    </v-col>
                    <v-col cols="12" md="6">
                        <h4 class="mb-3">Máximo número de canjes</h4>
                        <v-text-field
                            v-model="coupon.max_number_exchanges"
                            :rules="rules.max_number_exchanges"
                            label="Máximo número de canjes"
                            v-intNumber
                            required
                            outlined
                            solo
                        ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="6">
                        <h4 class="mb-3">Estado</h4>
                        <v-select
                            v-model="coupon.status"
                            :items="allstatus"
                            outlined
                            item-text="name"
                            item-value="id"
                            label="Estado"
                        ></v-select>

                    </v-col>
                </v-row>

                <v-row>
                    <v-col
                        cols="12"
                        md="12"
                        class="d-flex">
                        <v-btn
                            :disabled="!valid"
                            class="btn-blue mx-auto"
                            @click="submit"
                            v-if="!coupon.id"
                        >
                            Agregar cupón
                        </v-btn>
                        <v-btn
                            :disabled="!valid"
                            color="success"
                            class="mr-4"
                            @click="submitUpdate"
                            v-if="coupon.id"
                        >
                            Actualizar
                        </v-btn>
                    </v-col>
                </v-row>
            </v-container>
        </v-form>
    </div>
</template>

<script>
    import couponService from "@/services/couponService";

    export default {
        name: "CouponForm",
        props: ["coupon"],
        data: () => ({
            snackbar: false,
            textError: '',
            valid: false,
            alert: false,
            fromDateMenu: false,
            rules: {
                code: [
                    v => !!v || 'Campo obligatorio',
                ],
                amount: [
                    v => !!v || 'Campo obligatorio',
                ],
                max_number_exchanges: [
                    v => !!v || 'Campo obligatorio',
                ],
            },
            allstatus: [
                {id: 1, name: 'Activo'},
                {id: 0, name: 'Inactivo'}
            ]
        }),
        mounted() {
        },
        computed: {
            date_value() {
                return this.formatDate(this.coupon.expire_in);
            }
        },
        methods: {
            submit() {
                couponService.store(this.coupon).then(response => {
                    this.$router.push({path: '/coupons'})
                }, (error, data) => {
                    this.textError = error.response.data.message;
                    this.snackbar = true;
                });
            },
            submitUpdate() {
                couponService.update(this.coupon).then(response => {
                    this.$router.push({path: '/coupons'})
                }, error => {
                    this.textError = error.response.data.message;
                    this.snackbar = true;
                    console.log('error', error);
                });
            },
            formatDate(date) {
                if (!date) return null;
                const [year, month, day] = date.split('-');
                return `${day}/${month}/${year}`
            },
        }
    }
</script>

<style scoped>

</style>
