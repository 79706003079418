<template>
    <v-form v-model="valid" v-on:submit="submit" class="elevation-2 py-4">
        <v-container>
            <v-row>
                <v-col cols="12" md="6">
                    <h4 class="mb-3">Nombre</h4>
                    <v-text-field
                            v-model="categoria.nombre"
                            :rules="rules.name"
                            label="Nombre"
                            required
                            outlined
                            solo
                    ></v-text-field>
                </v-col>
                <v-col cols="12" md="6">
                    <h4 class="mb-3">Descripción</h4>
                    <v-text-field
                            v-model="categoria.descripcion"
                            label="Descripción"
                            required
                            outlined
                            solo
                    ></v-text-field>
                </v-col>
            </v-row>

            <v-row>
                <v-col cols="12" md="12" class="d-flex">
                    <v-btn :disabled="!valid" class="mx-auto btn-blue" @click="submit" v-if="!categoria.id">
                        Agregar categoría
                    </v-btn>
                    <v-btn :disabled="!valid" class="mx-auto btn-blue" @click="submitUpdate" v-if="categoria.id">
                        Actualizar
                    </v-btn>
                </v-col>
            </v-row>
        </v-container>
    </v-form>
</template>

<script>
    import categoriaService from "@/services/categoriaService";

    export default {
        name: "CategoriaForm",
        props: ["categoria"],
        data: () => ({
            valid: false,
            alert: false,
            rules: {
                name: [
                    v => !!v || 'Nombre es requerido',
                ],
            },
        }),
        mounted() {
        },
        methods: {
            submit() {
                categoriaService.store(this.categoria).then(response => {
                    this.$router.push({path: '/categorias'})
                }, (error, data) => {
                    this.alert = true;
                    setTimeout(() => {
                        this.alert = false
                    }, 3000);
                });
            },
            submitUpdate() {
                categoriaService.update(this.categoria).then(response => {
                    this.$router.push({path: '/categorias'})
                }, error => {
                    console.log('error', error);
                });
            },
        }
    }
</script>

<style scoped>

</style>
