<template>
    <v-form v-model="valid" v-on:submit="submit" class="elevation-2 py-4">
        <v-container>
            <v-row>
                <v-col cols="12" md="6">
                    <h4 class="mb-3">Nombre</h4>
                    <v-text-field
                            v-model="channel.name"
                            :rules="rules.name"
                            label="Nombre"
                            required
                            outlined
                            solo
                    ></v-text-field>
                </v-col>
            </v-row>

            <v-row>
                <v-col
                        cols="12"
                        md="12"
                        class="d-flex">
                    <v-btn
                            :disabled="!valid"
                            class="btn-blue mx-auto"
                            @click="submit"
                            v-if="!channel.id"
                    >
                        Agregar canal
                    </v-btn>
                    <v-btn
                            :disabled="!valid"
                            color="success"
                            class="mr-4"
                            @click="submitUpdate"
                            v-if="channel.id"
                    >
                        Actualizar
                    </v-btn>
                </v-col>
            </v-row>
        </v-container>
    </v-form>
</template>

<script>
    import channelService from "@/services/channelService";

    export default {
        name: "ChannelForm",
        props: ["channel"],
        data: () => ({
            valid: false,
            alert: false,
            rules: {
                name: [
                    v => !!v || 'Nombre es requerido',
                ],
            },
        }),
        mounted() {
        },
        methods: {
            submit() {
                channelService.store(this.channel).then(response => {
                    this.$router.push({path: '/channels'})
                }, (error, data) => {
                    this.alert = true;
                    setTimeout(() => {
                        this.alert = false
                    }, 3000);
                });
            },
            submitUpdate() {
                channelService.update(this.channel).then(response => {
                    this.$router.push({path: '/channels'})
                }, error => {
                    console.log('error', error);
                });
            },
        }
    }
</script>

<style scoped>

</style>
