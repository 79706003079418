<template>
    <v-form v-model="valid" v-on:submit="submit" class="elevation-2 py-4">
        <v-container>
            <v-row>
                <v-col cols="12" md="6">
                    <h4 class="mb-3">Nombre</h4>
                    <v-text-field
                        v-model="lista.name"
                        :rules="rules.name"
                        label="Nombre"
                        required
                        outlined
                        solo
                    ></v-text-field>
                </v-col>
                <v-col cols="12" md="6">
                    <h4 class="mb-3">Descripción</h4>
                    <v-text-field
                        v-model="lista.description"
                        label="Descripción"
                        required
                        outlined
                        solo
                    ></v-text-field>
                </v-col>
            </v-row>

            <v-row>
                <v-col cols="12" md="6">
                    <h4 class="mb-3">Flexline ID</h4>
                    <v-text-field
                        v-model="lista.id_flexline"
                        label="Flexline ID"
                        required
                        outlined
                        solo
                    ></v-text-field>
                </v-col>
                <v-col cols="12" md="6">
                    <h4 class="mb-3">Flexline Name</h4>
                    <v-text-field
                        v-model="lista.name_flexline"
                        label="Flexline Name"
                        required
                        outlined
                        solo
                    ></v-text-field>
                </v-col>
                <v-col cols="12" md="6">
                    <h4 class="mb-3">Fecha Vigencia</h4>
                    <v-text-field
                        v-model="lista.fecha_vigencia"
                        label="Fecha Vigencia"
                        required
                        outlined
                    ></v-text-field>
                </v-col>
                <v-col cols="12" md="6">
                    <h4 class="mb-3">Bodega</h4>
                    <v-combobox
                        v-model="lista.bodega"
                        :items="lista_bodegas"
                        item-text="name"
                        item-value="id"
                        label="Bodega"
                        outlined
                        solo
                    ></v-combobox>
                </v-col>
                <v-col cols="12" md="6">
                    <h4 class="mb-3">Partner</h4>
                    <v-combobox
                        v-model="lista.partner"
                        :items="lista_partners"
                        item-text="name"
                        item-value="id"
                        label="Partner"
                        outlined
                        solo
                    ></v-combobox>
                </v-col>
                <v-col cols="12" md="6">
                    <h4 class="mb-3">Modelo de Negocio</h4>
                    <v-combobox
                        v-model="lista.modelo_negocio"
                        :items="modelo_negocio"
                        item-text="name"
                        item-value="id"
                        label="Modelo Negocio"
                        outlined
                        solo
                    ></v-combobox>
                </v-col>
                <v-col cols="12" md="6">
                    <h4 class="mb-3">Nivel de Precio</h4>
                    <v-combobox
                        v-model="lista.nivel_precio"
                        :items="nivel_precio"
                        item-text="name"
                        item-value="id"
                        label="Nivel de Precio"
                        outlined
                        solo
                    ></v-combobox>
                </v-col>
                <v-col cols="12" md="6">
                    <h4 class="mb-3">Canal</h4>
                    <v-select
                        v-model="lista.channel_id"
                        :items="channels"
                        @change="onChangeChannelId(true)"
                        item-text="name"
                        item-value="id"
                        label="Canal"
                        outlined
                        solo
                    ></v-select>
                </v-col>
                <v-col cols="12" md="6">
                    <h4 class="mb-3">Subcanal</h4>
                    <v-select
                        v-model="lista.subchannel_id"
                        :items="subchannels"
                        item-text="name"
                        item-value="id"
                        label="Subcanal"
                        outlined
                        solo
                    ></v-select>
                </v-col>
            </v-row>

            <v-row>
                <!--         <v-col cols="12" md="6">
                          <h4 class="mb-3">Estado</h4>
                          <v-combobox
                            label="Estado"
                            outlined
                            solo
                          ></v-combobox>
                        </v-col> -->
            </v-row>
            <v-row>
                <v-col cols="12" md="4">
                    <p>Rangos</p>
                    <v-radio-group v-model="lista.rangos"
                                   row>
                        <v-radio
                            label="Sí"
                            :value="1"
                        ></v-radio>
                        <v-radio
                            label="No"
                            :value="0"
                        ></v-radio>
                    </v-radio-group>
                </v-col>
            </v-row>
            <v-row v-if="lista.rangos">
                <v-col>
                    <v-card>
                        <v-simple-table>
                            <template v-slot:default>
                                <thead>
                                <tr>
                                    <th>Rango mínimo</th>
                                    <th>Rango máximo</th>
                                    <th>Flexline ID Rango</th>
                                    <th>Flexline Name Rango</th>
                                    <th>Flexline Fecha vigencia</th>
                                    <th>Acciones</th>
                                    <th>
                                        <v-btn small color="info" @click="addRango">
                                            Agregar Rango
                                        </v-btn>
                                    </th>
                                </tr>
                                </thead>
                                <tbody>
                                <template v-for="(item,key) in lista.lista_precio_range">
                                    <tr :key="key">
                                        <td>
                                            <v-text-field
                                                label="Rango mínimo"
                                                v-model="lista.lista_precio_range[key].rango_menor"
                                            ></v-text-field>
                                        </td>
                                        <td>
                                            <v-text-field
                                                label="Rango máximo"
                                                v-model="lista.lista_precio_range[key].rango_mayor"
                                            ></v-text-field>
                                        </td>
                                        <td>
                                            <v-text-field
                                                label="Flexline ID Rango"
                                                v-model="lista.lista_precio_range[key].id_flexline"
                                            ></v-text-field>
                                        </td>
                                        <td>
                                            <v-text-field
                                                label="Flexline Name Rango"
                                                v-model="lista.lista_precio_range[key].name_flexline"
                                            ></v-text-field>
                                        </td>
                                        <td>
                                            <v-text-field
                                                label="Flexline Fecha vigencia"
                                                v-model="lista.lista_precio_range[key].fecha_vigencia"
                                            ></v-text-field>
                                        </td>
                                        <td>
                                            <v-icon @click="deleteRango(key)">
                                                mdi-delete
                                            </v-icon>
                                        </td>
                                    </tr>
                                </template>

                                </tbody>
                            </template>
                        </v-simple-table>
                    </v-card>
                </v-col>
            </v-row>


            <v-row>
                <v-col cols="12" md="12" class="d-flex">
                    <v-btn
                        :disabled="!valid"
                        class="btn-blue mx-auto"
                        @click="submit"
                        v-if="!lista.id"
                    >
                        Agregar lista de precios
                    </v-btn>
                    <v-btn
                        :disabled="!valid"
                        class="mx-auto"
                        @click="submitUpdate"
                        v-if="lista.id"
                    >
                        Actualizar
                    </v-btn>
                </v-col>
            </v-row>
        </v-container>
    </v-form>
</template>
<style>
.img_detail {
    width: 100px;
}
</style>
<script>
// @ is an alias to /src
import listaService from "@/services/listaService";
import partnerListPriceService from "@/services/partnerListPriceService";
import channelService from "@/services/channelService";
import subchannelService from "@/services/subchannelService";
import ubigeoService from "@/services/ubigeoService";

export default {
    props: ["lista"],
    name: "ListaForm",
    components: {},
    data: () => ({
        valid: false,
        alert: false,
        departments: [],
        provinces: [],
        districts: [],
        lista_bodegas: [],
        modelo_negocio: ["B2B", "B2C"],
        nivel_precio: ["Visual", "Neto"],
        lista_partners: [],
        lista_precio_range_ubigeo: [
            {
                departments: [],
                provinces: [],
                districts: [],
            },
        ],
        rules: {
            name: [(v) => !!v || "Nombre es requerido"],
        },
        channels: [],
        subchannels: [],
        /*numberRule: v  => {
            if (!v.trim()) return true;
            if (!isNaN(parseFloat(v))) return true;
            return false;
        },*/
    }),
    mounted() {
        this.getBodegas();
        this.getPartners();
        channelService.all().then(response => {
            this.channels = response.data.data;
        }).catch(error => {
            this.alert = true;
            setTimeout(() => {
                this.alert = false;
            }, 3000);
        });
        if (this.lista.id) {
            this.onChangeChannelId(false);
        }
    },
    methods: {
        getBodegas() {
            listaService.getBodegas().then(response => {
                this.lista_bodegas = response.data
            })
        },
        getPartners() {
            partnerListPriceService.getPartners().then(response => {
                this.lista_partners = response.data.data
            })
        },
        submit() {
            partnerListPriceService.storeLista(this.lista).then(
                (response) => {
                    this.$router.push({path: "/partner-list-price"});
                },
                (error, data) => {
                    console.log("error", error);
                    console.log("error data", data);
                    this.alert = true;
                    setTimeout(() => {
                        this.alert = false;
                    }, 3000);
                }
            );
        },
        submitUpdate() {
            partnerListPriceService.updateLista(this.lista).then(
                (response) => {
                    this.$router.push({path: "/partner-list-price"});
                },
                (error) => {
                    console.log("error", error);
                }
            );
        },
        getDepartments(key) {
            ubigeoService.getDepartments().then((response) => {
                if (!isNaN(key)) {
                    this.lista_precio_range_ubigeo[key].departments = response.data;
                } else {
                    this.departments = response.data;
                }
            });
        },
        getProvinces(key) {
            console.log("key", key);
            let department_id = isNaN(key)
                ? this.lista.department_id
                : this.lista.lista_precio_range[key].department_id;
            if (!isNaN(key)) {
                if (typeof this.lista.lista_precio_range[key].department_id === "object") {
                    department_id = this.lista.lista_precio_range[key].department_id.id;
                }
            } else if (typeof this.lista.department_id === "object") {
                department_id = this.lista.department_id.id;
            }
            console.log(department_id);
            ubigeoService.getProvinces(department_id).then((response) => {
                if (!isNaN(key)) {
                    this.lista_precio_range_ubigeo[key].provinces = response.data;
                } else {
                    this.provinces = response.data;
                }
            });
        },
        getDistricts(key) {
            let province_id = !isNaN(key)
                ? this.lista.lista_precio_range[key].province_id
                : this.lista.province_id;
            if (!isNaN(key)) {
                if (typeof this.lista.lista_precio_range[key].province_id === "object") {
                    province_id = this.lista.lista_precio_range[key].province_id.id;
                }
            } else if (typeof this.lista.province_id === "object") {
                province_id = this.lista.province_id.id;
            }
            ubigeoService.getDistricts(province_id).then((response) => {
                if (!isNaN(key)) {
                    this.lista_precio_range_ubigeo[key].districts = response.data;
                } else {
                    this.districts = response.data;
                }
            });
        },
        isLetterOrNumber(e) {
            let char = String.fromCharCode(e.keyCode);
            if (/^[0-9]+$/.test(char)) {
                if (this.lista.listaid.length >= this.documentLength) {
                    e.preventDefault();
                } else {
                    return true;
                }
            } else {
                e.preventDefault();
            }
        },
        setDocumentLength() {
            console.log(this.lista.lista_type);
            if (this.lista.lista_type === "RUC") {
                this.documentLength = 11;
            } else {
                this.documentLength = 8;
            }
        },
        addRango() {
            this.lista.lista_precio_range.push({
                code: "",
                name: "",
            });
        },
        deleteRango(key) {
            this.lista.lista_precio_range.splice(key, 1);
            // this.editedIndex = this.lista.lista_precio_range.indexOf(item);
        },
        onChangeChannelId(setNull = false) {
            if (this.lista.channel_id) {
                if (setNull) {
                    this.lista.subchannel_id = null;
                }
                channelService.getByChannel(this.lista.channel_id).then(response => {
                    this.subchannels = response.data.data;
                }).catch(error => {
                    this.alert = true;
                    setTimeout(() => {
                        this.alert = false;
                    }, 3000);
                });
            }
        }
    },
};
</script>
