<template>
  <div>
    <v-row>
      <v-col
          cols="12"
          md="4">
        <v-text-field
            v-model="pedido.nfactura"
            label="Factura"
            required
            @change="changeFactura"
        ></v-text-field>
      </v-col>

      <v-col
          cols="12"
          md="4">
        <v-row>
          <v-col cols="12" md="6">
            <v-file-input
                accept="image/*"
                @change="handleImage"
                label="Constancia de pago"
            ></v-file-input>
          </v-col>
          <v-col cols="12" md="6">
            <img class="img_detail" :src="pedido.constancia_url" alt="">
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<script>
// @ is an alias to /src


import pedidoService from "@/services/pedidoService";

export default {
  props: ["pedido"],
  name: 'PedidoPago',
  components: {},
  watch: {
    pedido: {
      handler: function(newValue) {
        // console.log(newValue.status);
      },
      deep: true
    }
  },
  methods: {
    handleImage (e) {
      this.createBase64Image(e);
    },

    createBase64Image (fileObject) {
      const reader = new FileReader
      reader.onload = e => {
        pedidoService.uploadPhoto(e.target.result).then(response => {
          this.pedido.constancia = response.data.fileName;
          this.pedido.constancia_url = response.data.url;
        }, error => {
          console.log('error',error)
        })
      }
      reader.readAsDataURL(fileObject);
    },
    changeFactura(obj){
        if(obj==""){
          this.pedido.status=2;
        }
    }
  }
}
</script>
