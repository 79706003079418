<template>
  <div>
    <Breadcrumbs :items="breadcrumbs"/>
    <v-card>
      <PedidoForm v-if="pedido.is_approved < 1 && (userSession.role == 'P2-Supervisor' || userSession.role == 'P2-EjecutivoVentas')" :pedido="pedido" :edit_codigo_bancario="edit_codigo_bancario"/>
      <PedidoFormAdmin v-if="userSession.role === 'admin' || userSession.role=='saco'" :pedido="pedido" :edit_codigo_bancario="edit_codigo_bancario"/>
      <DetallePedidoPartner v-if="pedido.is_approved == 1 && (userSession.role === 'P2-Supervisor' || userSession.role === 'P2-EjecutivoVentas') && pedido.id" :pedido="pedido"/>
    </v-card>
  </div>
</template>

<script>
// @ is an alias to /src
import Breadcrumbs from "@/components/layout/Breadcrumbs";
import PedidoForm from "@/components/partner-2/partner-order/PedidoForm";
import PedidoFormAdmin from "@/components/partner-2/partner-order/PedidoFormAdmin";
import DetallePedidoPartner from "@/components/partner-2/partner-order/DetallePedidoPartner";
import partnerOrderService from "@/services/partner-2/partnerOrderService";

export default {
  name: 'PedidosCreate',
  props: ["pedido_address","departments","provinces","districts"],
  components: {
    Breadcrumbs,
    PedidoForm,
    DetallePedidoPartner,
    PedidoFormAdmin
  },
  mounted() {
    this.getDataPedido(this.$route.params.id);
    this.userSession = this.$store.getters.userSession;
  },
  data: () => ({
    breadcrumbs: [
      {text: 'Inicio', disabled: false, href: '/',},
      {text: 'Pedidos', disabled: false, href: '/partner2-order',},
      {text: 'Editar Pedido', disabled: true, href: '/partner2-order/create',}
    ],
    tab: null,
    items: [
      { tab: 'Detalles', content: 'DetallePedido' },
      { tab: 'Despachar', content: 'DetallePedido' },
      { tab: 'Pago', content: 'DetallePedido' },
      { tab: 'Registros', content: 'DetallePedido' },
    ],
    userSession: {},
    edit_codigo_bancario: false,
    pedido: {
      api_bitel:2,
      type_transaction: 1,
      minorista_mayorista: 1,
      client_id: null,
      client_address_id: null,
      is_business:1,
      business_id: null,
      business_addresses_id: null,
      partner_client_2_id: null,
      partner_client_address_2_id: null,

      client_address: {
        description : '',
        client_id : null,
        consignatario : '',
        contact_phone : '',
        phone : '',
        department_id : null,
        province_id : null,
        district_id : null,
        ag_department_id : null,
        ag_province_id : null,
        ag_district_id : null,
        agencia_ubigeo_id : null,
        agencia_ubigeo : null,
        department: null,
        province: null,
        district: null,
        zona : '',
        price : '',
        address : '',
        default : true,
        document_number : '',
      },
      business_address: {
        description : '',
        business_id : null,
        consignatario : '',
        contact_phone : '',
        phone : '',
        department_id : null,
        province_id : null,
        district_id : null,
        ag_department_id : null,
        ag_province_id : null,
        ag_district_id : null,
        agencia_ubigeo_id : null,
        agencia_ubigeo : null,
        department: null,
        province: null,
        district: null,
        zona : '',
        price : '',
        address : '',
        default : true,
        document_number : '',
        delivery_type: 1,
        origen: '',
        destino: '',
      },
      consignatario: '',
      subtotal: null,
      subtotal_igv: null,
      total: null,
      courier: '',
      tracking_number: '',
      flete: null,
      fecha_entrega: null,
      razon_social: null,
      ruc: null,
      contenido_factura: null,
      nota: '',
      adjunto: '',
      adjunto_url: '',
      create_user_id: '',
      create_user: '',
      pago_user_id: '',
      pago_user: '',
      codigo_bancario: '',
      constancia: '',
      constancia_url: '',
      sustento_1: '',
      sustento_1_url: '',
      sustento_2: '',
      sustento_2_url: '',
      sustento_3: '',
      sustento_3_url: '',
      nfactura: '',
      despacho_factura: '',
      despacho_guia: '',
      status: 0,
      is_approved: 0,
      is_partner: 0,
      pedido_details:[]
    }
  }),
  watch: {
    'pedido.codigo_bancario'(val) {
      if(this.pedido.status<3){
        if(val){
          if(this.pedido.status<2){
            this.pedido.status = 2;
          }
        }else{
          this.pedido.status = 1;
        }
      }
    },
    // 'pedido.constancia'(val) {
    //   if(this.pedido.codigo_bancario && val && this.pedido.status<3){
    //     this.pedido.status = 3;
    //   }
    // },
    'pedido.nfactura'(val){
     if(this.pedido.nfactura && val && this.pedido.status<3){
            this.pedido.status = 2;
      }
    },
    // 'pedido.nfactura'(val){
    //  if(this.pedido.nfactura && val &&this.pedido.status<3){
    //         this.pedido.status = 3;
    //   }
    // },
    'pedido.tracking_number'(val) {
      // if( val && this.pedido.despacho_guia && this.pedido.despacho_factura && this.pedido.codigo_bancario && this.pedido.constancia && this.pedido.courier  && this.pedido.status<4){
          // if( val && this.pedido.despacho_guia && this.pedido.despacho_factura && this.pedido.codigo_bancario && this.pedido.courier  && this.pedido.status<4){
         if( val && this.pedido.despacho_guia && this.pedido.nfactura && this.pedido.courier  && this.pedido.status<4){
        this.pedido.status = 4;
      }
    },
    'pedido.despacho_factura'(val) {
      // if( val && this.pedido.despacho_guia && this.pedido.despacho_factura && this.pedido.codigo_bancario && this.pedido.constancia && this.pedido.courier  && this.pedido.status<4){
     if( val && this.pedido.tracking_number && this.pedido.despacho_guia &&this.pedido.nfactura && this.pedido.courier  && this.pedido.status==5){
        //this.pedido.status = 6;
      }
    },
    'pedido.despacho_guia'(val) {
      // if( val && this.pedido.tracking_number && this.pedido.despacho_factura &&this.pedido.codigo_bancario && this.pedido.constancia && this.pedido.courier  && this.pedido.status<4){
      //  if( val && this.pedido.tracking_number && this.pedido.despacho_factura &&this.pedido.codigo_bancario && this.pedido.courier  && this.pedido.status<4){
      if( val && this.pedido.tracking_number && this.pedido.nfactura && this.pedido.courier  && this.pedido.status<4){
       this.pedido.status = 4;
      }
    },
  },
  methods: {
    getDataPedido(pedido_id) {
      partnerOrderService.getPedido(pedido_id).then(response => {
        // console.log("getdata:",response.data)
        this.pedido = response.data;
      })
    }
  }
}
</script>
