import axios from "axios";

const ENDPOINT_PATH = process.env.VUE_APP_BASE_URL;

export default {
    datatable(data) {
        return axios.post(`${ENDPOINT_PATH}/partners/datatable`, data);
    },
    index() {
        return axios.get(`${ENDPOINT_PATH}/partners`);
    },
    show(id) {
        return axios.get(`${ENDPOINT_PATH}/partners/${id}`);
    },
    update(data) {
        return axios.put(`${ENDPOINT_PATH}/partners/${data.id}`, data);
    },
    uploadPhoto(image_base64) {
        const file = {file : image_base64};
        return axios.post(`${ENDPOINT_PATH}/partners/upload-photo`, file);
    }
}