<template>
    <div>
        <Breadcrumbs :items="breadcrumbs"/>
        <v-card>
            <v-data-table
                :headers="headers"
                :items="products"
                :options.sync="options"
                :server-items-length="totalProducts"
                :loading="loading"
                class="elevation-1">
                <template v-slot:top>
                    <v-col cols="12" md="12">
                        <v-row>
                            <v-col>
                                <v-toolbar-title>Productos</v-toolbar-title>
                            </v-col>
                        </v-row>
                    </v-col>
                    <v-col cols="12" md="12">
                        <v-row>
                            <v-col cols="12" md="3">
                                <v-text-field
                                    v-model="options.search_code"
                                    label="Código"
                                    class="mx-3"
                                    outlined
                                    solo
                                    dense
                                ></v-text-field>
                            </v-col>
                            <v-col cols="12" md="3">
                                <v-text-field
                                    v-model="options.search_name"
                                    label="Nombre"
                                    class="mx-3"
                                    outlined
                                    solo
                                    dense
                                ></v-text-field>
                            </v-col>
                            <v-col cols="12" md="3">
                                <v-select
                                    :items="marcas"
                                    v-model="options.search_marca"
                                    label="Marca"
                                    item-text="nombre"
                                    item-value="id"
                                    outlined
                                    solo
                                    dense
                                ></v-select>
                            </v-col>
                            <v-col cols="12" md="3">
                                <v-select
                                    :items="categorias"
                                    v-model="options.search_categoria"
                                    label="Categoría"
                                    item-text="nombre"
                                    item-value="id"
                                    outlined
                                    solo
                                    dense
                                ></v-select>
                            </v-col>
                        </v-row>
                    </v-col>
                    <v-col cols="12" md="12">
                        <v-btn dark class="btn-green mb-2 float-right" to="/products/create">
                            <v-icon left>
                                mdi-plus
                            </v-icon>
                            Agregar producto
                        </v-btn>
                    </v-col>
                </template>

                <template v-slot:item="{ item }">
                    <tr>
                        <td class="bg-light-blue"> {{ item.codigo_saco }}</td>
                        <td class="bg-light-blue"> {{ item.product.name }}</td>
                        <td>{{ item.product.unit }}</td>
                        <td>{{ item.product.marca ? item.product.marca.nombre : '' }}</td>
                        <td>{{ item.product.categoria ? item.product.categoria.nombre : '' }}</td>
                        <td>{{ item.color }}</td>
                        <td>{{ item.capacity }}</td>
                        <td>
                            <div class="p-2 mt-1 mb-1">
                                <v-img :src="item.image_url" :alt="item.product.name" width="70px"></v-img>
                            </div>
                        </td>
                        <td class="bg-light-blue">{{ item.stock_flex }}</td>
                        <td>{{ item.stock }}</td>
                        <td class="bg-light-blue">{{ item.price | currency(' ')}}</td>
                        <td class="bg-light-blue">{{ item.price_flex | currency(' ')}}</td>
                        <td>
                            <v-btn icon :to="'/products/edit/' + item.product_id">
                                <v-icon
                                    small
                                    class="mr-2"
                                    @click="editItem(item)"
                                >
                                    mdi-square-edit-outline
                                </v-icon>
                            </v-btn>
                        </td>
                    </tr>
                </template>
            </v-data-table>
        </v-card>
    </div>
</template>

<script>
// @ is an alias to /src
import Breadcrumbs from "@/components/layout/Breadcrumbs";
import productService from "@/services/productService";
import marcaService from "@/services/marcaService";
import categoriaService from "@/services/categoriaService";

export default {
    name: 'Example',
    components: {
        Breadcrumbs,
    },
    data: () => ({
        breadcrumbs: [
            {text: 'Inicio', disabled: false, href: '/',},
            {text: 'Productos', disabled: true, href: '/products',}
        ],
        marcas: [],
        categorias: [],
        totalProducts: 0,
        products: [],
        loading: true,
        options: {
            search_categoria: '',
            search_marca: '',
            search_code: "",
            search_name: "",
        },
        headers: [
            {
                text: 'Código',
                align: 'start',
                sortable: false,
                value: 'codigo_saco',
            },
            {
                text: 'Nombre',
                sortable: false,
                value: 'product.name',
            },
            {text: 'Unidad', value: 'product.unit'},
            {text: 'Marca', value: 'product.marca.nombre'},
            {text: 'Categoría', value: 'product.categoria.nombre'},
            {text: 'Color', value: 'color'},
            {text: 'Capacity', value: 'capacity'},
            {text: 'Image', value: 'image_url'},
            {text: 'Stock Total', value: 'stock_flex'},
            {text: 'Stock Disponible', value: 'stock'},
            {text: 'Price B2B', value: 'price'},
            {text: 'Price B2C', value: 'price_flex'},
            {text: 'Actions', value: 'actions', sortable: false},
        ],
    }),
    watch: {
        options: {
            handler() {
                this.getDataFromApi()
            },
            deep: true,
        },
    },
    mounted() {
        this.getDataFromApi();
        marcaService.all().then(response => {
            this.marcas = response.data.data;
            this.marcas.unshift({
                'id': '',
                'nombre': 'Todas las Marcas',
            });
        }).catch(error => {
            console.error('error', error);
        });
        categoriaService.all().then(response => {
            this.categorias = response.data.data;
            this.categorias.unshift({
                'id': '',
                'nombre': 'Todas las categorías',
            });
        }).catch(error => {
            console.error('error', error);
        });
    },
    methods: {
        editItem(item) {
            console.log(item);
        },
        deleteItem(item) {

        },
        getDataFromApi() {
            this.loading = true
            productService.productsDatatable(this.options).then(response => {
                // console.log('data_api',response);
                this.products = response.data.items
                this.totalProducts = response.data.total
                this.loading = false
            })
        },
        /**
         * In a real application this would be a call to fetch() or axios.get()
         */
        fakeApiCall() {
            return new Promise((resolve, reject) => {
                const {sortBy, sortDesc, page, itemsPerPage} = this.options

                let items = this.getDesserts()
                const total = items.length

                if (sortBy.length === 1 && sortDesc.length === 1) {
                    items = items.sort((a, b) => {
                        const sortA = a[sortBy[0]]
                        const sortB = b[sortBy[0]]

                        if (sortDesc[0]) {
                            if (sortA < sortB) return 1
                            if (sortA > sortB) return -1
                            return 0
                        } else {
                            if (sortA < sortB) return -1
                            if (sortA > sortB) return 1
                            return 0
                        }
                    })
                }

                if (itemsPerPage > 0) {
                    items = items.slice((page - 1) * itemsPerPage, page * itemsPerPage)
                }

                setTimeout(() => {
                    resolve({
                        items,
                        total,
                    })
                }, 1000)
            })
        },
        getDesserts() {
            return [
                {
                    name: 'Frozen Yogurt',
                    calories: 159,
                    fat: 6.0,
                    carbs: 24,
                    protein: 4.0,
                    iron: '1%',
                },
                {
                    name: 'Ice cream sandwich',
                    calories: 237,
                    fat: 9.0,
                    carbs: 37,
                    protein: 4.3,
                    iron: '1%',
                },
                {
                    name: 'Eclair',
                    calories: 262,
                    fat: 16.0,
                    carbs: 23,
                    protein: 6.0,
                    iron: '7%',
                },
                {
                    name: 'Cupcake',
                    calories: 305,
                    fat: 3.7,
                    carbs: 67,
                    protein: 4.3,
                    iron: '8%',
                },
                {
                    name: 'Gingerbread',
                    calories: 356,
                    fat: 16.0,
                    carbs: 49,
                    protein: 3.9,
                    iron: '16%',
                },
                {
                    name: 'Jelly bean',
                    calories: 375,
                    fat: 0.0,
                    carbs: 94,
                    protein: 0.0,
                    iron: '0%',
                },
                {
                    name: 'Lollipop',
                    calories: 392,
                    fat: 0.2,
                    carbs: 98,
                    protein: 0,
                    iron: '2%',
                },
                {
                    name: 'Honeycomb',
                    calories: 408,
                    fat: 3.2,
                    carbs: 87,
                    protein: 6.5,
                    iron: '45%',
                },
                {
                    name: 'Donut',
                    calories: 452,
                    fat: 25.0,
                    carbs: 51,
                    protein: 4.9,
                    iron: '22%',
                },
                {
                    name: 'KitKat',
                    calories: 518,
                    fat: 26.0,
                    carbs: 65,
                    protein: 7,
                    iron: '6%',
                },
            ]
        },
    },
}
</script>

<style>
.bg-light-blue {
    background-color: #dcf2ff;
}
.theme--light.v-data-table>.v-data-table__wrapper>table>tbody>tr:not(:last-child)>td:last-child, .theme--light.v-data-table>.v-data-table__wrapper>table>tbody>tr:not(:last-child)>td:not(.v-data-table__mobile-row), .theme--light.v-data-table>.v-data-table__wrapper>table>tbody>tr:not(:last-child)>th:last-child, .theme--light.v-data-table>.v-data-table__wrapper>table>tbody>tr:not(:last-child)>th:not(.v-data-table__mobile-row), .theme--light.v-data-table>.v-data-table__wrapper>table>thead>tr:last-child>th {
    vertical-align: top;
}
</style>
