<template>
  <div>
    <Breadcrumbs :items="breadcrumbs"/>
    <v-card>
      <PedidoForm :pedido="pedido" v-if="pedido.id" :edit_codigo_bancario="edit_codigo_bancario"/>
    </v-card>
  </div>
</template>

<script>
// @ is an alias to /src
import Breadcrumbs from "@/components/layout/Breadcrumbs";
import PedidoForm from "@/components/pedido-web/PedidoForm";
import pedidoService from "@/services/pedidoService";

export default {
  name: 'PedidosCreate',
  components: {
    Breadcrumbs,
    PedidoForm,
  },
  mounted() {
      this.id = this.$route.params.id;
    this.getDataPedidoWeb(this.id);
  },
  data: () => ({
      id: '',
    breadcrumbs: [
      {text: 'Inicio', disabled: false, href: '/',},
      {text: 'Pedidos', disabled: false, href: '/pedidos-web',},
      {text: 'Editar Pedido', disabled: true, href: `/pedidos-web/edit/`,}
    ],
    tab: null,
    items: [
      { tab: 'Detalles', content: 'DetallePedido' },
      { tab: 'Despachar', content: 'DetallePedido' },
      { tab: 'Pago', content: 'DetallePedido' },
      { tab: 'Registros', content: 'DetallePedido' },
    ],
    edit_codigo_bancario: false,
    pedido: {
      user_id: null,
      user_address_id: null,
      user_address: {
        description : '',
        user_id : null,
        consignatario : '',
        contact_phone : '',
        phone : '',
        department_id : null,
        province_id : null,
        district_id : null,
        department: null,
        province: null,
        district: null,
        zona : '',
        price : '',
        address : '',
        default : true,
        document_number : '',
      },
      consignatario: '',
      subtotal: null,
      subtotal_igv: null,
      total: null,
      courier: '',
      tracking_number: '',
      flete: null,
      fecha_entrega: null,
      razon_social: 'Viettel Peru S.A.C.',
      ruc: '20543254798',
      contenido_factura: null,
      nota: '',
      adjunto: '',
      adjunto_url: '',
      create_user_id: '',
      create_user: '',
      pago_user_id: '',
      pago_user: '',
      codigo_bancario: '',
      constancia: '',
      constancia_url: '',
      sustento_1: '',
      sustento_1_url: '',
      sustento_2: '',
      sustento_2_url: '',
      sustento_3: '',
      sustento_3_url: '',
      nfactura: '',
      despacho_factura: '',
      despacho_guia: '',
      status: 0,
      pedido_web_details:[]
    }
  }),
  watch: {
    'pedido.codigo_bancario'(val) {
      if(this.pedido.status<3){
        if(val){
          if(this.pedido.status<2){
            this.pedido.status = 2;
          }
        }else{
          this.pedido.status = 1;
        }
      }
    },
    // 'pedido.constancia'(val) {
    //   if(this.pedido.codigo_bancario && val && this.pedido.status<3){
    //     this.pedido.status = 3;
    //   }
    // },
    'pedido.nfactura'(val){
     if(this.pedido.nfactura && val &&this.pedido.status<3){
            this.pedido.status = 3;
      }
    },
    'pedido.tracking_number'(val) {
      // if( val && this.pedido.despacho_guia && this.pedido.despacho_factura && this.pedido.nfactura && this.pedido.constancia && this.pedido.courier  && this.pedido.status<4){
          // if( val && this.pedido.despacho_guia && this.pedido.despacho_factura && this.pedido.nfactura && this.pedido.courier  && this.pedido.status<4){
         if( val && this.pedido.despacho_guia && this.pedido.nfactura && this.pedido.courier  && this.pedido.status<4){
        this.pedido.status = 4;
      }
    },
    'pedido.despacho_factura'(val) {
      // if( val && this.pedido.despacho_guia && this.pedido.despacho_factura && this.pedido.nfactura && this.pedido.constancia && this.pedido.courier  && this.pedido.status<4){
     if( val && this.pedido.tracking_number && this.pedido.despacho_guia &&this.pedido.nfactura && this.pedido.courier  && this.pedido.status==5){
        //this.pedido.status = 6;
      }
    },
    'pedido.despacho_guia'(val) {
      // if( val && this.pedido.tracking_number && this.pedido.despacho_factura &&this.pedido.nfactura && this.pedido.constancia && this.pedido.courier  && this.pedido.status<4){
      //  if( val && this.pedido.tracking_number && this.pedido.despacho_factura &&this.pedido.nfactura && this.pedido.courier  && this.pedido.status<4){
      if( val && this.pedido.tracking_number && this.pedido.nfactura && this.pedido.courier  && this.pedido.status<4){
       this.pedido.status = 4;
      }
    },
  },
  methods: {
    getDataPedidoWeb(pedido_id) {
      pedidoService.getPedidoWeb(pedido_id).then(response => {
        this.pedido = response.data;
      })
    }
  }
}
</script>
