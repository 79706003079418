import axios from "axios";

const ENDPOINT_PATH = process.env.VUE_APP_BASE_URL;

export default {
    searchBusiness(search) {
        return axios.get(`${ENDPOINT_PATH}/business/search/${search}`);
    },
    uploadPhoto(image_base64) {
        const file = {file : image_base64};
        return axios.post(`${ENDPOINT_PATH}/pedido/upload-photo`, file);
    },
    uploadFile(formData) {
        return axios.post(`${ENDPOINT_PATH}/pedido/upload-file`, formData, {headers: {'Content-Type': 'multipart/form-data'}});
    },
    pedidosDatatable(data) {
        return axios.post(`${ENDPOINT_PATH}/pedido-manual/datatable`, data);
    },
    trackingDatatable(data) {
        return axios.post(`${ENDPOINT_PATH}/pedido-manual/tracking/datatable`, data);
    },
    trackingDatatable2(data) {
        return axios.post(`${ENDPOINT_PATH}/pedido-manual/tracking/datatable2`, data);
    },
    facturaDatatable(data) {
        return axios.post(`${ENDPOINT_PATH}/pedido/factura/datatable`, data);
    },
    reporteDatatable(data) {
        return axios.post(`${ENDPOINT_PATH}/pedido/reporte/datatable`, data);
    },
    pedidosWebDatatable(data) {
        return axios.post(`${ENDPOINT_PATH}/pedido/web/datatable`, data);
    },
    storePedidoBusiness(data) {
        return axios.post(`${ENDPOINT_PATH}/pedido-manual`, data);
    },
    updatePedido(data) {
        return axios.post(`${ENDPOINT_PATH}/pedido-manual/update`, data);
    },
    updatePedidoWeb(data) {
        return axios.post(`${ENDPOINT_PATH}/pedido-web/update`, data);
    },
    validatePedidoWeb(data) {
        return axios.post(`${ENDPOINT_PATH}/pedido-web/validate`, data);
    },
    nullPedido(data) {
        return axios.post(`${ENDPOINT_PATH}/pedido/anular`, data);
    },
    nullPedidoWeb(data) {
        return axios.post(`${ENDPOINT_PATH}/pedido-web/anular`, data);
    },
    getPedido(pedido_id) {
        return axios.get(`${ENDPOINT_PATH}/pedido-manual/${pedido_id}`);
    },
    getPedidoWeb(pedido_id) {
        return axios.get(`${ENDPOINT_PATH}/pedido-web/${pedido_id}`);
    },
    getDataTracking(pedido_id) {
        return axios.get(`${ENDPOINT_PATH}/pedido/tracking/${pedido_id}`);
    },
    getUbigeo(district_id) {
        return axios.get(`${ENDPOINT_PATH}/pedido/ubigeo/${district_id}`);
    },
    getUbigeoAgencia(district_id) {
        return axios.get(`${ENDPOINT_PATH}/pedido/ubigeo/agencia/${district_id}`);
    },
    downloadFileTest() {
        return axios.get(`${ENDPOINT_PATH}/downloadfile`,{ responseType: 'blob' });
    },
    downloadPedidoBusinessCoti(id) {
        return axios.get(`${ENDPOINT_PATH}/pedido-manual/pdf-coti/${id}`,{ responseType: 'blob' });
    },
    downloadPedidoBusiness(id) {
        return axios.get(`${ENDPOINT_PATH}/pedido-manual/pdf/${id}`,{ responseType: 'blob' });
    },
    downloadXlsEquipos(id) {
        return axios.get(`${ENDPOINT_PATH}/pedido-manual/xlsx_pedido/${id}`,{ responseType: 'blob' });
    },
    downloadPagado(id) {
        return axios.get(`${ENDPOINT_PATH}/pedido-manual/pdf_pagado/${id}`,{ responseType: 'blob' });
    },
    downloadPagadoWeb(id) {
        return axios.get(`${ENDPOINT_PATH}/pedido-manual/pdf_pagado_web/${id}`,{ responseType: 'blob' });
    },
    downloadXslxPedidos(data) {
        return axios.post(`${ENDPOINT_PATH}/pedido-manual/export-xlsx`,data,{ responseType: 'blob' });
    },
    downloadXslxPedidosReport(data) {
        return axios.post(`${ENDPOINT_PATH}/pedido-manual/export-xlsx/report`,data,{ responseType: 'blob' });
    },
    downloadTicketPedido(id) {
        return axios.get(`${ENDPOINT_PATH}/pedido-manual/ticket-pdf/${id}`,{ responseType: 'blob' });
    },
    indicadoresPedidos(params) {
        return axios.get(`${ENDPOINT_PATH}/pedido-manual/indicadores`,{ params: params });
    },
    syncImeisBitel(id) {
        return axios.post(`${ENDPOINT_PATH}/pedido-manual/sync/bitel/${id}`);
    },
    syncImeisFlexline(id) {
        return axios.post(`${ENDPOINT_PATH}/pedido-manual/sync/flexline/${id}`);
    }
};
