<template>
    <div>
        <Breadcrumbs :items="breadcrumbs"/>
        <div class="my-4"><h3 class="ml-5"><v-icon>mdi-chevron-left</v-icon>Agregar marca</h3></div>
        <v-card>
            <MarcaForm :marca="marca"/>
        </v-card>
    </div>
</template>

<script>
    // @ is an alias to /src
    import Breadcrumbs from "@/components/layout/Breadcrumbs";
    import MarcaForm from "@/components/marca/MarcaForm";

    export default {
        name: 'Example',
        components: {
            Breadcrumbs,
            MarcaForm
        },
        data: () => ({
            breadcrumbs: [
                {text: 'Inicio', disabled: false, href: '/',},
                {text: 'Marcas', disabled: false, href: '/marcas',},
                {text: 'Crear Marca', disabled: true, href: '/marcas/create',}
            ],
            marca: {
                nombre: '',
                descripcion: '',
            }
        }),
    }
</script>
