<template>
<div class="bg-login">
  <v-container fill-height="fill-height">
      <v-col cols="6" md="5" class="ml-auto">
      <v-flex class="login-form text-center">
        <div class="display-1 mb-3 white--text">
          
          <v-img
            src="~@/assets/images/Logo.png"
            
            class="mx-auto"
          ></v-img>
        </div>
        <v-card light="light" class="box-login">
          <v-card-text>
            <div class="subheading"  style="margin-bottom:1.5rem;">
              <span class="title-login">Bienvenidos</span>
            </div>
            <v-form>
              <v-text-field
                v-model="email"
                :error-messages="emailErrors"
                light="light"
                label="Correo"
                required
                outlined
                @input="$v.email.$touch()"
                @blur="$v.email.$touch()"
              ></v-text-field>
              <v-text-field
                v-model="password"
                :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                :error-messages="passwordErrors"
                :type="show1 ? 'text' : 'password'"
                name="input-10-1"
                light="light"
                outlined
                label="Contraseña"
                @click:append="show1 = !show1"
              ></v-text-field>
              <div class="d-flex my-3"><a href="" class="text-blue ml-auto">¿Olvidaste tu contraseña?</a></div>
              <v-btn large class="btn-blue px-5" @click="submit">Iniciar sesión </v-btn>
              <v-alert dense outlined type="error" v-if="invalid_login">
                <strong>Usuario</strong> o <strong>password</strong> incorrectos
              </v-alert>
            </v-form>
          </v-card-text>
        </v-card>
      </v-flex>
    </v-col>
  </v-container>
</div>
</template>
<style>
.login-form {
  max-width: 500px;
}
.btn-blue {
    background-color: #1371CB !important;
    border-radius: 5px;
}
</style>
<script>
// @ is an alias to /src
import { validationMixin } from "vuelidate";
import { required, email } from "vuelidate/lib/validators";
import auth from "@/services/auth";

export default {
  mixins: [validationMixin],
  validations: {
    email: { required },
    password: { required },
  },
  name: "Login",
  components: {},
  data: () => ({
    email: "",
    password: "",
    show1: false,
    invalid_login: false,
    rules: {
      required: (value) => !!value || "Requerido",
    },
  }),
  computed: {
    emailErrors() {
      const errors = [];
      if (!this.$v.email.$dirty) return errors;
      !this.$v.email.required && errors.push("Email es requerido");
      return errors;
    },
    passwordErrors() {
      const errors = [];
      if (!this.$v.password.$dirty) return errors;
      !this.$v.password.required && errors.push("Password es requerido");
      return errors;
    },
  },
  methods: {
    submit() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        //this.submitStatus = 'ERROR'
      } else {
        this.$store
          .dispatch("login", { email: this.email, password: this.password })
          .then(() => this.$router.push("/"))
          .catch((err) => {
            console.log(err);
            setTimeout(() =>{
            this.invalid_login = false;  
            },2000)
            this.invalid_login = true;
          });
      }
    },
  },
};
</script>
