import axios from "axios";

const ENDPOINT_PATH = process.env.VUE_APP_BASE_URL;

export default {
    searchClients(search) {
        return axios.get(`${ENDPOINT_PATH}/partner2-clients/search/${search}`);
    },
    pedidosDatatable(data) {
        return axios.post(`${ENDPOINT_PATH}/partner2-order/datatable`, data);
    },
    reporteDatatable(data) {
        return axios.post(`${ENDPOINT_PATH}/partner2-order/report/datatable`, data);
    },
    storePedidoBusiness(data) {
        return axios.post(`${ENDPOINT_PATH}/partner2-order`, data);
    },
    updatePedido(data) {
        return axios.post(`${ENDPOINT_PATH}/partner2-order/update`, data);
    },
    downloadPedidoBusinessCoti(id) {
        return axios.get(`${ENDPOINT_PATH}/partner2-order/pdf-coti/${id}`,{ responseType: 'blob' });
    },
    downloadPedidoBusiness(id) {
        return axios.get(`${ENDPOINT_PATH}/partner2-order/pdf/${id}`,{ responseType: 'blob' });
    },
    downloadXlsEquipos(id) {
        return axios.get(`${ENDPOINT_PATH}/partner2-order/xlsx_pedido/${id}`,{ responseType: 'blob' });
    },
    downloadXslxPedidos(data) {
        return axios.post(`${ENDPOINT_PATH}/partner2-order/export-xlsx`,data,{ responseType: 'blob' });
    },
    getPedido(pedido_id) {
        return axios.get(`${ENDPOINT_PATH}/partner2-order/${pedido_id}`);
    },
    searchProductsBusiness(search,business_id,type_transaction) {
        return axios.post(`${ENDPOINT_PATH}/products-partner2-client/search`,{
            search: search,
            business_id:business_id,
            type_transaction:type_transaction,
        });
    },
    uploadPhoto(image_base64) {
        const file = {file : image_base64};
        return axios.post(`${ENDPOINT_PATH}/partner2-order/upload-photo`, file);
    },
    uploadFile(formData) {
        return axios.post(`${ENDPOINT_PATH}/partner2-order/upload-file`, formData, {headers: {'Content-Type': 'multipart/form-data'}});
    },
    getDataTracking(pedido_id) {
        return axios.get(`${ENDPOINT_PATH}/partner2-order/tracking/${pedido_id}`);
    },
    downloadPagado(id) {
        return axios.get(`${ENDPOINT_PATH}/partner2-order/pdf_pagado/${id}`,{ responseType: 'blob' });
    },
    nullPedido(data) {
        return axios.post(`${ENDPOINT_PATH}/partner2-order/anular`, data);
    },
    downloadXslxPedidosReport(data) {
        return axios.post(`${ENDPOINT_PATH}/partner2-order/export-xlsx/report`,data,{ responseType: 'blob' });
    },
    downloadTicketPedido(id) {
        return axios.get(`${ENDPOINT_PATH}/partner2-order/ticket-pdf/${id}`,{ responseType: 'blob' });
    },
    isApproved(data) {
        return axios.post(`${ENDPOINT_PATH}/partner2-order/approved`, data);
    },
    getUbigeo(district_id) {
        return axios.get(`${ENDPOINT_PATH}/partner2-order/ubigeo/${district_id}`);
    },
    getUbigeoAgencia(district_id) {
        return axios.get(`${ENDPOINT_PATH}/partner2-order/ubigeo/agencia/${district_id}`);
    },
    syncImeisBitel(id) {
        return axios.post(`${ENDPOINT_PATH}/partner2-order/sync/bitel/${id}`);
    },
    syncImeisFlexline(id) {
        return axios.post(`${ENDPOINT_PATH}/partner2-order/sync/flexline/${id}`);
    },
    descargarImeis(id) {
        return axios.get(`${ENDPOINT_PATH}/partner2-order/descargar-imeis/${id}`,{ responseType: 'blob' })
    }
}