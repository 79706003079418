<template>
  <div>
    <Breadcrumbs :items="breadcrumbs"/>
        <div class="my-4"><h3 class="ml-5"><v-icon>mdi-chevron-left</v-icon> Agregar cliente</h3></div>
    <v-card>
      <PartnerClientForm/>
    </v-card>
  </div>
</template>

<script>
// @ is an alias to /src
import Breadcrumbs from "@/components/layout/Breadcrumbs";
import PartnerClientForm from "@/components/partner-1/partner-client/PartnerClientForm";

export default {
  name: 'Example',
  components: {
    Breadcrumbs,
    PartnerClientForm
  },
  data: () => ({
    breadcrumbs: [
      {text: 'Inicio', disabled: false, href: '/',},
      {text: 'Clientes', disabled: false, href: '/partner-client',},
      {text: 'Crear Cliente', disabled: true, href: '/partner-client/create',}
    ]
  }),
}
</script>
