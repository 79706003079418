<template>
  <v-row justify="center">
    <v-col>
      <v-dialog v-model="dialog" persistent max-width="600px">
        <template v-slot:activator="{ on, attrs }">
          <v-card class="mx-auto" outlined>
            <v-list-item three-line>
              <v-list-item-content>
                <div class="overline mb-4">Envío por agencia</div>
                <v-list-item-subtitle
                >Agencia:
                  {{
                    pedido_address.agencia_ubigeo?pedido_address.agencia_ubigeo.agencia.name:''
                  }}</v-list-item-subtitle
                >
                <v-list-item-subtitle
                >Región:
                  {{
                    pedido_address.ag_department
                        ? pedido_address.ag_department.name
                        : ""
                  }}-{{
                    pedido_address.ag_province ? pedido_address.ag_province.name : ""
                  }}-{{
                    pedido_address.ag_district ? pedido_address.ag_district.name : ""
                  }}</v-list-item-subtitle
                >
                <v-list-item-subtitle
                >UBIGEO: {{ pedido_address.ag_district?pedido_address.ag_district.id:'' }}</v-list-item-subtitle
                >
                <v-list-item-subtitle
                >Origen: {{ pedido_address.agencia_ubigeo?pedido_address.agencia_ubigeo.agencia.origen:'' }}</v-list-item-subtitle
                >
                <v-list-item-subtitle
                >Destino: {{pedido_address.agencia_ubigeo?pedido_address.agencia_ubigeo.destino:''}}</v-list-item-subtitle
                >
              </v-list-item-content>
            </v-list-item>

            <v-card-actions>
              <v-btn color="primary" dark v-bind="attrs" v-on="on" :disabled="disabledData">
                Editar
              </v-btn>
            </v-card-actions>
          </v-card>
        </template>
        <v-card>
          <v-card-title>
            <span class="headline">Envío por agencia</span>
          </v-card-title>
          <v-card-text>
            <v-form v-model="valid">
              <v-container>
                <v-row>
                  <v-col cols="12" sm="6" md="4">
                    <v-combobox
                        v-model="pedido_address.ag_department"
                        :items="departments"
                        item-text="name"
                        label="Departamento"
                        required
                        :rules="rules.department"
                        @change="getProvinces"
                    >
                    </v-combobox>
                  </v-col>
                  <v-col cols="12" sm="6" md="4">

                    <v-combobox
                        v-model="pedido_address.ag_province"
                        :items="provinces"
                        item-text="name"
                        label="Provincia"
                        required
                        :rules="rules.province"
                        @change="getDistricts"
                    >
                      <template v-slot:no-data>
                        <v-list-item>
                          <v-list-item-content>
                            <v-list-item-title>
                              Por favor seleccione un departamento
                            </v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                      </template>
                    </v-combobox>
                  </v-col>
                  <v-col cols="12" sm="6" md="4">
                    <v-combobox
                        v-model="pedido_address.ag_district"
                        :items="districts"
                        item-text="name"
                        required
                        :rules="rules.district"
                        label="Distrito"
                        @change="getAgencias"
                    >
                      <template v-slot:no-data>
                        <v-list-item>
                          <v-list-item-content>
                            <v-list-item-title>
                              Por favor seleccione una provincia
                            </v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                      </template>
                    </v-combobox>
                  </v-col>
                   <v-col cols="12">

                    UBIGEO: <span>{{pedido_address.ag_district?pedido_address.ag_district.id:''}}</span><br>
                  </v-col>
                  <v-col cols="12" sm="6" md="12">
                    <v-combobox
                        v-model="pedido_address.agencia_ubigeo"
                        :items="ubigeo_agencias"
                        item-text="agencia.name"
                        required
                        :rules="rules.agencia"
                        label="Agencia"
                    >
                      <template v-slot:no-data>
                        <v-list-item>
                          <v-list-item-content>
                            <v-list-item-title>
                              Por favor seleccione un distrito
                            </v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                      </template>
                    </v-combobox>
                  </v-col>

                  <v-col cols="12">
                    Origen: <span>{{pedido_address.agencia_ubigeo?pedido_address.agencia_ubigeo.agencia.origen:''}}</span>
                    <br>
                    Destino: <span>{{pedido_address.agencia_ubigeo?pedido_address.agencia_ubigeo.destino:''}}</span>
                  </v-col>

                </v-row>
              </v-container>
            </v-form>
            <small>*requerido</small>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="dialog = false">
              Cerrar
            </v-btn>
            <v-btn
                :disabled="!valid"
                color="blue darken-1"
                text
                @click="dialog = false"
            >
              Guardar
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-col>
  </v-row>
</template>

<script>
// @ is an alias to /src

import ubigeoService from "@/services/ubigeoService";

export default {
  props: ["pedido_address", "departments", "provinces", "districts","ubigeo_agencias", "disabled"],
  name: "PedidoAddressAgencia",
  components: {},
  watch: {
      disabled: function (newValue, oldValue) {
          this.disabledData = newValue;
      }
    // pedido_address: {
    //   handler: function(newValue, oldValue) {
    //     console.log(newValue,oldValue)
    //     console.log("department_id" + newValue.department_id + " modified")
    //     console.log("New province_id: " + newValue.province_id)
    //   },
    //   deep: true
    // }
  },
  data: () => ({
    valid: false,
    dialog: false,
    // ubigeo_agencias: [],
    rules: {
      document_number: [(v) => !!v || "DNI es requerido"],
      province: [(v) => !!v || "Provincia es requerido"],
      department: [(v) => !!v || "Departamento es requerido"],
      district: [(v) => !!v || "Distrito es requerido"],
      agencia: [(v) => !!v || "Agencia es requerido"],
    },
      disabledData: true
  }),
  mounted() {
      if (this.disabled != undefined) {
          this.disabledData = this.disabled;
      }
    // if (this.pedido_address.department_id) {
    //   console.log('provinces',this.provinces.length)
    //   if (this.provinces && this.provinces.length > 0) {
    //   } else {
    //     this.getProvinces();
    //   }
    // }
    // if (this.pedido_address.province_id) {
    //   if (this.districts && this.districts.length > 0) {
    //   } else {
    //     this.getDistricts();
    //   }
    // }
    // if (this.pedido_address.district_id) {
    //   if (this.agencia_ubigeos && this.agencia_ubigeos.length > 0) {
    //   } else {
    //     this.getAgencias();
    //   }
    // }
  },
  methods: {
    getProvinces() {
      let department_id = this.pedido_address.ag_department;
      if (typeof this.pedido_address.ag_department === "object")
        department_id = this.pedido_address.ag_department.id;
      ubigeoService.getProvincesAgencia(department_id).then((response) => {
        //this.$set(this.provinces,null, response.data);
        this.provinces = response.data;
        this.pedido_address.ag_province = "";
        this.pedido_address.ag_district = "";
      });
    },
    getDistricts() {
      let province_id = this.pedido_address.ag_province;
      if (typeof this.pedido_address.ag_province === "object")
        province_id = this.pedido_address.ag_province.id;
      ubigeoService.getDistrictsAgencia(province_id).then((response) => {
        this.districts = response.data;
        this.pedido_address.ag_district = "";
      });
    },
    getAgencias() {
      let district_id = this.pedido_address.ag_district;
      if (typeof this.pedido_address.ag_district === "object")
        district_id = this.pedido_address.ag_district.id;
      ubigeoService.getUbigeoAgencias(district_id).then((response) => {
        this.ubigeo_agencias = response.data;
        this.pedido_address.agencia_ubigeo = "";
      });
    },
    // setDistrict(){
    //   console.log(this.pedido_address.district)
    // }
  },
};
</script>
