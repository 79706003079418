import axios from "axios";

const ENDPOINT_PATH = process.env.VUE_APP_BASE_URL;

export default {
    datatable(data) {
        return axios.post(`${ENDPOINT_PATH}/businesses/datatable`, data);
    },
    index() {
        return axios.get(`${ENDPOINT_PATH}/businesses`);
    },
    store(data) {
        return axios.post(`${ENDPOINT_PATH}/businesses`, data);
    },
    show(id) {
        return axios.get(`${ENDPOINT_PATH}/businesses/${id}`);
    },
    update(data) {
        return axios.put(`${ENDPOINT_PATH}/businesses/${data.id}`, data);
    },
    destroy(id) {
        return axios.delete(`${ENDPOINT_PATH}/businesses/${id}`, null);
    },
};
