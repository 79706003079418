<template>
  <div>
    <Breadcrumbs :items="breadcrumbs"/>
    <div class="my-4"><h3 class="ml-5"><v-icon>mdi-chevron-left</v-icon> Agregar usuario</h3></div>
    <v-card>
      <UserForm :user="user"/>
    </v-card>
  </div>
</template>

<script>
// @ is an alias to /src
import Breadcrumbs from "@/components/layout/Breadcrumbs";
import UserForm from "@/components/user/UserForm";

export default {
  name: 'UserCreate',
  components: {
    Breadcrumbs,
    UserForm
  },
  data: () => ({
    breadcrumbs: [
      {text: 'Inicio', disabled: false, href: '/',},
      {text: 'Usuarios', disabled: false, href: '/users',},
      {text: 'Crear Usuario', disabled: true, href: '/users/create',}
    ],
    user: {
      name : '',
      email : '',
      password : '',
      phone : '',
      role : '',
      name_code : '',
      staff_code : '',
      address : '',
      lista_precio : null,
    }
  }),
}
</script>
