<template>
  <div>
    <v-row>
      <v-col>
        <v-card
            elevation="2"
        >
          <v-row>
            <v-col cols="3">
              <v-carousel v-if="images_base64">
                <v-carousel-item
                    v-for="(image,i) in images_base64"
                    :key="i"
                    :src="image"
                    :height="300"
                    reverse-transition="fade-transition"
                    transition="fade-transition"
                ></v-carousel-item>
              </v-carousel>
              <v-carousel v-if="images_url">
                <v-carousel-item
                    v-for="(image,i) in images_url"
                    :key="i"
                    :src="image.url"
                    :height="300"
                    reverse-transition="fade-transition"
                    transition="fade-transition"
                ></v-carousel-item>
              </v-carousel>

            </v-col>
            <v-col>
              <v-simple-table>
                <template v-slot:default>
                  <thead>
                  <tr>
                    <th class="text-left">
                      ID RPT
                    </th>
                    <th class="text-left">
                      Fecha de creación
                    </th>
                    <th class="text-left">
                      Estado tracking
                    </th>
                    <th class="text-left">
                      Nombre Sede
                    </th>
                    <th class="text-left">
                      Observación
                    </th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr
                      v-for="item in tracking"
                      :key="item.id_rpt_envio_ruta"
                  >
                    <td>{{ item.id_rpt_envio_ruta }}</td>
                    <td>{{ item.fecha_creacion }}</td>
                    <td>{{ item.estado_tracking }}</td>
                    <td>{{ item.nombre_sede }}</td>
                    <td>{{ item.obs }}</td>
                  </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
// @ is an alias to /src


import pedidoService from "@/services/pedidoService";

export default {
  props: ["pedido"],
  name: 'PedidoLog',
  components: {},
  mounted() {
    if(this.pedido.id && this.pedido.envio_emision && this.pedido.envio_codigo){
      this.getDataTracking();
      
    }
  },
    watch: {
        pedido: {
            handler(val){
                if(!this.pedidoId) {
                    this.pedido = val;
                    this.getDataTracking(this.pedido.id);
                }
            },
            deep: true
        }
    },
  data: () => ({
    tracking : [],
    model:0,
    images_base64 : null,
    images_url : null,
      pedidoId: null,
      id_envio:null
  }),
  methods: {
    getDataTracking(){
      pedidoService.getDataTrackingOlva(this.pedido.envio_emision,this.pedido.envio_codigo).then(response=> {
        if(response.status === 200){
          if(response.data.success){
            this.tracking = response.data.data.details;
            this.id_envio = response.data.data.general.id_envio;
            this.getImagenOlva();
          }
        }
      })
    },
    getImagenOlva(){
      pedidoService.getImageOlva(this.pedido.envio_emision,this.pedido.envio_codigo).then(response=> {
        console.log(response);
        if(response.status === 200){
          if(response.data.success){
            this.images_base64 = response.data.main;
          }
        }
      });
      if(this.id_envio){
        pedidoService.getImageOlva2(this.id_envio).then(response=> {
          if(response.status === 200){
            if(response.data.success){
              this.images_url = response.data.data;
            }
          }
        })
      }
    }
  }
}
</script>
