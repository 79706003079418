<template>
    <div>
        <v-snackbar v-model="snackbar" :timeout="2000">
            {{ textError }}
            <template v-slot:action="{ attrs }">
                <v-btn color="red" text v-bind="attrs" @click="snackbar = false">
                    Cerrar
                </v-btn>
            </template>
        </v-snackbar>
        <v-form v-model="valid" v-on:submit="submit">
            <v-container>
                <v-row>
                    <v-col cols="12" md="6">
                    <h4 class="mb-3">Posición</h4>
                        <v-text-field
                                v-model="banner.position"
                                :rules="rules.name"
                                label="Posición"
                                type="number"
                                required
                                outlined
                                solo
                        ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="6">
                    <h4 class="mb-3">Foto (1458x640px)</h4>
                        <v-file-input
                                accept="image/*"
                                @change="handleImage($event)"
                                label="Foto (1458x640px)"
                                outlined
                                solo
                        ></v-file-input>

                    </v-col>
                </v-row>
                <v-row v-if="banner.photo_url">
                    <v-col cols="12" md="12">
                        <div>
                            <img class="img_detail" :src="banner.photo_url" alt="">
                        </div>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12" md="4">
                        <v-btn :disabled="!valid" color="success" class="mr-4" @click="submit" v-if="!banner.id">
                            Guardar
                        </v-btn>
                        <v-btn :disabled="!valid" color="success" class="mr-4" @click="submitUpdate" v-if="banner.id">
                            Actualizar
                        </v-btn>
                    </v-col>
                </v-row>
            </v-container>
        </v-form>
    </div>
</template>


<script>
    import bannerService from "@/services/bannerService";
    import productService from "@/services/productService";

    export default {
        name: "BannerForm",
        props: ["banner"],
        data: () => ({
            valid: false,
            alert: false,
            rules: {
                name: [
                    v => !!v || 'Nombre es requerido',
                ],
            },
            snackbar: false,
            textError: '',
        }),
        mounted() {
        },
        methods: {
            submit() {
                if (!this.banner.photo) {
                    this.textError = 'Adjuntar foto';
                    this.snackbar = true;
                    return;
                }
                if (!this.banner.photo_url) {
                    this.textError = 'Adjuntar foto';
                    this.snackbar = true;
                    return;
                }
                bannerService.store(this.banner).then(response => {
                    this.$router.push({path: '/banners'})
                }, (error, data) => {
                    this.textError = error.response.data.message;
                    this.snackbar = true;
                });
            },
            submitUpdate() {
                if (!this.banner.photo) {
                    this.textError = 'Adjuntar foto';
                    this.snackbar = true;
                    return;
                }
                if (!this.banner.photo_url) {
                    this.textError = 'Adjuntar foto';
                    this.snackbar = true;
                    return;
                }
                bannerService.update(this.banner).then(response => {
                    this.$router.push({path: '/banners'})
                }, error => {
                    this.textError = error.response.data.message;
                    this.snackbar = true;
                });
            },
            handleImage(fileObject) {
                const reader = new FileReader;
                reader.onload = e => {
                    productService.uploadPhoto(e.target.result).then(response => {
                        this.banner.photo = response.data.fileName;
                        this.banner.photo_url = response.data.url;
                    }, error => {
                        this.textError = error.response.data.message;
                        this.snackbar = true;
                    })
                };
                reader.readAsDataURL(fileObject);
            }
        }
    }
</script>

<style scoped>

</style>
