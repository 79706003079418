<template>
  <div>
    <v-row>
      <v-col>
        <v-card
            elevation="2"
        >
          <v-simple-table>
            <template v-slot:default>
              <thead>
              <tr>
                <th class="text-left">
                  User
                </th>
                <th class="text-left">
                  Acción
                </th>
                <th class="text-left">
                  Anterior estado
                </th>
                <th class="text-left">
                  Nuevo estado
                </th>
                <th class="text-left">
                  Fecha
                </th>
              </tr>
              </thead>
              <tbody>
              <tr
                  v-for="item in tracking"
                  :key="item.id"
              >
                <td>{{ item.user.name }}</td>
                <td>{{ item.description }}</td>
                <td>{{ item.old_status_nice }}</td>
                <td>{{ item.new_status_nice }}</td>
                <td>{{ item.fecha }}</td>
              </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
// @ is an alias to /src


import pedidoService from "@/services/pedidoService";

export default {
  props: ["pedido"],
  name: 'PedidoLog',
  components: {},
  mounted() {
    if(this.pedido.id){
      this.getDataTracking(this.pedido.id);
    }
  },
    watch: {
        pedido: {
            handler(val){
                if(!this.pedidoId) {
                    this.pedido = val;
                    this.getDataTracking(this.pedido.id);
                }
            },
            deep: true
        }
    },
  data: () => ({
    tracking : [],
      pedidoId: null
  }),
  methods: {
    getDataTracking(pedido_id){
      pedidoService.getDataTracking(pedido_id).then(response=> {
        this.tracking = response.data;
      })
    }
  }
}
</script>
