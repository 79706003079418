<template>
  <div>
    aveces
  </div>
</template>

<script>
// @ is an alias to /src


export default {
  name: 'DetallePedido',
  components: {}
}
</script>
