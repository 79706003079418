import axios from "axios";

const ENDPOINT_PATH = process.env.VUE_APP_BASE_URL;

export default {
    bodegasDatatable(data) {
        return axios.post(`${ENDPOINT_PATH}/bodegas/datatable`, data);
    },
    uploadPhoto(image_base64) {
        const file = {file : image_base64};
        return axios.post(`${ENDPOINT_PATH}/bodegas/upload-photo`, file);
    },
    storeBodega(data) {
        return axios.post(`${ENDPOINT_PATH}/bodegas`, data);
    },
    deleteBodega(bodega_id) {
        return axios.post(`${ENDPOINT_PATH}/bodegas/${bodega_id}/delete`, null);
    },
    updateBodega(data) {
        return axios.put(`${ENDPOINT_PATH}/bodegas/${data.id}`, data);
    },
    getBodega(id) {
        return axios.get(`${ENDPOINT_PATH}/bodegas/${id}`);
    },
};
