<template>
    <div>
        <Breadcrumbs :items="breadcrumbs"/>
        <v-card>
            <v-data-table
                    :headers="headers"
                    :items="coupons"
                    :options.sync="options"
                    :server-items-length="totalCoupons"
                    :loading="loading"
                    :items-per-page="25"
                    class="elevation-1">
                <template v-slot:top>
                    <v-toolbar flat
                               class="py-4">
                        <v-dialog v-model="dialogDelete" max-width="500px">
                            <v-card>
                                <v-card-title class="headline">Estás seguro de eliminar este cupón?
                                </v-card-title>
                                <v-card-actions>
                                    <v-spacer></v-spacer>
                                    <v-btn color="blue darken-1" text @click="closeDelete">Cancelar</v-btn>
                                    <v-btn color="blue darken-1" text @click="deleteItemConfirm">Sí, estoy
                                        seguro
                                    </v-btn>
                                    <v-spacer></v-spacer>
                                </v-card-actions>
                            </v-card>
                        </v-dialog>
                        <v-toolbar-title>Cupones</v-toolbar-title>
                        <v-spacer></v-spacer>
                        <v-form>
                            <v-text-field
                                    outlined
                                    v-model="search_coupon"
                                    label="Buscar Cupón"
                                    prepend-inner-icon="mdi-magnify"
                                    dense
                                    class="mr-3  input-saco"
                            ></v-text-field>
                        </v-form>
                        <v-btn dark
                               class="btn-green" to="/coupons/create">
                            <v-icon left>
                                mdi-plus
                            </v-icon>
                            Agregar Cupón
                        </v-btn>
                    </v-toolbar>
                </template>
                <template v-slot:item.actions="{ item }">
                    <v-btn icon :to="'/coupons/edit/' + item.id">
                        <v-icon small class="mr-2">
                            mdi-square-edit-outline
                        </v-icon>
                    </v-btn>
                    <v-icon
                            small
                            @click="deleteItem(item.id)"
                    >
                        mdi-trash-can-outline
                    </v-icon>
                </template>
                <template v-slot:item.expire_in="{ item }">
                    {{item.expire_in | date}}
                </template>
            </v-data-table>
        </v-card>
    </div>
</template>

<script>
    // @ is an alias to /src
    import Breadcrumbs from "@/components/layout/Breadcrumbs";
    import couponService from "@/services/couponService";

    export default {
        name: 'Coupons',
        components: {
            Breadcrumbs,
        },
        data: () => ({
            breadcrumbs: [
                {text: 'Inicio', disabled: false, href: '/',},
                {text: 'Cupones', disabled: true, href: '/coupons',}
            ],
            search_coupon: '',
            totalCoupons: 0,
            coupons: [],
            loading: true,
            dialogDelete: false,
            options: {query: {coupon: ''}},
            editedIndex: null,
            headers: [
                {
                    text: 'ID',
                    align: 'start',
                    sortable: false,
                    value: 'id',
                },
                {
                    text: 'Cupón',
                    sortable: false,
                    value: 'code',
                },
                {
                    text: 'Descripción',
                    sortable: false,
                    value: 'description',
                },
                {
                    text: 'Monto',
                    sortable: false,
                    value: 'amount',
                },
                {
                    text: 'Expira en',
                    sortable: false,
                    value: 'expire_in',
                },
                {
                    text: 'Máximo numero de usos',
                    sortable: false,
                    value: 'max_number_exchanges',
                },
                {
                    text: 'Número de canjes realizados',
                    sortable: false,
                    value: 'number_exchanges',
                },
                {
                    text: 'Estado',
                    sortable: false,
                    value: 'status_nice',
                },
                {text: 'Acciones', value: 'actions', sortable: false},
            ],
        }),
        watch: {
            params: {
                handler() {
                    this.getDataFromApi()
                },
                deep: true,
            },
        },
        computed: {
            params(nv) {
                this.options.query.coupon = this.search_coupon;
            }
        },
        mounted() {
            this.getDataFromApi()
        },
        methods: {
            editItem(item) {
            },
            deleteItem(item) {
                this.dialogDelete = true;
                this.editedIndex = item;
            },
            deleteItemConfirm() {
                couponService.destroy(this.editedIndex).then(result => {
                    this.getDataFromApi();
                    this.closeDelete();
                })
            },
            closeDelete() {
                this.dialogDelete = false
            },
            getDataFromApi() {
                this.loading = true;
                couponService.datatable(this.options).then(response => {
                    // console.log('data_api',response);
                    this.coupons = response.data.items;
                    this.totalCoupons = response.data.total;
                    this.loading = false;
                });
            },

        },
    }
</script>
