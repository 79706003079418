<template>
    <div>
        <Breadcrumbs :items="breadcrumbs" />
        <v-card>
            <v-form v-model="valid" v-on:submit="submit">
                <v-container>
                    <v-row>
                        <v-col cols="12" md="4">
                            <v-text-field v-model="business.name" :rules="rules.name" label="Nombre" required>
                            </v-text-field>
                        </v-col>
                        <v-col cols="12" md="4">
                            <v-combobox v-model="business.client_type" :items="client_types" @change="setDocumentLength"
                                label="Tipo de documento"></v-combobox>
                        </v-col>
                        <v-col cols="12" md="4">
                            <v-text-field v-model="business.clientid" label="RUC" v-on:minlength="documentLength"
                                v-on:maxlength="documentLength" v-on:keypress="isLetterOrNumber($event)"></v-text-field>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col cols="12" md="4">
                            <v-combobox v-model="business.department_id" :items="departments" item-text="name"
                                label="Departamento" @change="getProvinces">
                            </v-combobox>
                        </v-col>
                        <v-col cols="12" md="4">
                            <v-combobox v-model="business.province_id" :items="provinces" item-text="name"
                                label="Provincia" @change="getDistricts">
                                <template v-slot:no-data>
                                    <v-list-item>
                                        <v-list-item-content>
                                            <v-list-item-title>
                                                Por favor seleccione un departamento
                                            </v-list-item-title>
                                        </v-list-item-content>
                                    </v-list-item>
                                </template>
                            </v-combobox>
                        </v-col>
                        <v-col cols="12" md="4">
                            <v-combobox v-model="business.district" :items="districts" item-text="name" label="Distrito"
                                v-on:change="setPrice">
                                <template v-slot:no-data>
                                    <v-list-item>
                                        <v-list-item-content>
                                            <v-list-item-title>Por favor seleccione una provincia
                                            </v-list-item-title>
                                        </v-list-item-content>
                                    </v-list-item>
                                </template>
                            </v-combobox>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col cols="12" md="4">
                            <v-text-field v-model="business.address" :rules="rules.address" label="Dirección" required>
                            </v-text-field>
                        </v-col>
                        <v-col cols="12" md="4">
                            <v-text-field v-model="price" label="Zona - Precio" readonly></v-text-field>
                        </v-col>
                        <v-col cols="12" md="4">
                            <v-combobox v-model="business.lista_precio" :items="listas" item-text="name"
                                item-value="name" label="Lista de precio"></v-combobox>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col cols="12" md="4">
                            <h4 class="mb-3">Correo</h4>
                            <v-text-field v-model="business.email" label="Correo"></v-text-field>
                        </v-col>
                        <v-col cols="12" md="4">
                            <h4 class="mb-3">Contacto</h4>
                            <v-text-field v-model="business.contacto" label="Contacto"></v-text-field>
                        </v-col>
                        <v-col cols="12" md="4">
                            <h4 class="mb-3">Número celular</h4>
                            <v-text-field v-model="business.phone" label="Número celular"></v-text-field>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col>
                            <v-card>
                                <v-simple-table>
                                    <template v-slot:default>
                                        <thead>
                                            <tr>
                                                <th>Shop Code</th>
                                                <th>Shop Name</th>
                                                <th>Dirección</th>
                                                <th>Acciones</th>
                                                <th>
                                                    <v-btn small color="info" @click="addStore">
                                                        Agregar Tienda
                                                    </v-btn>
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <template v-for="(item, key) in business.business_stores">
                                                <tr>
                                                    <td>
                                                        <v-text-field label="Shop Code"
                                                            v-model="business.business_stores[key].code"></v-text-field>
                                                    </td>
                                                    <td>
                                                        <v-text-field label="Shop Name"
                                                            v-model="business.business_stores[key].name"></v-text-field>
                                                    </td>
                                                    <td>
                                                        <v-text-field label="Dirección"
                                                            v-model="business.business_stores[key].address">
                                                        </v-text-field>
                                                    </td>
                                                    <td>
                                                        <v-icon @click="deleteStore(key)">
                                                            mdi-delete
                                                        </v-icon>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <v-combobox
                                                            v-model="business.business_stores[key].department_id"
                                                            :items="departments" item-text="name" label="Departamento"
                                                            @change="getProvinces(key)">
                                                        </v-combobox>
                                                    </td>
                                                    <td>
                                                        <v-combobox v-model="business.business_stores[key].province_id"
                                                            :items="stores_ubigeo[key].provinces" item-text="name"
                                                            label="Provincia" @change="getDistricts(key)">
                                                            <template v-slot:no-data>
                                                                <v-list-item>
                                                                    <v-list-item-content>
                                                                        <v-list-item-title>
                                                                            Por favor seleccione un departamento
                                                                        </v-list-item-title>
                                                                    </v-list-item-content>
                                                                </v-list-item>
                                                            </template>
                                                        </v-combobox>
                                                    </td>
                                                    <td>
                                                        <v-combobox v-model="business.business_stores[key].district"
                                                            :items="stores_ubigeo[key].districts" item-text="name"
                                                            label="Distrito">
                                                            <template v-slot:no-data>
                                                                <v-list-item>
                                                                    <v-list-item-content>
                                                                        <v-list-item-title>Por favor seleccione una
                                                                            provincia</v-list-item-title>
                                                                    </v-list-item-content>
                                                                </v-list-item>
                                                            </template>
                                                        </v-combobox>
                                                    </td>
                                                </tr>
                                                <hr />
                                            </template>
                                        </tbody>
                                    </template>
                                </v-simple-table>
                            </v-card>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col cols="12" md="4">
                            <v-btn :disabled="!valid" color="success" class="mr-4" @click="submit">
                                Actualizar
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-container>
            </v-form>
        </v-card>
        <v-overlay :value="overlay">
            <v-progress-circular indeterminate color="primary" size="64"></v-progress-circular>
        </v-overlay>
    </div>
</template>

<script>
import Breadcrumbs from "@/components/layout/Breadcrumbs";
import businessService from "@/services/businessService";
import ubigeoService from "@/services/ubigeoService";
import listaService from "@/services/listaService";

export default {
    name: "edit.vue",
    components: {
        Breadcrumbs,
    },
    data: () => ({
        overlay: true,
        breadcrumbs: [
            { text: "Inicio", disabled: false, href: "/" },
            { text: "Editar Cliente Saco", disabled: false, href: "/businesses" },
        ],
        loader: null,
        price: null,
        valid: false,
        departments: [],
        provinces: [],
        districts: [],
        listas: [],
        documentLength: 8,
        client_types: ["RUC", "DNI"],
        stores_ubigeo: [
            { departments: [], provinces: [], districts: [] },
            { departments: [], provinces: [], districts: [] },
            { departments: [], provinces: [], districts: [] },
            { departments: [], provinces: [], districts: [] },
            { departments: [], provinces: [], districts: [] },
            { departments: [], provinces: [], districts: [] },
            { departments: [], provinces: [], districts: [] },
            { departments: [], provinces: [], districts: [] },
            { departments: [], provinces: [], districts: [] },
            { departments: [], provinces: [], districts: [] },
            { departments: [], provinces: [], districts: [] },
            { departments: [], provinces: [], districts: [] },
            { departments: [], provinces: [], districts: [] },
            { departments: [], provinces: [], districts: [] },
            { departments: [], provinces: [], districts: [] },
            { departments: [], provinces: [], districts: [] },
            { departments: [], provinces: [], districts: [] },
            { departments: [], provinces: [], districts: [] },
            { departments: [], provinces: [], districts: [] },
            { departments: [], provinces: [], districts: [] },
            { departments: [], provinces: [], districts: [] },
            { departments: [], provinces: [], districts: [] },
            { departments: [], provinces: [], districts: [] },
            { departments: [], provinces: [], districts: [] },
            { departments: [], provinces: [], districts: [] },
            { departments: [], provinces: [], districts: [] },
            { departments: [], provinces: [], districts: [] },
            { departments: [], provinces: [], districts: [] },
            { departments: [], provinces: [], districts: [] },
            { departments: [], provinces: [], districts: [] },
            { departments: [], provinces: [], districts: [] },
            { departments: [], provinces: [], districts: [] },
            { departments: [], provinces: [], districts: [] },
            { departments: [], provinces: [], districts: [] },
        ],
        business: {
            name: "",
            client_type: "RUC",
            clientid: "",
            department_id: "",
            province_id: "",
            district_id: "",
            district: null,
            address: "",
            postal_code: "",
            lista_precio: null,
            business_stores: [],
        },
        rules: {
            code: [(v) => !!v || "Código es requerido"],
            name: [(v) => !!v || "Nombre es requerido"],
            address: [(v) => !!v || "Dirección es requerido"],
        },
    }),
    mounted() {
        this.breadcrumbs.push({
            text: "Editar Empresa Partner",
            disabled: true,
            href: "/businesses/edit/" + this.$route.params.id,
        });
        this.getListas();


        // console.log(this.$route.params.id);
    },
    methods: {
        getListas() {
            listaService.getListas().then((response) => {
                if (response.status === 200) {
                    this.listas = response.data;
                }

                businessService.show(this.$route.params.id).then((responseBusiness) => {
                    this.departments = responseBusiness.data.data.departments;
                    this.provinces = responseBusiness.data.data.provinces;
                    this.districts = responseBusiness.data.data.districts;
                    this.business = responseBusiness.data.data.data;
                    console.log(this.business)
                    //this.business = responseBusiness.data.data.data.business_stores;
                    //this.$set(this.business, 'business_stores', []);
                    //this.$set(this.business, 'business_stores', responseBusiness.data.data.data.business_stores);

                    this.business.department_id = this.departments.find(
                        (items) => items.id === this.business.department_id
                    );
                    this.business.province_id = this.provinces.find(
                        (items) => items.id === this.business.province_id
                    );
                    this.business.district = this.districts.find(
                        (items) => items.id === this.business.district_id
                    );
                    this.business.lista_precio = this.listas.find(
                        (items) => items.id === this.business.lista_precio_id
                    );
                    this.setPrice();
                    this.setDocumentLength();
                    let index = 0;
                    responseBusiness.data.data.data.business_stores.forEach((store) => {
                        if (this.stores_ubigeo.length < index + 1) {
                            this.stores_ubigeo.push({
                                departments: [],
                                provinces: [],
                                districts: [],
                            });
                        }
                        console.log('ss',this.stores_ubigeo);
                        this.stores_ubigeo[index].departments =
                            responseBusiness.data.data.departments;
                        this.stores_ubigeo[index].provinces =
                            responseBusiness.data.data.stores_ubigeo[index].provinces;
                        this.stores_ubigeo[index].districts =
                            responseBusiness.data.data.stores_ubigeo[index].districts;

console.log('st',this.business.business_stores);
                        this.business.business_stores[index].department_id =
                            this.departments.find(
                                (items) =>
                                    items.id === this.business.business_stores[index].department_id
                            );
                        this.business.business_stores[index].province_id = this.stores_ubigeo[
                            index
                        ].provinces.find(
                            (items) => items.id === this.business.business_stores[index].province_id
                        );
                        this.business.business_stores[index].district = this.stores_ubigeo[
                            index
                        ].districts.find(
                            (items) => items.id === this.business.business_stores[index].district_id
                        );
                        index = index + 1;
                    });
                    this.overlay = false;
                }).catch((error) => {
                    console.error("error", error);
                    this.$router.push({ path: "/businesses" });
                });
            });
        },
        submit() {
            businessService.update(this.business).then((response) => {
                this.$router.push({ path: "/businesses" });
            }).catch((error) => {
                console.error("error", error);
            });
        },
        getDepartments() {
            ubigeoService.getDepartments().then((response) => {
                this.departments = response.data;
            });
        },
        getProvinces(key) {
            if (!isNaN(key)) {
                let department_id = this.business.business_stores[key].department_id;
                department_id = this.business.business_stores[key].department_id.id;
                ubigeoService.getProvinces(department_id).then((response) => {
                    this.stores_ubigeo[key].provinces = response.data;
                    console.log(this.stores_ubigeo);
                });
                return;
            }
            ubigeoService.getProvinces(this.business.department_id.id).then((response) => {
                this.provinces = response.data;
            });
        },
        getDistricts(key) {
            if (!isNaN(key)) {
                let province_id = this.business.business_stores[key].province_id;
                province_id = this.business.business_stores[key].province_id.id;
                ubigeoService.getDistricts(province_id).then((response) => {
                    this.stores_ubigeo[key].districts = response.data;
                });
                return;
            }
            ubigeoService.getDistricts(this.business.province_id.id).then((response) => {
                this.districts = response.data;
            });
        },
        setPrice() {
            this.price = this.business.district
                ? this.business.district.zona + " -  S/" + this.business.district.price
                : "";
        },
        isLetterOrNumber(e) {
            let char = String.fromCharCode(e.keyCode);
            if (/^[0-9]+$/.test(char)) {
                if (this.business.clientid.length >= this.documentLength) {
                    e.preventDefault();
                } else {
                    return true;
                }
            } else {
                e.preventDefault();
            }
        },
        setDocumentLength() {
            if (this.business.client_type === "RUC") {
                this.documentLength = 11;
            } else {
                this.documentLength = 8;
            }
        },
        addStore() {
            let address_set = '';
            if (this.business.business_stores.length == 0) {
                address_set = this.business.address;
            }
            this.$set(this.business.business_stores, this.business.business_stores.length, {
                code: "",
                name: "",
                address: address_set,
                department_id: null,
                province_id: null,
                district_id: null,
            });
            if (this.stores_ubigeo.length < this.business.business_stores.length) {
                this.stores_ubigeo.push({
                    departments: [],
                    provinces: [],
                    districts: [],
                });
            }
        },
        deleteStore(key) {
            this.$delete(this.business.business_stores, key);
        },
    },
};
</script>

<style scoped>
</style>
