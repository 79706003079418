import axios from "axios";

const ENDPOINT_PATH = process.env.VUE_APP_BASE_URL;

export default {
    usersDatatable(data) {
        return axios.post(`${ENDPOINT_PATH}/users/datatable`, data);
    },
    usersWebDatatable(data) {
        return axios.post(`${ENDPOINT_PATH}/users/web/datatable`, data);
    },
    uploadPhoto(image_base64) {
        const file = {file : image_base64};
        return axios.post(`${ENDPOINT_PATH}/users/upload-photo`, file);
    },
    storeUser(data) {
        return axios.post(`${ENDPOINT_PATH}/users`, data);
    },
    updateUser(data) {
        return axios.post(`${ENDPOINT_PATH}/users/update`, data);
    },
    deleteUser(user_id) {
        return axios.post(`${ENDPOINT_PATH}/users/${user_id}/delete`, null);
    },
    getUser(user_id) {
        return axios.get(`${ENDPOINT_PATH}/users/${user_id}`);
    },
};
