<template>
  <div>
    <Breadcrumbs :items="breadcrumbs"/>
    <v-card>
      <v-data-table
          :headers="headers"
          :items="users"
          :options.sync="options"
          :server-items-length="totalUsers"
          :loading="loading"
          class="elevation-2"
      >
        <template v-slot:top>
          <v-toolbar
              flat
              class="py-4"
          >
            <v-dialog v-model="dialogDelete" max-width="500px">
              <v-card>
                <v-card-title class="headline">Estás seguro de eliminar este cliente?
                </v-card-title>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="blue darken-1" text @click="closeDelete">Cancelar</v-btn>
                  <v-btn color="blue darken-1" text @click="deleteItemConfirm">Sí, estoy
                    seguro
                  </v-btn>
                  <v-spacer></v-spacer>
                </v-card-actions>
              </v-card>
            </v-dialog>
            <v-toolbar-title>Usuarios</v-toolbar-title>
            <v-spacer></v-spacer>
<!--             <v-form>
              <v-text-field
                outlined
                label="Buscar usuario"
                prepend-inner-icon="mdi-magnify"
                dense
                class="mr-3 input-saco"
              ></v-text-field>
            </v-form> -->
            <v-btn
                dark
                class="btn-green"
                to="/users/create"
              >      
              <v-icon left>
                mdi-plus
              </v-icon>
                <v-icon left>fas fa-search</v-icon>
                Agregar usuario
            </v-btn>
          </v-toolbar>
        </template>
        <template v-slot:item.actions="{ item }">
          <v-btn icon :to="'/users/edit/'+item.id">
            <v-icon
                small
                class="mr-2"
            >
              mdi-square-edit-outline
            </v-icon>
          </v-btn>
          <v-icon
              small
              @click="deleteItem(item.id)"
              v-if="userSession.role==='admin'"
          >
            mdi-trash-can-outline
          </v-icon>
        </template>
        <template v-slot:item.image_url="{ item }">
          <div class="p-2 mt-1 mb-1">
            <v-img :src="item.image_url" :alt="item.user.name" width="70px"></v-img>
          </div>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
// @ is an alias to /src
import Breadcrumbs from "@/components/layout/Breadcrumbs";
import userService from "@/services/userService";
import pedidoService from "@/services/pedidoService";

export default {
  computed: {
    userSession: function () {
      return this.$store.getters.userSession
    },
  },
  name: 'Example',
  components: {
    Breadcrumbs,
  },
  data: () => ({
    breadcrumbs: [
      {text: 'Inicio', disabled: false, href: '/',},
      {text: 'Users', disabled: true, href: '/users',}
    ],
    dialogDelete: false,
    totalUsers: 0,
    users: [],
    loading: true,
    editedIndex: null,
    options: {},
    headers: [
      {
        text: 'Código',
        align: 'start',
        sortable: false,
        value: 'id',
      },
      {
        text: 'Nombre',
        sortable: false,
        value: 'name',
      },
      {
        text: 'Email',
        sortable: false,
        value: 'email',
      },
      {
        text: 'Rol de usuario',
        sortable: false,
        value: 'role',
      },
      { text: 'Actions', value: 'actions', sortable: false },
    ],
  }),
  watch: {
    options: {
      handler () {
        this.getDataFromApi()
      },
      deep: true,
    },
  },
  mounted () {
    this.getDataFromApi()
  },
  methods: {
    editItem (item) {
      console.log(item);
    },
    deleteItem(item) {
      console.log(item)
      this.dialogDelete = true
      this.editedIndex = item
    },
    deleteItemConfirm() {
      userService.deleteUser(this.editedIndex).then(result => {
        this.getDataFromApi()
        this.closeDelete()
      })
    },
    closeDelete() {
      this.dialogDelete = false
    },
    getDataFromApi () {
      this.loading = true
      userService.usersDatatable(this.options).then(response => {
        console.log('data_api',response);
        this.users = response.data.items
        this.totalUsers = response.data.total
        this.loading = false
      })
    },
  },
}
</script>
