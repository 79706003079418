<template>
  <div>
    <Breadcrumbs :items="breadcrumbs"/>
        <div class="my-4"><h3 class="ml-5"><v-icon>mdi-chevron-left</v-icon>Agregar lista de precios</h3></div>
    <v-card>
      <ListaForm :lista="lista"/>
    </v-card>
  </div>
</template>

<script>
// @ is an alias to /src
import Breadcrumbs from "@/components/layout/Breadcrumbs";
import ListaForm from "@/components/lista/ListaForm";

export default {
  name: 'Example',
  components: {
    Breadcrumbs,
    ListaForm
  },
  data: () => ({
    breadcrumbs: [
      {text: 'Inicio', disabled: false, href: '/',},
      {text: 'Listas', disabled: false, href: '/listas',},
      {text: 'Crear Lista', disabled: true, href: '/listas/create',}
    ],
    lista: {
      name: '',
      description: '',
      id_flexline: '',
      name_flexline: '',
      fecha_vigencia: '',
      bodega_id: null,
      channel_id: null,
      subchannel_id: null,
      rangos: 0,
      lista_precio_range:[]
    }
  }),
}
</script>
