<template>
    <div>
        <Breadcrumbs :items="breadcrumbs"/>
        <v-card>
            <ChannelForm :channel="channel"/>
        </v-card>
    </div>
</template>

<script>
    // @ is an alias to /src
    import Breadcrumbs from "@/components/layout/Breadcrumbs";
    import ChannelForm from "@/components/channel/ChannelForm";
    import channelService from "@/services/channelService";

    export default {
        name: 'ChannelEdit',
        components: {
            Breadcrumbs,
            ChannelForm
        },
        data: () => ({
            breadcrumbs: [
                {text: 'Inicio', disabled: false, href: '/',},
                {text: 'Canales', disabled: false, href: '/channels',},
                {text: 'Editar Canal', disabled: true, href: '/channels/edit',}
            ],
            channel: {
                name: '',
            }
        }),
        mounted() {
            this.getData(this.$route.params.id);
        },
        methods: {
            getData(id) {
                channelService.show(id).then(response => {
                    this.channel = response.data.data;
                })
            }
        }
    }
</script>
