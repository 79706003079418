<template>
    <div>
        <Breadcrumbs :items="breadcrumbs"/>
        <div class="my-4"><h3 class="ml-5"><v-icon>mdi-chevron-left</v-icon>Agregar producto</h3></div>
        <v-card>
            <ProductForm :product="product"/>
        </v-card>
    </div>
</template>

<script>
    // @ is an alias to /src
    import Breadcrumbs from "@/components/layout/Breadcrumbs";
    import ProductForm from "@/components/product/ProductForm";

    export default {
        name: 'ProductCreate',
        components: {
            Breadcrumbs,
            ProductForm
        },
        data: () => ({
            breadcrumbs: [
                {text: 'Inicio', disabled: false, href: '/',},
                {text: 'Productos', disabled: false, href: '/products',},
                {text: 'Crear Producto', disabled: true, href: '/products/create',}
            ],
            product: {
                code: '',
                name: '',
                description: '',
                unit: 'Un',
                stock: null,
                price: null,
                image: '',
                detail: false,
                marca_id: null,
                categoria_id: null,
                web_description: null,
                details: [{
                    code: '',
                    color: '',
                    capacity: '',
                    image: '',
                    image_url: 'https://via.placeholder.com/100x100',
                    codigo_saco: null,
                    comision: null,
                    stock: null,
                    price: null
                }]
            }
        }),
    }
</script>
