<template>
    <div>
        <Breadcrumbs :items="breadcrumbs"/>
        <v-card>
            <BannerForm :banner="banner"/>
        </v-card>
    </div>
</template>

<script>
    import Breadcrumbs from "@/components/layout/Breadcrumbs";
    import BannerForm from "@/components/banner/BannerForm";

    export default {
        name: 'BannerCreate',
        components: {
            Breadcrumbs,
            BannerForm
        },
        data: () => ({
            breadcrumbs: [
                {text: 'Inicio', disabled: false, href: '/',},
                {text: 'Banners', disabled: false, href: '/banners',},
                {text: 'Crear Banner', disabled: true, href: '/banners/create',}
            ],
            banner: {
                position: '',
                photo: '',
                photo_url: '',
            }
        }),
    }
</script>
