<template>
    <div>
        <Breadcrumbs :items="breadcrumbs"/>
        <v-card>
            <CategoriaForm :categoria="categoria"/>
        </v-card>
    </div>
</template>

<script>
    // @ is an alias to /src
    import Breadcrumbs from "@/components/layout/Breadcrumbs";
    import CategoriaForm from "@/components/categoria/CategoriaForm";
    import categoriaService from "@/services/categoriaService";

    export default {
        name: 'BodegaEdit',
        components: {
            Breadcrumbs,
            CategoriaForm
        },
        data: () => ({
            breadcrumbs: [
                {text: 'Inicio', disabled: false, href: '/',},
                {text: 'Categorías', disabled: false, href: '/categorias',},
                {text: 'Crear Categoría', disabled: true, href: '/categorias/create',}
            ],
            categoria: {
                nombre: '',
                descripcion: '',
            }
        }),
        mounted() {
            this.show(this.$route.params.id);
        },
        methods: {
            show(bodega_id) {
                categoriaService.show(bodega_id).then(response => {
                    this.categoria = response.data.categoria;
                })
            }
        }
    }
</script>
