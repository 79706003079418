import axios from "axios";

const ENDPOINT_PATH = process.env.VUE_APP_BASE_URL;

export default {
    clientsDatatable(data) {
        return axios.post(`${ENDPOINT_PATH}/clients/datatable`, data);
    },
    uploadPhoto(image_base64) {
        const file = {file : image_base64};
        return axios.post(`${ENDPOINT_PATH}/clients/upload-photo`, file);
    },
    storeClient(data) {
        return axios.post(`${ENDPOINT_PATH}/clients`, data);
    },
    deleteClient(client_id) {
        return axios.post(`${ENDPOINT_PATH}/clients/${client_id}/delete`, null);
    },
    updateClient(data) {
        return axios.put(`${ENDPOINT_PATH}/clients/${data.id}`, data);
    },
    getClient(id) {
        return axios.get(`${ENDPOINT_PATH}/clients/${id}`);
    },
    getClientById(id) {
        return axios.get(`${ENDPOINT_PATH}/clients/getClient/${id}`);
    },
    getClientes() {
        return axios.get(`${ENDPOINT_PATH}/clients/lista`);
    },
};
