import axios from 'axios';

const ENDPOINT_PATH = process.env.VUE_APP_BASE_URL;

export default {
    clientsDatatable(data) {
        return axios.post(`${ENDPOINT_PATH}/partner2-clients/datatable`, data);
    },
    storeClient(data) {
        return axios.post(`${ENDPOINT_PATH}/partner2-clients`, data);
    },
    getClient(id) {
        return axios.get(`${ENDPOINT_PATH}/partner2-clients/${id}`);
    },
    updateClient(data) {
        return axios.put(`${ENDPOINT_PATH}/partner2-clients/${data.id}`, data);
    },
    deleteClient(client_id) {
        return axios.post(`${ENDPOINT_PATH}/partner2-clients/${client_id}/delete`, null);
    },
}