<template>
  <div>
    <Breadcrumbs :items="breadcrumbs"/>
    <v-card>
      <BodegaForm :bodega="bodega"/>
    </v-card>
  </div>
</template>

<script>
// @ is an alias to /src
import Breadcrumbs from "@/components/layout/Breadcrumbs";
import BodegaForm from "@/components/bodega/BodegaForm";
import bodegaService from "@/services/bodegaService";

export default {
  name: 'BodegaEdit',
  components: {
    Breadcrumbs,
    BodegaForm
  },
  data: () => ({
    breadcrumbs: [
      {text: 'Inicio', disabled: false, href: '/',},
      {text: 'Bodegas', disabled: false, href: '/bodegas',},
      {text: 'Crear Bodega', disabled: true, href: '/bodegas/create',}
    ],
    bodega: {
      name: '',
      description: '',
    }
  }),
  mounted () {
    this.getDataBodega(this.$route.params.id);
  },
  methods: {
    getDataBodega(bodega_id) {
      bodegaService.getBodega(bodega_id).then(response => {        
        this.bodega = response.data.bodega;
      })
    }
  }
}
</script>
