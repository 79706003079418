<template>
    <div>
        <v-snackbar v-model="snackbar" :timeout="2000">
            {{ textError }}
            <template v-slot:action="{ attrs }">
                <v-btn
                        color="red"
                        text
                        v-bind="attrs"
                        @click="snackbar = false"
                >
                    Cerrar
                </v-btn>
            </template>
        </v-snackbar>
        <v-form v-model="valid" v-on:submit="submit">
            <PedidoTabs :pedido="pedido"></PedidoTabs>
            <v-container>
                <v-row>
                    <v-col cols="12" md="8">
                        <v-card outlined>
                            <v-list-item three-line>
                                <v-list-item-content>
                                    <div class="overline">
                                        Cliente
                                    </div>
                                    <v-list-item-subtitle>{{ pedido.user ? pedido.user.name : '' }}
                                    </v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item>
                        </v-card>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12" md="4">
                        <PedidoAddress :pedido_address="pedido.user_address"
                                       :departments="departments"
                                       :provinces="provinces"
                                       :districts="districts"
                        />
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12" md="4">
                        <v-card class="mx-auto" outlined>
                            <v-list-item three-line>
                                <v-list-item-content>
                                    <div class="overline mb-4">
                                        Comprobante
                                    </div>
                                    <v-list-item-subtitle><b>Comprobante:</b> {{pedido.sale_document_type_text}}</v-list-item-subtitle>
                                    <template v-if="pedido.sale_document_type == '01'">
                                        <v-list-item-subtitle><b>RUC:</b> {{pedido.sale_ruc}}</v-list-item-subtitle>
                                        <v-list-item-subtitle><b>Razón Social:</b> {{pedido.sale_business_name}}</v-list-item-subtitle>
                                        <v-list-item-subtitle><b>Dirección Fiscal:</b> {{pedido.sale_address}}</v-list-item-subtitle>
                                    </template>
                                </v-list-item-content>
                            </v-list-item>
                        </v-card>
                    </v-col>
                </v-row>
                <!--<v-row>
                    <v-col
                            cols="12"
                            md="8"
                    >
                         <v-autocomplete
                                 v-model="productSelect"
                                 :loading="loading"
                                 :items="products"
                                 :search-input.sync="searchProduct"
                                 item-text="name"
                                 return-object
                                 flat
                                 clearable
                                 hide-pedido_web_details
                                 hide-selected
                                 label="Productos"
                                 @change="selectProduct"
                         >
                        <template v-slot:no-data>
                            <v-list-item>
                                <v-list-item-title>
                                    Buscar por
                                    <strong>nombre</strong> o
                                    <strong>código</strong>
                                </v-list-item-title>
                            </v-list-item>
                        </template>
                        <template v-slot:selection="{ attr, on, item, selected }">
                            <v-chip
                                    v-bind="attr"
                                    :input-value="selected"
                                    color="blue-grey"
                                    class="white&#45;&#45;text"
                                    v-on="on"
                            >
                                <span v-text="item.name"></span>
                            </v-chip>
                        </template>
                        <template v-slot:item="{ item }">
                            <v-list-item-avatar
                                    color="indigo"
                                    :tile="true"
                                    class="headline font-weight-light white&#45;&#45;text"
                            >
                                <img :src="item.image_url" alt="">
                            </v-list-item-avatar>
                            <v-list-item-content>
                                <v-list-item-title v-text="item.name"></v-list-item-title>
                                <v-list-item-subtitle v-text="item.color"></v-list-item-subtitle>
                            </v-list-item-content>
                        </template>
                        </v-autocomplete>
                    </v-col>
                </v-row>-->

                <v-row>
                    <v-col>
                        <hr>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        <v-card>
                            <v-simple-table>
                                <template v-slot:top>
                                    <v-toolbar
                                            flat
                                    >
                                        <v-dialog v-model="dialogDelete" max-width="500px">
                                            <v-card>
                                                <v-card-title class="headline">Estás seguro de eliminar este ítem?
                                                </v-card-title>
                                                <v-card-actions>
                                                    <v-spacer></v-spacer>
                                                    <v-btn color="blue darken-1" text @click="closeDelete">Cancelar</v-btn>
                                                    <v-btn color="blue darken-1" text @click="deleteItemConfirm">Sí, estoy
                                                        seguro
                                                    </v-btn>
                                                    <v-spacer></v-spacer>
                                                </v-card-actions>
                                            </v-card>
                                        </v-dialog>
                                    </v-toolbar>
                                </template>
                                <template v-slot:default>
                                    <thead>
                                    <tr>
                                        <th>N°</th>
                                        <th>Foto</th>
                                        <th>Producto</th>
                                        <th>Especificaciones</th>
                                        <th>Unidad</th>
                                        <th>Cantidad</th>
                                        <th>Stock</th>
                                        <th>Stock Reservado</th>
                                        <th>Precio Unitario</th>
                                        <th>Total parcial</th>
                                        <!--                                    <th>Acciones</th>-->
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr
                                            v-for="(item,key) in pedido.pedido_web_details"
                                            :key="item.id"
                                    >
                                        <td>{{ key + 1 }}</td>
                                        <td>
                                            <v-img :src="item.product_detail.image_url" alt="" width="100"
                                                   class="mt-1 mb-1"></v-img>
                                        </td>
                                        <td>{{ item.product_detail.description }}</td>
                                        <td>
                                            <p>Color: {{ item.product_detail.color }}</p>
                                            <p>Capacidad: {{ item.product_detail.capacity }}</p>
                                        </td>
                                        <td>Un</td>
                                        <td>
                                            <v-text-field
                                                    type="number"
                                                    label="Cantidad"
                                                    v-model="pedido.pedido_web_details[key].cantidad"
                                                    @change="updateParcial(key)"
                                                    :rules=[rules.min(1,pedido.pedido_web_details[key]),rules.max(item.product_detail.stock,pedido.pedido_web_details[key])]
                                            ></v-text-field>
                                        </td>
                                        <td>{{ item.product_detail.stock }}</td>
                                        <td>{{ item.product_detail.stockreserve }}</td>
                                        <td>{{ item.price }}</td>
                                        <td>{{ item.parcial }}</td>
                                        <!--                                    <td>-->
                                        <!--                                        <v-icon-->
                                        <!--                                                small-->
                                        <!--                                                @click="deleteItem(item)"-->
                                        <!--                                        >-->
                                        <!--                                            mdi-delete-->
                                        <!--                                        </v-icon>-->
                                        <!--                                    </td>-->
                                    </tr>
                                    </tbody>
                                </template>
                            </v-simple-table>
                        </v-card>
                        <div>
                            <div class="d-flex flex-row-reverse">
                                <div class="pa-2">
                                    <v-simple-table>
                                        <template v-slot:default>
                                            <tbody>
                                            <tr>
                                                <td>Sub total:</td>
                                                <td>{{ pedido.subtotal }}</td>
                                            </tr>
                                            <!-- <tr>
                                                 <td>Monto total:</td>
                                                 <td>{{ monto_total }}</td>
                                             </tr>-->
                                            <tr>
                                                <td>Flete:</td>
                                                <td>{{ pedido.flete }}</td>
                                            </tr>
                                            <tr>
                                                <td>Descuento por cupón:</td>
                                                <td>{{ pedido.coupon_amount }}</td>
                                            </tr>
                                            <!--<tr>
                                                <td>Taxes:</td>
                                                <td>{{ taxes }}</td>
                                            </tr>-->
                                            <tr>
                                                <td>Pedido total:</td>
                                                <td>{{ pedido.total }}</td>
                                            </tr>
                                            </tbody>
                                        </template>
                                    </v-simple-table>
                                </div>
                            </div>
                        </div>
                    </v-col>
                </v-row>
                <!--<v-row v-if="false">
                    <v-col
                            cols="12"
                            md="4">
                        <v-text-field
                                v-model="pedido.razon_social"
                                label="Razón social"
                                readonly
                        ></v-text-field>
                    </v-col>
                    <v-col
                            cols="12"
                            md="4">
                        <v-text-field
                                v-model="pedido.ruc"
                                label="Razón social"
                                readonly
                        ></v-text-field>
                    </v-col>
                    &lt;!&ndash;<v-col
                            cols="12"
                            md="4">
                        <v-combobox
                                v-model="pedido.contenido_factura"
                                :items="factura_contenidos"
                                label="Contenido Factura"
                        ></v-combobox>
                    </v-col>&ndash;&gt;

                </v-row>
                <v-row v-if="false">
                    <v-col cols="12" md="4">
                        <v-text-field
                                :readonly="!edit_codigo_bancario"
                                v-model="pedido.codigo_bancario"
                                label="Código bancario"
                                counter
                                maxlength="14"
                        ></v-text-field>
                    </v-col>
                    <v-col
                            cols="12"
                            md="4">
                        <v-text-field
                                v-model="pedido.nota"
                                label="Nota"
                        ></v-text-field>
                    </v-col>

                    <v-col
                            cols="12"
                            md="4">
                        <v-row>
                            <v-col cols="12" md="1">
                                <v-file-input
                                        hide-input
                                        accept="image/*"
                                        @change="handleImage"
                                        label="Foto"
                                ></v-file-input>
                            </v-col>
                            <v-col cols="12" md="10">
                                <img class="img_detail" :src="pedido.adjunto_url" alt="">
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
                <v-row v-if="false">
                    <v-col cols="12" md="4">
                        <v-text-field
                                :readonly="!edit_codigo_bancario"
                                v-model="pedido.codigo_bancario_1"
                                label="Código bancario 2"
                                counter
                                maxlength="14"
                        ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="4">
                        <v-text-field
                                :readonly="!edit_codigo_bancario"
                                v-model="pedido.codigo_bancario_2"
                                label="Código bancario 3"
                                counter
                                maxlength="14"
                        ></v-text-field>
                    </v-col>

                </v-row>

                <v-row v-if="false">
                    <v-col cols="12" md="4">
                        <v-text-field
                                :readonly="!edit_codigo_bancario"
                                v-model="pedido.codigo_bancario_3"
                                label="Código bancario 4"
                                counter
                                maxlength="14"
                        ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="4">
                        <v-text-field
                                :readonly="!edit_codigo_bancario"
                                v-model="pedido.codigo_bancario_4"
                                label="Código bancario 5"
                                counter
                                maxlength="14"
                        ></v-text-field>
                    </v-col>

                </v-row>-->

                <v-row v-if="pedido">
                    <v-col cols="12" md="4" v-if="pedido.payment_method == 0">
                        <h3>Voucher de Pago</h3>
                        <p v-if="pedido.voucher_number">{{pedido.voucher_number}}</p>
                        <v-img :src="pedido.voucher_url" alt="Voucher de Pago" class=""></v-img>
                    </v-col>
                    <v-col cols="12" md="4" v-if="pedido.payment_method == 1">
                        <h3>Pagado con Tarjeta</h3>
                        <p><b>Número de transacción: </b>{{pedido.niubiz_purchase_number}}</p>
                        <p><b>Número Tarjeta: </b>{{pedido.niubiz_card_number}}</p>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12" md="4">
                        <template v-if="pedido.status == 1">
                            <v-btn :disabled="sending" color="success" class="mr-4" @click="validatePedido(true)"
                                   :loading="sending"
                                   v-if="pedido.id">
                                Aprobar Pedido
                            </v-btn>
                            <v-btn :disabled="sending" color="error" class="mr-4" @click="validatePedido(false)"
                                   :loading="sending"
                                   v-if="pedido.id">
                                Rechazar Pedido
                            </v-btn>
                        </template>
                        <template v-if="pedido.status == 3">
                            <v-btn :disabled="!valid || !isPorConfirmar" color="success" class="mr-4" @click="submitUpdate"
                                   v-if="pedido.id">
                                Actualizar
                            </v-btn>
                        </template>
                        <!--<v-btn
                                :disabled="!valid || isEmptyPedidoDetail"
                                color="success"
                                class="mr-4"
                                @click="submit"
                                v-if="!pedido.id"
                        >
                            Guardar
                        </v-btn>-->

                    </v-col>
                </v-row>
            </v-container>
        </v-form>
    </div>
</template>
<style>
    .img_detail {
        width: 100px;
    }
</style>
<script>
    // @ is an alias to /src
    import pedidoService from "@/services/pedidoService";
    import productService from "@/services/productService";
    import PedidoAddress from "@/components/pedido-web/PedidoAddress";
    import PedidoTabs from "@/components/pedido/PedidoTabs";
    import ubigeoService from "@/services/ubigeoService";

    export default {
        props: ["pedido", "edit_codigo_bancario"],
        name: 'PedidoForm',
        components: {
            PedidoTabs,
            PedidoAddress
        },
        data: () => ({
            type_status: 'pedidos-web',
            valid: false,
            sub_total: 0,
            monto_total: 0,
            factura_contenidos: ['Detalles producto', 'General'],
            loading: false,
            clients: [],
            products: [],
            departments: [],
            provinces: [],
            districts: [],
            searchClient: null,
            searchProduct: null,
            productSelect: null,
            select: null,
            dialogDelete: false,
            rules: {
                client: [
                    v => !!v || 'Cliente es requerido',
                ],
                name: [
                    v => !!v || 'Nombre es requerido',
                ],
                address: [
                    v => !!v || 'Dirección es requerido',
                ],
                min(min, v) {
                    this.valid = (parseInt(v.cantidad) >= min);
                    return parseInt(v.cantidad) >= min || `Valor debe ser por lo menos ${min}`;
                },
                max(max, v) {
                    if (v.old_cantidad) {
                        this.valid = ((parseInt(v.cantidad) - parseInt(v.old_cantidad)) <= max);
                        return (parseInt(v.cantidad) - parseInt(v.old_cantidad)) <= max || `Valor no debe ser mayor a ${max}.`;
                    } else {
                        this.valid = (parseInt(v.cantidad) <= max);
                        return parseInt(v.cantidad) <= max || `Valor no debe ser mayor a ${max}.`;
                    }
                }
            },
            sending: false,
            snackbar: false,
            textError: '',
        }),
        watch: {
            pedido: {
                handler: function (newValue) {
                },
                deep: true
            },
            searchClient(val) {
                val && val !== this.select && this.getClients(val)
            },
            searchProduct(val) {
                val && val !== this.select && this.getProducts(val)
            },
            dialogDelete(val) {
                val || this.closeDelete()
            },
            'pedido.pedido_web_details'(val) {
                val && val !== this.select && this.recalculate()
            },
            'pedido.client'(val) {
                this.setUbigeo()
            },
        },
        computed: {
            taxes() {
                return parseFloat(this.monto_total * 0.18).toFixed(2);
            },
            pedido_total() {
                // return parseFloat(this.monto_total * 1.18).toFixed(2);
                return parseFloat(Number(this.monto_total) + Number(this.flete)).toFixed(2);
            },
            pedido_sub_total() {
                return parseFloat(this.monto_total / 1.18).toFixed(2);
            },
            flete() {
                this.recalculate();
                if (this.monto_total >= 5000) {
                    return 0;
                } else if (this.pedido.user_address.district) {
                    return this.pedido.user_address.district.price;
                } else if (this.pedido.client) {
                    return this.pedido.client.price;
                } else {
                    return 0;
                }
            },
            isEmptyPedidoDetail() {
                return this?.pedido?.pedido_web_details?.length === 0
            },
            isPorConfirmar() {
                if (this.pedido.status == 2 || this.pedido.status == 3) {
                    return this.pedido.despacho_factura
                } else {
                    return true
                }
            }
        },
        mounted() {
            //this.type_status = this.$route.query.type ? this.$route.query.type : "pedidos-web";
            // this.getDepartments()
        },
        methods: {
            submit() {
                this.pedido.flete = this.flete;
                this.pedido.subtotal = this.monto_total;
                this.pedido.subtotal_igv = this.taxes;
                this.pedido.total = this.pedido_total;
                pedidoService.storePedido(this.pedido).then(response => {
                    if (response.data.success) {
                        if (response.data.download) {
                            var pedido_id = response.data.pedido.id;
                            pedidoService.downloadPedido(pedido_id).then(response => {
                                const blob = new Blob([response.data], {type: 'application/pdf'})
                                const link = document.createElement('a')
                                link.href = URL.createObjectURL(blob)
                                link.download = 'pedido_' + pedido_id
                                link.click()
                                URL.revokeObjectURL(link.href)
                                this.$router.push({path: '/' + this.type_status})
                            });
                        } else {
                            this.$router.push({path: '/' + this.type_status})
                        }
                    } else {
                        console.log('error', error);
                    }
                }, error => {
                    console.log('error', error);
                });
            },
            submitUpdate() {
                this.pedido.flete = this.flete;
                this.pedido.subtotal = this.monto_total;
                this.pedido.subtotal_igv = this.taxes;
                this.pedido.total = this.pedido_total;
                pedidoService.updatePedidoWeb(this.pedido).then(response => {
                    if (response.data.success) {
                        if (response.data.download) {
                            var pedido_id = response.data.pedido.id;
                            pedidoService.downloadPagadoWeb(pedido_id).then(response => {
                                const blob = new Blob([response.data], {type: 'application/pdf'})
                                const link = document.createElement('a')
                                link.href = URL.createObjectURL(blob)
                                link.download = 'pedido_pagado_' + pedido_id
                                link.click()
                                URL.revokeObjectURL(link.href)
                                this.$router.push({path: '/' + this.type_status})
                            });
                        } else {
                            this.$router.push({path: '/' + this.type_status})
                        }
                    } else {
                        console.log('error', error);
                    }
                }, error => {
                    console.log('error', error);
                });
            },
            validatePedido(valid) {
                this.sending = true;
                pedidoService.validatePedidoWeb({pedido_id: this.pedido.id, valid: valid}).then(response => {
                    this.sending = false;
                    this.$router.push({path: '/pedidos-web' });
/*
                    if (response.data.success) {
                        if (response.data.download) {
                            var pedido_id = response.data.pedido.id;
                            pedidoService.downloadPagadoWeb(pedido_id).then(response => {
                                const blob = new Blob([response.data], {type: 'application/pdf'})
                                const link = document.createElement('a')
                                link.href = URL.createObjectURL(blob)
                                link.download = 'pedido_pagado_' + pedido_id
                                link.click()
                                URL.revokeObjectURL(link.href)
                                this.$router.push({path: '/' + this.type_status})
                            });
                        } else {
                            this.$router.push({path: '/' + this.type_status})
                        }
                    } else {
                        console.log('error', error);
                    }
*/
                }, error => {
                    this.sending = false;
                    this.textError = error.response.data.message;
                    this.snackbar = true;
                    console.log('error', error);
                });
            },
            getDepartments() {
                /*ubigeoService.getDepartments().then(response => {
                    this.departments = response.data
                });*/
                pedidoService.getUbigeo(this.pedido.user_address.district.id).then(result => {
                    this.departments = result.data.departments;
                    this.provinces = result.data.provinces;
                    this.districts = result.data.districts;
                    // this.pedido.user_address.department = this.departments.find(items => items.id === this.pedido.user_address.district.department_id);
                    // this.pedido.user_address.province = this.provinces.find(items => items.id === this.pedido.user_address.district.province_id);
                    // this.pedido.user_address.district = this.districts.find(items => items.id === this.pedido.user_address.district.id);
                });
            },
            setUbigeo() {
                if (this.pedido.user_address?.district?.id) {
                    // console.log(this.pedido.client);
                    pedidoService.getUbigeo(this.pedido.user_address.district.id).then(result => {
                        this.departments = result.data.departments;
                        this.provinces = result.data.provinces;
                        this.districts = result.data.districts;
                        // this.pedido.user_address.department = this.departments.find(items => items.id === this.pedido.user_address.district.department_id);
                        // this.pedido.user_address.province = this.provinces.find(items => items.id === this.pedido.user_address.district.province_id);
                        // this.pedido.user_address.district = this.districts.find(items => items.id === this.pedido.user_address.district.id);
                    });
                }
            },
            recalculate() {
                this.monto_total = this.pedido.pedido_web_details.reduce((prev, cur) => {
                    return prev + cur.parcial
                }, 0);
                this.sub_total = (this.monto_total / 1.18).toFixed(2);
            },
            selectProduct(e) {
                this.pedido.pedido_web_details.push({
                    cantidad: 1,
                    price: e.price,
                    parcial: e.price,
                    product_detail: e,
                });
                this.$nextTick(() => {
                    this.productSelect = null
                })
            },
            updateParcial(index) {
                //this.monto_total += e.price;
                this.pedido.pedido_web_details[index].parcial = this.pedido.pedido_web_details[index].cantidad * this.pedido.pedido_web_details[index].price;
                this.$set(this.pedido.pedido_web_details, index, this.pedido.pedido_web_details[index]);

            },
            getClients(v) {
                this.loading = true
                pedidoService.searchClients(v).then(response => {
                    this.clients = response.data;
                    this.loading = false
                })
            },
            getProducts(v) {
                this.loading = true;
                let client_id = this.pedido.client ? this.pedido.client.id : null;
                productService.searchProducts(v).then(response => {
                    this.products = response.data;
                    this.loading = false
                })
            },
            deleteItem(item) {
                this.editedIndex = this.pedido.pedido_web_details.indexOf(item)
                this.dialogDelete = true
            },

            deleteItemConfirm() {
                this.pedido.pedido_web_details.splice(this.editedIndex, 1)
                this.closeDelete()
            },
            closeDelete() {
                this.dialogDelete = false
            },
            handleImage(e) {
                this.createBase64Image(e);
            },

            createBase64Image(fileObject) {
                const reader = new FileReader
                reader.onload = e => {
                    pedidoService.uploadPhoto(e.target.result).then(response => {
                        this.pedido.adjunto = response.data.fileName;
                        this.pedido.adjunto_url = response.data.url;
                    }, error => {
                        console.log('error', error)
                    })
                }
                reader.readAsDataURL(fileObject);
            }
        }
    }
</script>
