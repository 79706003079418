<template>
  <v-card>
    <v-stepper
        :value="status"
    >
      <v-stepper-header>
        <v-stepper-step
            step="1"
            color="secondary"
            :complete="status>=1"
            complete-icon="mdi-checkbox-marked-circle-outline"
            v-if="type_status=='' || type_status=='facturacion'"
        >
          Reservado
        </v-stepper-step>

        <v-divider></v-divider>

        <v-stepper-step
            step="2"
            color="secondary"
            :complete="status>=2"
            v-if="type_status=='' || type_status=='facturacion'"
            complete-icon="mdi-checkbox-marked-circle-outline"
        >
          Por confirmar Pago
        </v-stepper-step>

        <v-divider></v-divider>

        <v-stepper-step step="3" color="secondary"
                        :complete="status>=3"
                        v-if="type_status=='' || type_status=='facturacion'"
                        complete-icon="mdi-checkbox-marked-circle-outline">
          Pagado
        </v-stepper-step>
        <v-divider></v-divider>

        <v-stepper-step step="4" color="secondary"
                        :complete="status>=4"
                        v-if="type_status=='tracking' || type_status=='facturacion'"
                        complete-icon="mdi-checkbox-marked-circle-outline">
          Despachado
        </v-stepper-step>
        <v-divider></v-divider>

        <v-stepper-step step="5" color="secondary"
                        :complete="status>=5"
                        v-if="type_status=='tracking' || type_status=='facturacion'"
                        complete-icon="mdi-checkbox-marked-circle-outline">
          Entregado
        </v-stepper-step>

      </v-stepper-header>
    </v-stepper>
  </v-card>
</template>

<script>
// @ is an alias to /src


export default {
  props: ["status"],
  name: 'PedidoSteps',
  components: {},
  mounted() {
    this.type_status = this.$route.query.type ? this.$route.query.type : "";
  },
  data: () => ({
    type_status: null,
  })
}
</script>
