<template>
  <div>
    <Breadcrumbs :items="breadcrumbs"/>
    <v-card>
      <v-data-table
          :headers="headers"
          :items="pedidos"
          :options.sync="options"
          :server-items-length="totalPedidos"
          :loading="loading"
          :items-per-page="25"
          :search="search_code"
          class="elevation-1"
      >
        <template v-slot:top>
          <v-toolbar
              flat
              class="py-5"
          >
            <v-dialog v-model="dialogDelete" max-width="500px">
              <v-card>
                <v-card-title class="headline">¿Seguro de anular pedido?
                </v-card-title>
                <v-card-subtitle>
                  <v-text-field
                      v-model="input_motivo"
                      label="Ingrese un motivo por favor"
                      counter
                      maxlength="14"
                  ></v-text-field>
                </v-card-subtitle>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="blue darken-1" text @click="closeDelete">Cancelar</v-btn>
                  <v-btn
                      :disabled="!input_motivo"
                      color="blue darken-1" text @click="deleteItemConfirm">Sí, estoy
                    seguro
                  </v-btn>
                  <v-spacer></v-spacer>
                </v-card-actions>
              </v-card>
            </v-dialog>
            <v-toolbar-title>Pedidos</v-toolbar-title>
            <v-spacer></v-spacer>
           <v-row class="">
             <v-col cols="12" md="3">
               <v-text-field
                   v-model="search_code"
                   label="Código de pedido"
                   class="mx-4"
                  outlined
                  solo
                  dense
               ></v-text-field>
             </v-col>
             <v-col cols="12" md="3">
               <v-text-field
                   v-model="search_client"
                   label="Cliente"
                   class="mx-4"
                  outlined
                  solo
                  dense
               ></v-text-field>
             </v-col>
             <v-col cols="12" md="3">
               <v-text-field
                   v-model="search_sede"
                   label="Sede"
                   class="mx-4"
                  outlined
                  solo
                  dense
               ></v-text-field>
             </v-col>
             <v-col cols="12" md="3">
               <v-dialog
                   ref="dialog"
                   v-model="modal"
                   :return-value.sync="search_date"
                   persistent
                   width="290px"
                  outlined
                  solo
                  dense
               >
                 <template v-slot:activator="{ on, attrs }">
                   <v-text-field
                       v-model="search_date"
                       label="Buscar rango de fecha"
                       prepend-inner-icon="mdi-calendar"
                       readonly
                       v-bind="attrs"
                       v-on="on"
                       outlined
                       dense
                       class="input-saco"
                   ></v-text-field>
                 </template>
                 <v-date-picker
                     v-model="search_date"
                     range
                 >
                   <v-spacer></v-spacer>
                   <v-btn
                       text
                       color="primary"
                       @click="modal = false"
                   >
                     Cancel
                   </v-btn>
                   <v-btn
                       text
                       color="primary"
                       @click="$refs.dialog.save(search_date)"
                   >
                     OK
                   </v-btn>
                 </v-date-picker>
               </v-dialog>
             </v-col>
<!--              <v-col>
              <v-text-field
                outlined
                label="Buscar pedido"
                prepend-inner-icon="mdi-magnify"
                dense
                class="mr-3 input-saco"
              ></v-text-field>
             </v-col> -->
           </v-row>
            <v-btn
                dark
                class="btn-blue mx-4"
                to="/partner2-order/create"
                v-if="userSession.role==='partner' || userSession.role==='admin'"
            >
              Nuevo Pedido
            </v-btn>
            <v-btn dark class="btn-border-green" @click="exportXlsx()" v-if="userSession.role !== 'P2-EjecutivoVentas'">
              Exportar
              <v-icon small class="ml-2">
                mdi-file-document
              </v-icon>
            </v-btn>
          </v-toolbar>
        </template>
        <template v-slot:item.despacho_factura="{item}">
          {{item.despacho_factura!==null?'Si':'No'}}
        </template>
        <template v-slot:item.documents="{ item }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                  small
                  @click="descargarPedido(item.id)"
                  v-on="on"
                  v-bind="attrs"
              >
                <v-icon
                    small
                    class="mr-2"
                >
                  mdi-order-bool-descending-variant
                  mdi-cart-check
                </v-icon>
              </v-btn>
            </template>
            <span>Descargar Pedido</span>
          </v-tooltip>

          <!-- <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                  small
                  @click="descargarOrden(item.id)"
                  v-if="item.pago_user_id"
                  v-on="on"
                  v-bind="attrs"
              >
                <v-icon
                    small
                    class="mr-2"
                >
                  mdi-cart-check
                </v-icon>
              </v-btn>
            </template>
            <span>Descargar Orden de compra</span>
          </v-tooltip> -->

          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                  small
                  v-on="on"
                  v-bind="attrs"
                  @click="descargarDatosEquipos(item)">
                <v-icon
                    small
                    class="mr-2"
                >
                  mdi-database-arrow-down-outline
                </v-icon>
              </v-btn>
            </template>
            <span>Descargar Datos de equipos</span>
          </v-tooltip>

        </template>
        <template v-slot:item.actions="{ item }">
          <!--<v-btn   icon>
            <v-icon
                small
                class="mr-2"
            >
              mdi-eye-outline
            </v-icon>
          </v-btn>-->
          <v-btn icon
                 v-if="item.status>0"
                 :to="'/partner2-facturacion/edit/'+item.id+'?type=facturacion'">
            <v-icon
                small
                class="mr-2"
                @click="editItem(item)"
            >
              mdi-square-edit-outline
            </v-icon>
          </v-btn>
          <v-icon
              small
              @click="deleteItem(item.id)"
              v-if="item.status>0 && userSession.role==='admin'"
          >
            mdi-trash-can-outline
          </v-icon>
        </template>
        <template v-slot:item.image_url="{ item }">
          <div class="p-2 mt-1 mb-1">
            <v-img :src="item.image_url" :alt="item.pedido.name" width="70px"></v-img>
          </div>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
// @ is an alias to /src
import Breadcrumbs from "@/components/layout/Breadcrumbs";
import pedidoService from "@/services/pedidoService";
import partnerOrderService from "@/services/partner-2/partnerOrderService";
import axios from "axios";
import clientService from "@/services/clientService";

export default {
  computed: {
    userSession: function () {
      return this.$store.getters.userSession
    },
    params(nv) {
      this.options.query.code = this.search_code;
      this.options.query.date = this.search_date;
      this.options.query.client = this.search_client;
      this.options.query.sede = this.search_sede;
    }
  },
  name: 'PedidosIndex',
  components: {
    Breadcrumbs,
  },
  data: () => ({
    breadcrumbs: [
      {text: 'Inicio', disabled: false, href: '/',},
      {text: 'Pedidos', disabled: true, href: '/partner2-report',}
    ],
    input_motivo: '',
    dialogDelete: false,
    modal:false,
    search_code: "",
    search_client: "",
    search_sede: "",
    search_date: [],
    totalPedidos: 0,
    pedidos: [],
    loading: true,
    options: {query:{code:'',date:'',client:''}},
    editedIndex: null,
    headers: [
      {
        text: 'Código pedido',
        align: 'start',
        sortable: false,
        value: 'id',
      },
      {
        text: 'Nombre Cliente',
        sortable: false,
        value: 'client.name',
      },
      {
        text: 'Sede',
        sortable: false,
        value: 'client_store.name',
      },
      // {text: 'Zona', sortable: false, value: 'client_address.zona'},
      {text: 'Precio', sortable: false, value: 'total'},
      {text: 'Estado', sortable: false, value: 'status_nice'},
      {
        text: 'Facturado',
        sortable: false,
        value: 'despacho_factura'
      },
      {text: 'Documentos', sortable: false, value: 'documents'},
      {text: 'Acciones', sortable: false, value: 'actions'},
    ],
  }),
  watch: {
    params: {
      handler() {
        this.getDataFromApi()
      },
      deep: true,
    },
  },
  mounted() {
    this.getDataFromApi()
  },
  methods: {
    editItem(item) {
      console.log(item);
    },
    descargarPedido(id) {
      partnerOrderService.downloadPedidoBusiness(id).then(response => {
        const blob = new Blob([response.data], {type: 'application/pdf'})
        const link = document.createElement('a')
        link.href = URL.createObjectURL(blob)
        link.download = 'pedido_' + id
        link.click()
        URL.revokeObjectURL(link.href)
      });
    },
    descargarOrden(id) {
      partnerOrderService.downloadPagado(id).then(response => {
        const blob = new Blob([response.data], {type: 'application/pdf'})
        const link = document.createElement('a')
        link.href = URL.createObjectURL(blob)
        link.download = 'pedido_pagado_' + id
        link.click()
        URL.revokeObjectURL(link.href)
      });
    },
    descargarDatosEquipos(item) {
      partnerOrderService.downloadXlsEquipos(item.id).then(response => {
        const blob = new Blob([response.data], {type: 'application/vnd.ms-excel'})
        const link = document.createElement('a')
        link.href = URL.createObjectURL(blob)
        link.download = 'pedido_datos_equipos_' + item.id
        link.click()
        URL.revokeObjectURL(link.href)
      });
    },
    deleteItem(item) {
      console.log(item)
      this.input_motivo = '';
      this.dialogDelete = true
      this.editedIndex = item
    },
    deleteItemConfirm() {
      if(this.input_motivo){
        let data_null = {
          pedido_id : this.editedIndex,
          comment_null : this.input_motivo
        }
        partnerOrderService.nullPedido(data_null).then(result => {
          this.input_motivo = '';
          this.getDataFromApi()
          this.closeDelete()
        })
      }

    },
    closeDelete() {
      this.dialogDelete = false
    },
    getDataFromApi() {
      this.loading = true
      partnerOrderService.reporteDatatable(this.options).then(response => {
        // console.log('data_api', response);
        this.pedidos = response.data.items
        this.totalPedidos = response.data.total
        this.loading = false
      })
    },
    exportXlsx() {
      let data = {
        code:this.search_code,
        date:this.search_date,
      }
      partnerOrderService.downloadXslxPedidosReport(data).then(response => {
        const blob = new Blob([response.data], {type: 'application/vnd.ms-excel'});
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = 'pedidos';
        link.click();
        URL.revokeObjectURL(link.href);
      })
    }
  },
}
</script>
