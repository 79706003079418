import axios from "axios";

const ENDPOINT_PATH = process.env.VUE_APP_BASE_URL;

export default {
    listasDatatable(data) {
        return axios.post(`${ENDPOINT_PATH}/listas/datatable`, data);
    },
    uploadPhoto(image_base64) {
        const file = {file : image_base64};
        return axios.post(`${ENDPOINT_PATH}/listas/upload-photo`, file);
    },
    storeLista(data) {
        return axios.post(`${ENDPOINT_PATH}/listas`, data);
    },
    deleteLista(lista_id) {
        return axios.post(`${ENDPOINT_PATH}/listas/${lista_id}/delete`, null);
    },
    updateLista(data) {
        return axios.put(`${ENDPOINT_PATH}/listas/${data.id}`, data);
    },
    getLista(id) {
        return axios.get(`${ENDPOINT_PATH}/listas/${id}`);
    },
    getListas() {
        return axios.get(`${ENDPOINT_PATH}/listas/search`);
    },
    getBodegas() {
        return axios.get(`${ENDPOINT_PATH}/bodegas`);
    },
};
