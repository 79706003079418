<template>
  <div>
    <Breadcrumbs :items="breadcrumbs"/>
        <div class="my-4"><h3 class="ml-5"><v-icon>mdi-chevron-left</v-icon> Agregar cliente Saco</h3></div>
    <v-card>
      <BusinessForm/>
    </v-card>
  </div>
</template>

<script>
// @ is an alias to /src
import Breadcrumbs from "@/components/layout/Breadcrumbs";
import BusinessForm from "@/components/business/BusinessForm";

export default {
  name: 'Example',
  components: {
    Breadcrumbs,
      BusinessForm
  },
  data: () => ({
    breadcrumbs: [
      {text: 'Inicio', disabled: false, href: '/',},
      {text: 'Empresas', disabled: false, href: '/businesses',},
      {text: 'Crear Cliente Saco', disabled: true, href: '/businesses/create',}
    ]
  }),
}
</script>
