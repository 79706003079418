<template>
    <div>
        <Breadcrumbs :items="breadcrumbs"/>
        <v-card>
            <v-data-table
                    :headers="headers"
                    :items="categorias"
                    :options.sync="options"
                    :server-items-length="totalCategorias"
                    :loading="loading"
                    :items-per-page="25"
                    class="elevation-1"
            >
                <template v-slot:top>
                    <v-toolbar
                            flat
                            class="py-4"
                    >
                        <v-dialog v-model="dialogDelete" max-width="500px">
                            <v-card>
                                <v-card-title class="headline">Estás seguro de eliminar esta categoría?
                                </v-card-title>
                                <v-card-actions>
                                    <v-spacer></v-spacer>
                                    <v-btn color="blue darken-1" text @click="closeDelete">Cancelar</v-btn>
                                    <v-btn color="blue darken-1" text @click="deleteItemConfirm">Sí, estoy
                                        seguro
                                    </v-btn>
                                    <v-spacer></v-spacer>
                                </v-card-actions>
                            </v-card>
                        </v-dialog>
                        <v-toolbar-title>Categorías</v-toolbar-title>
                        <v-spacer></v-spacer>
                        <v-form>
                        <v-text-field
                            outlined
                            v-model="search_categoria"
                            label="Buscar categoría"
                            prepend-inner-icon="mdi-magnify"
                            dense
                            class="mr-3 input-saco"
                        ></v-text-field>
                        </v-form>
                        <v-btn
                                dark
                                class="btn-green"
                                to="/categorias/create"
                        >
                        <v-icon left>
                            mdi-plus
                        </v-icon>
                            Agregar categoría
                        </v-btn>
                    </v-toolbar>
                </template>
                <template v-slot:item.actions="{ item }">
                    <v-btn icon :to="'/categorias/edit/' + item.id">
                        <v-icon small class="mr-2">
                            mdi-square-edit-outline
                        </v-icon>
                    </v-btn>
                    <v-icon
                            small
                            @click="deleteItem(item.id)"
                    >
                        mdi-trash-can-outline
                    </v-icon>
                </template>
            </v-data-table>
        </v-card>
    </div>
</template>

<script>
    // @ is an alias to /src
    import Breadcrumbs from "@/components/layout/Breadcrumbs";
    import categoriaService from "@/services/categoriaService";

    export default {
        name: 'Categorías',
        components: {
            Breadcrumbs,
        },
        data: () => ({
            breadcrumbs: [
                {text: 'Inicio', disabled: false, href: '/',},
                {text: 'Categorías', disabled: true, href: '/categorias',}
            ],
            search_categoria: '',
            totalCategorias: 0,
            categorias: [],
            loading: true,
            dialogDelete: false,
            options: {query: {categoria: ''}},
            editedIndex: null,
            headers: [
                {
                    text: 'ID',
                    align: 'start',
                    sortable: false,
                    value: 'id',
                },
                {
                    text: 'Categoría',
                    sortable: false,
                    value: 'nombre',
                },
                {
                    text: 'Descripción',
                    sortable: false,
                    value: 'descripcion',
                },
                {text: 'Acciones', value: 'actions', sortable: false},
            ],
        }),
        watch: {
            params: {
                handler() {
                    this.getDataFromApi()
                },
                deep: true,
            },
        },
        computed: {
            params(nv) {
                this.options.query.categoria = this.search_categoria;
            }
        },
        mounted() {
            this.getDataFromApi()
        },
        methods: {
            editItem(item) {
            },
            deleteItem(item) {
                this.dialogDelete = true
                this.editedIndex = item
            },
            deleteItemConfirm() {
                categoriaService.destroy(this.editedIndex).then(result => {
                    this.getDataFromApi();
                    this.closeDelete()
                })
            },
            closeDelete() {
                this.dialogDelete = false
            },
            getDataFromApi() {
                this.loading = true
                categoriaService.datatable(this.options).then(response => {
                    this.categorias = response.data.items;
                    this.totalCategorias = response.data.total;
                    this.loading = false
                })
            },

        },
    }
</script>
