<template>
    <div>
        <Breadcrumbs :items="breadcrumbs"/>
        <v-card>
            <MarcaForm :marca="marca"/>
        </v-card>
    </div>
</template>

<script>
    // @ is an alias to /src
    import Breadcrumbs from "@/components/layout/Breadcrumbs";
    import MarcaForm from "@/components/marca/MarcaForm";
    import marcaService from "@/services/marcaService";

    export default {
        name: 'MarcaEdit',
        components: {
            Breadcrumbs,
            MarcaForm
        },
        data: () => ({
            breadcrumbs: [
                {text: 'Inicio', disabled: false, href: '/',},
                {text: 'Marcas', disabled: false, href: '/marcas',},
                {text: 'Crear Marca', disabled: true, href: '/marcas/create',}
            ],
            marca: {
                nombre: '',
                descripcion: '',
            }
        }),
        mounted() {
            this.getData(this.$route.params.id);
        },
        methods: {
            getData(id) {
                marcaService.show(id).then(response => {
                    this.marca = response.data.marca;
                })
            }
        }
    }
</script>
