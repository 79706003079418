<template>
  <div>
    <Breadcrumbs :items="breadcrumbs"/>
    <v-card>
      <UserForm :user="user"/>
    </v-card>
  </div>
</template>

<script>
// @ is an alias to /src
import Breadcrumbs from "@/components/layout/Breadcrumbs";
import UserForm from "@/components/user/UserForm";
import userService from "@/services/userService";

export default {
  name: 'UserEdit',
  components: {
    Breadcrumbs,
    UserForm
  },
  data: () => ({
    breadcrumbs: [
      {text: 'Inicio', disabled: false, href: '/',},
      {text: 'Usuarios', disabled: false, href: '/users',},
      {text: 'Editar Usuario', disabled: true, href: '/users/edit',}
    ],
    user: {
      name : '',
      email : '',
      password : '',
      phone : '',
      role : '',
      name_code : '',
      staff_code : '',
      address : '',
      lista_precio : null,
    }
  }),
  mounted () {
    this.getDataUser(this.$route.params.id);
  },
  methods: {
    getDataUser(user_id) {
      userService.getUser(user_id).then(response => {
        this.user = response.data;
      })
    }
  }
}
</script>
