<template>
    <div>
        <Breadcrumbs :items="breadcrumbs"/>
        <v-card>
            <v-data-table
                :headers="headers"
                :items="users"
                :options.sync="options"
                :server-items-length="totalUsers"
                :loading="loading"
                class="elevation-2"
            >
                <template v-slot:top>
                    <v-toolbar flat class="py-4">
                        <v-toolbar-title>Clientes Web</v-toolbar-title>
                        <v-spacer></v-spacer>
                    </v-toolbar>
                </template>
            </v-data-table>
        </v-card>
    </div>
</template>

<script>
// @ is an alias to /src
import Breadcrumbs from "@/components/layout/Breadcrumbs";
import userService from "@/services/userService";
import pedidoService from "@/services/pedidoService";

export default {
    computed: {
        userSession: function () {
            return this.$store.getters.userSession
        },
    },
    name: 'UsersWeb',
    components: {
        Breadcrumbs,
    },
    data: () => ({
        breadcrumbs: [
            {text: 'Inicio', disabled: false, href: '/',},
            {text: 'Users', disabled: true, href: '/users',}
        ],
        dialogDelete: false,
        totalUsers: 0,
        users: [],
        loading: true,
        editedIndex: null,
        options: {},
        headers: [
            {
                text: 'Nombre',
                align: 'start',
                sortable: false,
                value: 'name',
            },
            {
                text: 'Email',
                sortable: false,
                value: 'email',
            },
            {
                text: 'Tipo de Documento',
                sortable: false,
                value: 'document_type_text',
            },
            {
                text: 'N° de Documento',
                sortable: false,
                value: 'document_number',
            },
            {
                text: 'Celular',
                sortable: false,
                value: 'phone',
            },
        ],
    }),
    watch: {
        options: {
            handler () {
                this.getDataFromApi()
            },
            deep: true,
        },
    },
    mounted () {
        this.getDataFromApi()
    },
    methods: {
        getDataFromApi () {
            this.loading = true
            userService.usersWebDatatable(this.options).then(response => {
                this.users = response.data.items
                this.totalUsers = response.data.total
                this.loading = false
            })
        },
    },
}
</script>
