<template>
  <div>
    <Breadcrumbs :items="breadcrumbs"/>
    <v-card>
      <v-data-table
          :headers="headers"
          :items="listas"
          :options.sync="options"
          :server-items-length="totalListas"
          :loading="loading"
          :items-per-page="25"
          class="elevation-1"
      >
        <template v-slot:top>
          <v-toolbar
              flat
              class="py-4"
          >
            <v-dialog v-model="dialogDelete" max-width="500px">
              <v-card>
                <v-card-title class="headline">Estás seguro de eliminar este lista?
                </v-card-title>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="blue darken-1" text @click="closeDelete">Cancelar</v-btn>
                  <v-btn color="blue darken-1" text @click="deleteItemConfirm">Sí, estoy
                    seguro
                  </v-btn>
                  <v-spacer></v-spacer>
                </v-card-actions>
              </v-card>
            </v-dialog>
            <v-toolbar-title>Lista de precios</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-form>
              <v-text-field
                outlined
                v-model="search_lista"
                label="Buscar lista"
                prepend-inner-icon="mdi-magnify"
                dense
                class="mr-3 input-saco"
              ></v-text-field>
            </v-form>
            <v-btn
                dark
                class="mb-2 btn-green"
                to="/listas/create"
            >
              <v-icon left>
                mdi-plus
              </v-icon>
              Agregar lista
            </v-btn>
          </v-toolbar>
        </template>
        <template v-slot:item.actions="{ item }">
          <v-btn icon :to="'/listas/edit/' + item.id">
            <v-icon small class="mr-2">
              mdi-square-edit-outline
            </v-icon>
          </v-btn>
          <v-icon
              small
              @click="deleteItem(item.id)"
          >
            mdi-trash-can-outline
          </v-icon>
        </template>
        <template v-slot:item.image_url="{ item }">
          <div class="p-2 mt-1 mb-1">
            <v-img :src="item.image_url" :alt="item.lista.name" width="70px"></v-img>
          </div>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
// @ is an alias to /src
import Breadcrumbs from "@/components/layout/Breadcrumbs";
import listaService from "@/services/listaService";

export default {
  name: 'Listas',
  components: {
    Breadcrumbs,
  },
  data: () => ({
    breadcrumbs: [
      {text: 'Inicio', disabled: false, href: '/',},
      {text: 'Listas', disabled: true, href: '/listas',}
    ],
    search_lista:'',
    totalListas: 0,
    listas: [],
    loading: true,
    dialogDelete: false,
    options: {query:{lista:''}},
    editedIndex: null,
    headers: [
      {
        text: 'ID',
        align: 'start',
        sortable: false,
        value: 'id',
      },
      {
        text: 'Lista',
        sortable: false,
        value: 'name',
      },
      {
        text: 'Flexline ID',
        sortable: false,
        value: 'id_flexline',
      },
      {
        text: 'Flexline Name',
        sortable: false,
        value: 'name_flexline',
      },
      {
        text: 'Bodega',
        sortable: false,
        value: 'bodega_name',
      },
      {
        text: 'Canal',
        sortable: false,
        value: 'channel.name',
      },
      {
        text: 'Subcanal',
        sortable: false,
        value: 'subchannel.name',
      },
      {
        text: 'Rangos',
        sortable: false,
        value: 'rangos',
      },
      { text: 'Estado', value: 'status_nice', sortable: false },
      { text: 'Actions', value: 'actions', sortable: false },
    ],
  }),
  watch: {
    params: {
      handler () {
        this.getDataFromApi()
      },
      deep: true,
    },
  },
  computed: {
    params(nv) {
      this.options.query.lista = this.search_lista;
    }
  },
  mounted () {
    this.getDataFromApi()
  },
  methods: {
    editItem (item) {
      console.log(item);
    },
    deleteItem(item) {
      console.log(item)
      this.dialogDelete = true
      this.editedIndex = item
    },
    deleteItemConfirm() {
      listaService.deleteLista(this.editedIndex).then(result => {
        this.getDataFromApi()
        this.closeDelete()
      })
    },
    closeDelete() {
      this.dialogDelete = false
    },
    getDataFromApi () {
      this.loading = true
      listaService.listasDatatable(this.options).then(response => {
        // console.log('data_api',response);
        this.listas = response.data.items;
        this.totalListas = response.data.total;
        this.loading = false
      })
    },
    /**
     * In a real application this would be a call to fetch() or axios.get()
     */
    fakeApiCall () {
      return new Promise((resolve, reject) => {
        const { sortBy, sortDesc, page, itemsPerPage } = this.options

        let items = this.getDesserts()
        const total = items.length

        if (sortBy.length === 1 && sortDesc.length === 1) {
          items = items.sort((a, b) => {
            const sortA = a[sortBy[0]]
            const sortB = b[sortBy[0]]

            if (sortDesc[0]) {
              if (sortA < sortB) return 1
              if (sortA > sortB) return -1
              return 0
            } else {
              if (sortA < sortB) return -1
              if (sortA > sortB) return 1
              return 0
            }
          })
        }

        if (itemsPerPage > 0) {
          items = items.slice((page - 1) * itemsPerPage, page * itemsPerPage)
        }

        setTimeout(() => {
          resolve({
            items,
            total,
          })
        }, 1000)
      })
    },
    getDesserts () {
      return [
        {
          name: 'Frozen Yogurt',
          calories: 159,
          fat: 6.0,
          carbs: 24,
          protein: 4.0,
          iron: '1%',
        },
        {
          name: 'Ice cream sandwich',
          calories: 237,
          fat: 9.0,
          carbs: 37,
          protein: 4.3,
          iron: '1%',
        },
        {
          name: 'Eclair',
          calories: 262,
          fat: 16.0,
          carbs: 23,
          protein: 6.0,
          iron: '7%',
        },
        {
          name: 'Cupcake',
          calories: 305,
          fat: 3.7,
          carbs: 67,
          protein: 4.3,
          iron: '8%',
        },
        {
          name: 'Gingerbread',
          calories: 356,
          fat: 16.0,
          carbs: 49,
          protein: 3.9,
          iron: '16%',
        },
        {
          name: 'Jelly bean',
          calories: 375,
          fat: 0.0,
          carbs: 94,
          protein: 0.0,
          iron: '0%',
        },
        {
          name: 'Lollipop',
          calories: 392,
          fat: 0.2,
          carbs: 98,
          protein: 0,
          iron: '2%',
        },
        {
          name: 'Honeycomb',
          calories: 408,
          fat: 3.2,
          carbs: 87,
          protein: 6.5,
          iron: '45%',
        },
        {
          name: 'Donut',
          calories: 452,
          fat: 25.0,
          carbs: 51,
          protein: 4.9,
          iron: '22%',
        },
        {
          name: 'KitKat',
          calories: 518,
          fat: 26.0,
          carbs: 65,
          protein: 7,
          iron: '6%',
        },
      ]
    },
  },
}
</script>
