<template>
    <div>
        <Breadcrumbs :items="breadcrumbs"/>
        <v-card>
            <v-data-table
                :headers="headers"
                :items="businesses"
                :options.sync="options"
                :server-items-length="totalBusinesses"
                :loading="loading"
                :items-per-page="25"
                class="elevation-1"
            >
                <template v-slot:top>
                    <v-toolbar
                        flat
                        class="py-4"
                    >
                        <v-dialog v-model="dialogDelete" max-width="500px">
                            <v-card>
                                <v-card-title class="headline">Estás seguro de eliminar esta empresa?
                                </v-card-title>
                                <v-card-actions>
                                    <v-spacer></v-spacer>
                                    <v-btn color="blue darken-1" text @click="closeDelete">Cancelar</v-btn>
                                    <v-btn color="blue darken-1" text @click="deleteItemConfirm">Sí, estoy
                                        seguro
                                    </v-btn>
                                    <v-spacer></v-spacer>
                                </v-card-actions>
                            </v-card>
                        </v-dialog>
                        <v-toolbar-title>Clientes</v-toolbar-title>
                        <v-spacer></v-spacer>
                        <v-form>
                            <v-text-field
                                outlined
                                v-model="search_business"
                                label="Buscar cliente"
                                prepend-inner-icon="mdi-magnify"
                                dense
                                class="mr-3 input-saco"
                            ></v-text-field>
                        </v-form>
                        <v-btn
                            dark
                            class="btn-green"
                            to="/businesses/create"
                        >
                            <v-icon left>
                                mdi-plus
                            </v-icon>
                            Agregar cliente
                        </v-btn>
                    </v-toolbar>
                </template>
                <template v-slot:item.actions="{ item }">
                    <v-btn icon :to="'/businesses/edit/' + item.id">
                        <v-icon small class="mr-2">
                            mdi-square-edit-outline
                        </v-icon>
                    </v-btn>
                    <v-icon small @click="deleteItem(item.id)">
                        mdi-trash-can-outline
                    </v-icon>
                </template>
            </v-data-table>
        </v-card>
    </div>
</template>

<script>
// @ is an alias to /src
import Breadcrumbs from "@/components/layout/Breadcrumbs";
import businessService from "@/services/businessService";

export default {
    name: 'Example',
    components: {
        Breadcrumbs,
    },
    data: () => ({
        breadcrumbs: [
            {text: 'Inicio', disabled: false, href: '/',},
            {text: 'Clientes', disabled: true, href: '/businesses',}
        ],
        search_business: '',
        totalBusinesses: 0,
        businesses: [],
        loading: true,
        dialogDelete: false,
        options: {query: {business: ''}},
        editedIndex: null,
        headers: [
            {
                text: 'Código',
                align: 'start',
                sortable: false,
                value: 'clientid',
            },
            {
                text: 'Nombre',
                sortable: false,
                value: 'name',
            },
            {
                text: 'Tipo de empresa',
                sortable: false,
                value: 'client_type',
            },
            {text: 'Zona', value: 'zona'},
            {text: 'Precio', value: 'price'},
            {text: 'Actions', value: 'actions', sortable: false},
        ],
    }),
    watch: {
        params: {
            handler() {
                this.getDataFromApi()
            },
            deep: true,
        },
    },
    computed: {
        params(nv) {
            this.options.query.business = this.search_business;
        }
    },
    mounted() {
        this.getDataFromApi()
    },
    methods: {
        editItem(item) {
            // console.log(item);
        },
        deleteItem(item) {
            // console.log(item)
            this.dialogDelete = true
            this.editedIndex = item
        },
        deleteItemConfirm() {
            businessService.destroy(this.editedIndex).then(result => {
                this.getDataFromApi()
                this.closeDelete()
            })
        },
        closeDelete() {
            this.dialogDelete = false
        },
        getDataFromApi() {
            this.loading = true
            businessService.datatable(this.options).then(response => {
                // console.log('data_api',response);
                this.businesses = response.data.items;
                this.totalBusinesses = response.data.total;
                this.loading = false
            })
        },
    },
}
</script>
