<template>
  <div>
    <Breadcrumbs :items="breadcrumbs"/>
    <v-card>
      <ListaForm :lista="lista" v-if="lista.id"/>
    </v-card>
  </div>
</template>

<script>
// @ is an alias to /src
import Breadcrumbs from "@/components/layout/Breadcrumbs";
import ListaForm from "@/components/partner-list-price/ListaForm";
import listaService from "@/services/listaService";
import partnerListPriceService from "@/services/partnerListPriceService";

export default {
  name: 'ListaEdit',
  components: {
    Breadcrumbs,
    ListaForm
  },
  data: () => ({
    breadcrumbs: [
      {text: 'Inicio', disabled: false, href: '/',},
      {text: 'Listas', disabled: false, href: '/partner-list-price',},
      {text: 'Crear Lista', disabled: true, href: '/partner-list-price/create',}
    ],
    lista: {
        id: null,
        name: '',
        description: '',
        id_flexline: '',
        name_flexline: '',
        fecha_vigencia: '',
        modelo_negocio: '',
        nivel_precio: '',
        bodega_id: null,
        partner_id: null,
        channel_id: null,
        subchannel_id: null,
        rangos: 0,
        lista_precio_range:[]
    }
  }),
  mounted () {
    this.getDataLista(this.$route.params.id);
  },
  methods: {
    getDataLista(lista_id) {
      partnerListPriceService.getLista(lista_id).then(response => {  
        this.lista = response.data.lista;
      })
    }
  }
}
</script>
